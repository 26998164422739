import React, { useState, useEffect } from 'react';

import { useTheme } from 'styled-components';
import { MdClose, MdToday } from 'react-icons/md';

import { useToast } from 'contexts/Toast';
import { useService } from 'hooks';
import CancelOrderModal from 'components/CancelOrderModal';
import { B2Modal, B2Spinner } from 'components/B2';
import { dateAndHourMask, addressMask, brCurrencyMask } from 'utils/masks';
import getBrandImage from 'utils/getBrandImage';

import {
  Header,
  HeaderLeft,
  OrderId,
  DateTime,
  CloseButton,
  Content,
  Side,
  Group,
  Label,
  Service,
  Icon,
  Profile,
  Avatar,
  ProfileInfo,
  Name,
  DateTimeItem,
  DateTimeDivider,
  DateContainer,
  CalendarContainer,
  DateText,
  Clock,
  CancelButton,
  Payment,
  Card,
  Brand,
  PaymentText,
  Question,
  QuestionLabel,
  QuestionResponse,
} from './styles';

interface IDetailModal {
  isOpen: boolean;
  onClose: () => void;
  order?: IOrder;
  refreshList: () => void;
}

const DetailModal: React.FC<IDetailModal> = ({
  isOpen,
  onClose,
  order,
  refreshList,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [logs, setLogs] = useState<Array<ILog>>([]);
  const [showCancelOrderModal, setShowCancelOderModal] = useState(false);

  const theme = useTheme();

  const { getLog } = useService();
  const { addToast } = useToast();

  useEffect(() => {
    const fetchLog = async () => {
      if (order && order.items[0].status_info.value === -1) {
        setIsLoading(true);
        const { error, message } = await getLog(order.items[0].id);

        if (error) {
          addToast('Erro ao carregar o pedido', 'error');
          onClose();
        } else {
          setLogs(message);
        }
        setIsLoading(false);
      }
    };

    fetchLog();
  }, [addToast, getLog, onClose, order]);

  const orderIsInProgress = (order: IOrder) =>
    order.items[0].status_info.value < 6 &&
    order.items[0].status_info.value !== -1;

  const orderIsCanceled = (order: IOrder) =>
    order.items[0].status_info.value === -1;

  return (
    <B2Modal open={isOpen}>
      {!order || isLoading ? (
        <B2Spinner />
      ) : (
        <>
          <Header>
            <HeaderLeft>
              <OrderId>#{order.id}</OrderId>
              <DateTime>
                Data e hora do pedido -{' '}
                {dateAndHourMask(new Date(order.items[0].initial_time))}
              </DateTime>
            </HeaderLeft>
            <CloseButton onClick={onClose}>
              <MdClose />
            </CloseButton>
          </Header>
          <hr />
          <Content>
            <Side leftSide>
              <Group>
                <Label>Tipo de serviço</Label>
                <Service>
                  {order.items[0].service.icon?.file && (
                    <Icon
                      src={order.items[0].service.icon?.file}
                      alt={order.items[0].service.name}
                    />
                  )}
                  <span>{order.items[0].service.name}</span>
                </Service>
              </Group>
              {order.items[0].worker && (
                <Group>
                  <Label>Prestador</Label>
                  <Profile>
                    <Avatar
                      src={
                        order.items[0].worker.avatar?.image_high_url ||
                        '/images/blank-profile.png'
                      }
                    />
                    <ProfileInfo>
                      <Name>{order.items[0].worker.name}</Name>
                    </ProfileInfo>
                  </Profile>
                </Group>
              )}
              <Group>
                <Label>Cliente</Label>
                <Profile>
                  <Avatar
                    src={
                      order.customer.avatar?.image_high_url ||
                      '/images/blank-profile.png'
                    }
                  />
                  <ProfileInfo>
                    <Name>{order.customer.name}</Name>
                  </ProfileInfo>
                </Profile>
              </Group>
              <Group>
                <Label>Endereço do serviço</Label>
                <span>{addressMask(order.items[0].address)}</span>
              </Group>
              <Group>
                <Label>Data e hora</Label>
                <DateTimeItem>
                  <DateContainer>
                    <CalendarContainer>
                      <MdToday size={24} color={theme.colors.main} />
                    </CalendarContainer>
                    <DateText>
                      {new Date(order.items[0].initial_time).toLocaleDateString(
                        'pt-BR',
                        {
                          weekday: 'long',
                          day: 'numeric',
                          month: 'short',
                          year: 'numeric',
                        }
                      )}
                    </DateText>
                  </DateContainer>
                  <Clock />
                  {new Date(order.items[0].initial_time).toLocaleTimeString(
                    'pt-BR',
                    {
                      hour: 'numeric',
                      minute: 'numeric',
                    }
                  )}
                </DateTimeItem>
                <DateTimeDivider />
                <DateTimeItem>
                  <DateContainer>
                    <CalendarContainer>
                      <MdToday size={24} color={theme.colors.main} />
                    </CalendarContainer>
                    <DateText>
                      {new Date(order.items[0].final_time).toLocaleDateString(
                        'pt-BR',
                        {
                          weekday: 'long',
                          day: 'numeric',
                          month: 'short',
                          year: 'numeric',
                        }
                      )}
                    </DateText>
                  </DateContainer>
                  <Clock />
                  {new Date(order.items[0].final_time).toLocaleTimeString(
                    'pt-BR',
                    {
                      hour: 'numeric',
                      minute: 'numeric',
                    }
                  )}
                </DateTimeItem>
              </Group>
              {orderIsInProgress(order) && (
                <CancelButton
                  onClick={() => setShowCancelOderModal(true)}
                  variant="outline"
                >
                  Cancelar pedido
                </CancelButton>
              )}
            </Side>
            <Side>
              <Group>
                <Label>Pagamento</Label>
                <Payment>
                  <Card>
                    <Brand
                      src={getBrandImage(order.items[0].card.brand)}
                      alt="card brand"
                    />
                    <PaymentText>
                      {order.items[0].card.brand}{' '}
                      {order.items[0].card.last_four_digits}
                    </PaymentText>
                  </Card>
                  <PaymentText>
                    {brCurrencyMask(order.items[0].price)}
                  </PaymentText>
                </Payment>
              </Group>
              <Group>
                <Label>Formulário</Label>
                {Object.keys(order.items[0].form).map((question, index) => (
                  <Question key={index}>
                    <QuestionLabel>{question}</QuestionLabel>
                    <QuestionResponse>
                      {order.items[0].form[question]}
                    </QuestionResponse>
                  </Question>
                ))}
              </Group>
              <Group>
                <Label>Status do pedido</Label>
                <span>{order.items[0].status_info.label}</span>
              </Group>
              {orderIsCanceled(order) && logs[0] && (
                <Group>
                  <Label>Motivo do cancelamento</Label>
                  <span>{logs[0].message}</span>
                </Group>
              )}
            </Side>
          </Content>
          <CancelOrderModal
            open={showCancelOrderModal}
            order={order}
            onClose={() => setShowCancelOderModal(false)}
            onSubmit={() => {
              onClose();
              refreshList();
            }}
          />
        </>
      )}
    </B2Modal>
  );
};

export default DetailModal;
