import { useCallback, useEffect, useState } from 'react';
import { MdOutlineAdd } from 'react-icons/md';
import { useHistory } from 'react-router';

import {
  PageHeaderContainer,
  PageHeaderTitle,
  PrimaryButton,
} from 'components/ProjectStyles';

import { B2Spinner } from 'components/B2';
import { useToast } from 'contexts/Toast';
import useCategory from 'hooks/Category';
import { B2Table } from 'components/B2/B2Table';
import CategoryTableRow from 'components/CategoryTableRow';
import RoutesPath from 'router/routes';

const ServiceCategories = () => {
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { listCategory } = useCategory();
  const { addToast } = useToast();
  const history = useHistory();

  const getCategories = useCallback(async () => {
    setIsLoading(true);
    const response = await listCategory();
    if (response.error) {
      addToast('Não foi possível carregar as categorias', 'error');
    } else {
      setCategories(response.message);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  return (
    <>
      <PageHeaderContainer style={{ marginTop: '0px' }}>
        <PageHeaderTitle>Crie novas categorias e serviços</PageHeaderTitle>
        <PrimaryButton
          onClick={() => history.push(RoutesPath.private.category.create)}
        >
          <MdOutlineAdd />
          <span>Adicionar categoria</span>
        </PrimaryButton>
      </PageHeaderContainer>
      {isLoading ? (
        <B2Spinner />
      ) : (
        <B2Table
          headerData={['', 'Categoria', 'Nº de serviços', '']}
          tableData={categories}
          tableEmptyComponent={() => <p>Nenhum dado encontrado</p>}
          renderRow={(category: ICategory, index) => (
            <CategoryTableRow category={category} key={index} />
          )}
          paginator
          total={categories.length}
          changePage={() => {}}
          currentPage={0}
        />
      )}
    </>
  );
};

export default ServiceCategories;
