export const endpoints = {
  auth: {
    login: `/auth/token/`,
    profile: `/auth/profile/`,
    register: `/auth/register`,
    changePassword: `/auth/reset_password/`,
    confirmChangePassword: `/auth/update_password/`,
  },
  assets: {
    sendImage: `/asset/image/icon/`,
    sendIcon: `/asset/icon/`,
  },
  b2biters: {
    list: `/customers/`,
  },
  address: `/address/`,
  customers: `/auth/customers/`,
  category: `/service/categories/`,
  service: {
    services: `/service/services/`,
    log: `/service/log/`,
  },
  staffs: `/auth/staff/`,
  trainings: `/trainings/`,
  workers: {
    active: `/auth/workers/?status=1`,
    pending: `/auth/workers/?status=0`,
    documents: `/auth/workers/documents/`,
    facechecks: `/auth/workers/facechecks/`,
    ban: `/auth/workers/ban/`,
    worker: `/auth/workers/`,
  },
  order: {
    post: '/order/',
    getAll: '/order/',
    getOne: '/order/:order_id/items/:order_item_id/',
    cancel: '/order/:order_id/items/:order_item_id/cancel/',
    rate: '/order/:order_id/items/:order_item_id/rate/',
  },
  metrics: {
    workers: `/metrics/workers_count`,
    avg: `/metrics/avg_ticket`,
    customers: `/metrics/customers_count`,
    gmv: `/metrics/gmv`,
    customersMonthly: `/metrics/customers_registerd_this_montth`,
    orders: `/metrics/services_ordered_count`,
  },
};
