import CloseButton from 'components/CloseButton';
import { FormError, FormGroup } from 'components/ProjectStyles';
import StateSelect from 'components/StateSelect';
import { useToast } from 'contexts/Toast';
import { useFormik } from 'formik';
import useAddress from 'hooks/Address';
import { cepMask, onlyNumbers } from 'utils/masks';
import { cepValidator, stateValidator } from 'utils/validators';
import * as yup from 'yup';
import { GridForm, HeaderContainer, Modal, SaveButton, Title } from './styles';

interface IAddressModal {
  closeModal: () => void;
  address: IAddress;
  setAddress: (updatedAddress: IAddress) => void;
}

const AddressModal: React.FC<IAddressModal> = ({
  address,
  closeModal,
  setAddress,
}) => {
  const { updateAddress } = useAddress();
  const { addToast } = useToast();

  const addressFormik = useFormik({
    initialValues: {
      street: address.street,
      number: address.number,
      city: address.city,
      state: address.state,
      zip_code: onlyNumbers(address.zip_code),
      neighborhood: address.neighborhood,
      complement: address.neighborhood,
      lat: address.point?.coordinates[0],
      long: address.point?.coordinates[1],
    },
    validationSchema: yup.object({
      zip_code: yup
        .string()
        .required('Esse campo é obrigatório')
        .length(8, 'CEP deve ter 8 dígitos')
        .test(
          'is-zipe_code-valid',
          'Campo inválido',
          (zip_code) => !!zip_code && cepValidator(zip_code)
        ),
      state: yup
        .string()
        .required('Esse campo é obrigatório')
        .test(
          'is-state-valid',
          'Campo inválido',
          (state) => !!state && stateValidator(state)
        ),
      city: yup.string().required('Esse campo é obrigatório'),
      neighborhood: yup.string().required('Esse campo é obrigatório'),
      street: yup.string().required('Esse campo é obrigatório'),
      number: yup.string().required('Esse campo é obrigatório'),
      lat: yup
        .number()
        .max(90, 'Número acima do limite')
        .min(-90, 'Número abaixo do limite'),
      long: yup
        .number()
        .max(180, 'Número acima do limite')
        .min(-180, 'Número abaixo do limite'),
    }),
    onSubmit: async (values) => {
      const response = await updateAddress(address.id, values);
      if (response.error) {
        addToast('Não foi possível atualizar esse endereço', 'error');
        addressFormik.setStatus(response.message);
      } else {
        addToast('Endereço atualizado com sucesso', 'success');
        setAddress(response.message);
        closeModal();
      }
    },
  });

  return (
    <Modal open={true}>
      <HeaderContainer>
        <Title>Endereço</Title>
        <CloseButton onClick={closeModal} />
      </HeaderContainer>
      <GridForm>
        <FormGroup>
          <label htmlFor="zip_code">CEP *</label>
          <input
            type="text"
            id="zip_code"
            name="zip_code"
            placeholder="00000-000"
            value={cepMask(addressFormik.values.zip_code)}
            onChange={(e) => {
              e.target.value = onlyNumbers(e.target.value);
              addressFormik.handleChange(e);
            }}
            maxLength={10}
          />
          {addressFormik.errors.zip_code && (
            <FormError>{addressFormik.errors.zip_code}</FormError>
          )}
          {addressFormik.status?.zip_code &&
            addressFormik.status.zip_code.map(
              (error: string, index: number) => (
                <FormError key={index}>{error}</FormError>
              )
            )}
        </FormGroup>
        <FormGroup>
          <label htmlFor="state">Estado *</label>
          <StateSelect
            id="state"
            name="state"
            value={addressFormik.values.state.toUpperCase()}
            onChange={(e) => {
              e.target.value = e.target.value.toUpperCase();
              addressFormik.handleChange(e);
            }}
          />
          {addressFormik.errors.state && (
            <FormError>{addressFormik.errors.state}</FormError>
          )}
          {addressFormik.status?.state &&
            addressFormik.status.state.map((error: string, index: number) => (
              <FormError key={index}>{error}</FormError>
            ))}
        </FormGroup>
        <FormGroup>
          <label htmlFor="city">Cidade *</label>
          <input
            type="text"
            id="city"
            name="city"
            placeholder="Insira a cidade"
            value={addressFormik.values.city}
            onChange={addressFormik.handleChange}
          />
          {addressFormik.errors.city && (
            <FormError>{addressFormik.errors.city}</FormError>
          )}
          {addressFormik.status?.city &&
            addressFormik.status.city.map((error: string, index: number) => (
              <FormError key={index}>{error}</FormError>
            ))}
        </FormGroup>
        <FormGroup>
          <label htmlFor="neighborhood">Bairro *</label>
          <input
            type="text"
            id="neighborhood"
            name="neighborhood"
            placeholder="Insira o bairro"
            value={addressFormik.values.neighborhood}
            onChange={addressFormik.handleChange}
          />
          {addressFormik.errors.neighborhood && (
            <FormError>{addressFormik.errors.neighborhood}</FormError>
          )}
          {addressFormik.status?.neighborhood &&
            addressFormik.status.neighborhood.map(
              (error: string, index: number) => (
                <FormError key={index}>{error}</FormError>
              )
            )}
        </FormGroup>
        <FormGroup>
          <label htmlFor="street">Rua *</label>
          <input
            type="text"
            id="street"
            name="street"
            placeholder="Insira a rua"
            value={addressFormik.values.street}
            onChange={addressFormik.handleChange}
          />
          {addressFormik.errors.street && (
            <FormError>{addressFormik.errors.street}</FormError>
          )}
          {addressFormik.status?.street &&
            addressFormik.status.street.map((error: string, index: number) => (
              <FormError key={index}>{error}</FormError>
            ))}
        </FormGroup>
        <FormGroup>
          <label htmlFor="number">Número *</label>
          <input
            type="text"
            id="number"
            name="number"
            placeholder="Insira o número"
            value={addressFormik.values.number}
            onChange={addressFormik.handleChange}
          />
          {addressFormik.errors.number && (
            <FormError>{addressFormik.errors.number}</FormError>
          )}
          {addressFormik.status?.number &&
            addressFormik.status.number.map((error: string, index: number) => (
              <FormError key={index}>{error}</FormError>
            ))}
        </FormGroup>
        <FormGroup>
          <label htmlFor="lat">Latitude</label>
          <input
            type="number"
            name="lat"
            id="lat"
            max={999.999999}
            min={-999.999999}
            step={0.000001}
            value={addressFormik.values.lat || ''}
            onChange={(e) => {
              addressFormik.setFieldValue('lat', Number(e.target.value));
            }}
            placeholder="Insira a latitude (-90.000000 a 90.000000)"
          />
          {addressFormik.errors.lat && (
            <FormError>{addressFormik.errors.lat}</FormError>
          )}
          {addressFormik.status?.lat &&
            addressFormik.status.lat.map((error: string, index: number) => (
              <FormError key={index}>{error}</FormError>
            ))}
        </FormGroup>
        <FormGroup>
          <label htmlFor="long">Longitude</label>
          <input
            type="number"
            name="long"
            id="long"
            max={999.999999}
            min={-999.999999}
            step={0.000001}
            value={addressFormik.values.long || ''}
            onChange={(e) => {
              addressFormik.setFieldValue('long', Number(e.target.value));
            }}
            placeholder="Insira a longitude (-180.000000 a 180.000000)"
          />
          {addressFormik.errors.long && (
            <FormError>{addressFormik.errors.long}</FormError>
          )}
          {addressFormik.status?.long &&
            addressFormik.status.long.map((error: string, index: number) => (
              <FormError key={index}>{error}</FormError>
            ))}
        </FormGroup>
        <FormGroup>
          <label htmlFor="complement">Complemento</label>
          <input
            type="text"
            id="complement"
            name="complement"
            placeholder="Insira um complemento"
            value={addressFormik.values.complement}
            onChange={addressFormik.handleChange}
          />
          {addressFormik.errors.complement && (
            <FormError>{addressFormik.errors.complement}</FormError>
          )}
          {addressFormik.status?.complement &&
            addressFormik.status.complement.map(
              (error: string, index: number) => (
                <FormError key={index}>{error}</FormError>
              )
            )}
        </FormGroup>
      </GridForm>
      <SaveButton
        type="button"
        onClick={() => addressFormik.handleSubmit()}
        disabled={Object.keys(addressFormik.errors).length !== 0}
      >
        Salvar alterações
      </SaveButton>
    </Modal>
  );
};

export default AddressModal;
