import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;

  svg {
    font-size: 24px;
    margin-right: 12px;
  }

  span {
    color: ${({ theme }) => theme.colors.gray.six};
  }

  span:hover {
    cursor: pointer;
    text-decoration-line: underline;
  }

  span + span {
    font-weight: bold;
  }

  span + span:hover {
    text-decoration-line: none;
    cursor: default;
  }
`;

export const CustomerContainer = styled.div`
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
`;

export const CustomerInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.one};
`;

export const CustomerImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 14px;
`;

export const CustomerContent = styled.div`
  display: flex;
`;
export const CustomerData = styled.div`
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const CustomerDataHeader = styled.div`
  margin-bottom: 12px;

  span {
    font-weight: 500;
  }

  span + span {
    margin-left: 24px;
  }

  svg {
    margin-left: 4px;
    font-size: 14px;
    color: ${(props) => props.theme.colors.yellow.dark};
  }
`;
export const CustomerDataFooter = styled.div`
  max-width: 420px;
  display: flex;
  flex-direction: column;

  span {
    color: ${({ theme }) => theme.colors.orange.light};
    font-size: 14px;
  }
  span + span {
    margin-top: 8px;
  }
  span > span {
    font-weight: bold;
  }
`;

export const CustomerButtons = styled.div`
  button {
    width: 200px;
    height: 40px;
    border-radius: 8px;
    font-size: 14px;
  }
  button + button {
    margin-left: 24px;
  }
`;

export const EditInfoButton = styled.button`
  background: ${(props) => props.theme.colors.green.dark};
  color: white;
`;

export const CustomerAddressContainer = styled.div`
  padding-top: 12px;

  h1 {
    font-size: 16px;
    font-weight: 500;
  }

  & > span {
    font-size: 14px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.orange.light};
    margin-top: 24px;
    display: block;
    text-align: center;
    cursor: pointer;
  }
`;
