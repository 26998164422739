import { PrimaryButton } from 'components/ProjectStyles';
import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 32px;
  position: relative;
  margin-bottom: 74px;
`;

export const QuestionsTitle = styled.span`
  color: ${({ theme }) => theme.colors.gray.three};
  font-weight: 600;
  margin: 32px 0px;
  display: block;
`;

export const QuestionsFooter = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.gray.one};
  padding-top: 16px;
`;

export const AddQuestionButton = styled.button`
  display: flex;
  align-items: center;
  background-color: rgba(39, 174, 96, 0.16);
  border-radius: 12px;
  padding: 6px 16px;
  color: ${({ theme }) => theme.colors.success};

  & > span {
    font-weight: 500;
    margin-left: 12px;
    font-size: 14px;
  }

  & > svg {
    font-size: 18px;
  }
`;

interface ISaveButton {
  blocked?: boolean;
}

export const SaveButton = styled(PrimaryButton)<ISaveButton>`
  width: 272px;
  justify-content: center;
  position: fixed;
  bottom: 12px;
  right: 48px;
  background-color: ${(props) =>
    props.blocked
      ? props.theme.colors.gray.three
      : props.theme.colors.orange.dark};
`;
