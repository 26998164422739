import styled from 'styled-components';

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: ${(props) => `1px solid ${props.theme.colors.gray.two}`};
  border-radius: 8px;
  padding: 36px 24px;
  height: 112px;
`;

export const CardTitle = styled.div`
  color: ${(props) => props.theme.colors.gray.six};
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: ${(props) => props.theme.fontWeight.bold};
`;

export const CardDescription = styled.div`
  color: ${(props) => props.theme.colors.gray.four};
  font-size: 12px;
  font-weight: ${(props) => props.theme.fontWeight.normal};
  margin-top: 5px;
  margin-right: 16px;
`;

export const CardValueContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CardValue = styled.span`
  color: ${(props) => props.theme.colors.gray.six};
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  margin-left: 5px;
`;

export const CardValueMeasure = styled.span`
  color: ${(props) => props.theme.colors.gray.six};
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: ${(props) => props.theme.fontWeight.normal};
  margin-left: 5px;
`;
