import { FormikProps } from 'formik';
import { MdAddCircle } from 'react-icons/md';

import {
  AddQuestionButton,
  Container,
  QuestionsFooter,
  QuestionsTitle,
  SaveButton,
} from './styles';

import QuestionCard from './QuestionCard';
import { useCallback } from 'react';

interface IStepTwo {
  formik: FormikProps<IFormFormikProps>;
}

const StepTwo: React.FC<IStepTwo> = ({ formik }) => {
  const addQuestion = useCallback(() => {
    formik.setFieldValue('forms', [
      ...formik.values.forms,
      { type: 'text', question: '' },
    ]);
  }, [formik]);

  const deleteQuestion = useCallback(
    (index: number) => {
      formik.setFieldValue(
        'forms',
        formik.values.forms.filter((_, i) => i !== index)
      );
    },
    [formik]
  );

  const editQuestion = useCallback(
    (index: number, question: IForm) => {
      formik.setFieldValue(
        'forms',
        formik.values.forms.map((el, i) => (i === index ? question : el))
      );
    },
    [formik]
  );

  return (
    <Container>
      <QuestionsTitle>Perguntas</QuestionsTitle>
      {formik.values.forms.map((form, index) => (
        <QuestionCard
          key={index}
          index={index}
          form={form}
          setForm={(form) => editQuestion(index, form)}
          deleteQuestion={() => deleteQuestion(index)}
          toucheds={formik.touched.forms && formik.touched.forms[index]}
          errors={formik.errors.forms && formik.errors.forms[index]}
        />
      ))}
      <QuestionsFooter>
        <AddQuestionButton onClick={addQuestion}>
          <MdAddCircle />
          <span>Add pergunta</span>
        </AddQuestionButton>
      </QuestionsFooter>
      <SaveButton
        blocked={Object.keys(formik.errors).length !== 0}
        onClick={() => formik.handleSubmit()}
        type="button"
      >
        Salvar formulário
      </SaveButton>
    </Container>
  );
};

export default StepTwo;
