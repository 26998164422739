import styled from 'styled-components';

export const Container = styled.div`
  table tr {
    border-bottom: 2px solid ${(props) => props.theme.colors.gray.one};
  }
  table th,
  table td {
    padding: 16px 0px;
  }
`;
