import styled from 'styled-components';

export const DropZoneContainer = styled.div`
  background-color: #e8e8e8;
  border-width: 0px;
  border-radius: 8px;
  font-family: ${(props) => props.theme.fontFamily};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 376px;
  font-size: 14px;

  p {
    color: ${(props) => props.theme.colors.gray.six};
    font-weight: ${(props) => props.theme.fontWeight.semiBold};
    margin-left: 8px;
    cursor: default;
  }

  span {
    cursor: pointer;
    text-decoration: underline;
    color: ${(props) => props.theme.colors.gray.six};
    font-weight: ${(props) => props.theme.fontWeight.bold};
  }
`;
