import { IconType } from 'react-icons';
import { useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { Container } from './styles';

interface ISideBarItem {
  index: number;
  name: string;
  route: string;
  icon: IconType;
}

const SideBarItem: React.FC<ISideBarItem> = ({
  icon: Icon,
  index,
  name,
  route,
}) => {
  const theme = useTheme();
  const location = useLocation();

  const itemIsSelected =
    location.pathname.split('/')[1] === route.split('/')[1];

  return (
    <Container selected={itemIsSelected} index={index} to={route}>
      <Icon size={theme.sidebarIconSize} />
      <p>{name}</p>
    </Container>
  );
};

export default SideBarItem;
