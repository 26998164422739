import { B2Spinner } from 'components/B2';
import CloseButton from 'components/CloseButton';
import { FormError } from 'components/ProjectStyles';
import { useToast } from 'contexts/Toast';
import { FormikProps } from 'formik';
import { useService } from 'hooks';
import React, { useCallback, useEffect, useState } from 'react';
import {
  MdArrowBackIos,
  MdCheckBox,
  MdOutlineCheckBoxOutlineBlank,
} from 'react-icons/md';
import { clamp, dateJsToDateString, getAge } from 'utils/math';
import {
  ExperienceTime,
  HeaderContainer,
  NextButton,
  Service,
  ServiceContainer,
  Title,
  TitleContainer,
} from '../styles';

interface IServicesData {
  closeModal: () => void;
  nextStep: () => void;
  prevStep: () => void;
  formik: FormikProps<IWorkerFormik>;
}

const ServicesData: React.FC<IServicesData> = ({
  closeModal,
  nextStep,
  prevStep,
  formik,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [services, setServices] = useState<IService[]>([]);

  const { listServices } = useService();
  const { addToast } = useToast();

  const formHasAnError = !!formik.errors.skills;

  const getServices = useCallback(async () => {
    setIsLoading(true);
    const response = await listServices();

    if (response.error) {
      addToast('Não foi possível carregar os serviços', 'error');
    } else {
      setServices(response.message);
    }
    setIsLoading(false);
  }, [addToast, listServices]);

  const addService = (id: number) => {
    formik.setFieldValue('skills', [
      ...formik.values.skills,
      {
        service: id,
        since: dateJsToDateString(new Date()),
      },
    ]);
  };

  const removeService = (id: number) => {
    formik.setFieldValue(
      'skills',
      formik.values.skills.filter((skill) => skill.service !== id)
    );
  };

  const handleExperienceTime = (serviceId: number, value: string) => {
    formik.setFieldValue(
      'skills',
      formik.values.skills.map((skill) => {
        if (skill.service !== serviceId) {
          return skill;
        } else {
          const experienceTimeDate = new Date();
          experienceTimeDate.setFullYear(
            experienceTimeDate.getFullYear() - Number(value)
          );
          return {
            ...skill,
            since: dateJsToDateString(experienceTimeDate),
          };
        }
      })
    );
  };

  useEffect(() => {
    getServices();
  }, [getServices]);

  return (
    <>
      <HeaderContainer>
        <TitleContainer>
          <MdArrowBackIos onClick={prevStep} />
          <Title>Serviços *</Title>
        </TitleContainer>
        <CloseButton onClick={closeModal} />
      </HeaderContainer>
      <ServiceContainer>
        {isLoading ? (
          <B2Spinner />
        ) : (
          services.map((service) => {
            const selectedSkill = formik.values.skills.find(
              (skill) => skill.service === service.id
            );
            return (
              <React.Fragment key={service.id}>
                <Service
                  selected={!!selectedSkill}
                  onClick={() => {
                    !!selectedSkill
                      ? removeService(service.id)
                      : addService(service.id);
                  }}
                >
                  {!!selectedSkill ? (
                    <MdCheckBox />
                  ) : (
                    <MdOutlineCheckBoxOutlineBlank />
                  )}
                  <span>{service.name}</span>
                </Service>
                {!!selectedSkill && (
                  <ExperienceTime>
                    <label htmlFor="since">
                      Tempo de experiência (em anos)
                    </label>
                    <input
                      type="number"
                      name="since"
                      id="since"
                      placeholder="Informe o tempo em anos"
                      min={0}
                      max={100}
                      value={getAge(selectedSkill.since)}
                      onChange={(e) => {
                        e.target.value = clamp(
                          Number(e.target.value),
                          0,
                          100
                        ).toString();
                        handleExperienceTime(service.id, e.target.value);
                      }}
                    />
                  </ExperienceTime>
                )}
              </React.Fragment>
            );
          })
        )}
      </ServiceContainer>
      {formik.errors.skills && formik.touched.skills && (
        <FormError>{formik.errors.skills}</FormError>
      )}
      <NextButton
        type="button"
        blocked={formHasAnError}
        onClick={() => formik.handleSubmit()}
      >
        Salvar
      </NextButton>
    </>
  );
};

export default ServicesData;
