import { PrimaryButton } from 'components/ProjectStyles';
import styled from 'styled-components';

export const FormGroupContainer = styled.div`
  display: flex;

  div + div {
    margin-left: 60px;
  }
`;

interface ResetButtonProps {
  hide: boolean;
}

export const ResetButton = styled.button<ResetButtonProps>`
  background-color: ${(props) => props.theme.colors.gray.two};
  width: 90px;
  height: 32px;
  justify-self: center;
  border-radius: 8px;
  margin-top: 8px;
  visibility: ${(props) => (props.hide ? 'hidden' : 'visible')};
`;

export const ScheduleContainer = styled.div`
  margin-top: 12px;
  margin-bottom: 80px;
  display: grid;
  grid-template-columns: 84px repeat(7, 128px);
  grid-template-rows: repeat(3, 40px);
  grid-row-gap: 8px;

  label {
    background-color: ${(props) => props.theme.colors.gray.one};
    color: #363636;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
  }

  button:first-of-type {
    grid-column-start: 2;
  }

  label:first-of-type {
    grid-column-start: 2;
    border-radius: 8px 0 0 8px;
  }

  label:last-of-type {
    border-radius: 0px 8px 8px 0;
  }

  label:nth-child(even) {
    background-color: #f4f4f4;
  }

  p {
    color: ${(props) => props.theme.colors.gray.four};
    font-weight: 500;
    font-size: 14px;
    align-self: center;
  }

  input {
    padding: 0 4px;
    width: 108px;
    justify-self: center;
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.colors.gray.one};
  }

  span {
    text-align: center;
  }

  span:first-of-type {
    grid-column-start: 2;
  }
`;

interface ISaveButton {
  blocked?: boolean;
}

export const SaveButton = styled(PrimaryButton)<ISaveButton>`
  width: 272px;
  justify-content: center;
  position: fixed;
  bottom: 12px;
  right: 48px;
  background-color: ${(props) =>
    props.blocked
      ? props.theme.colors.gray.three
      : props.theme.colors.orange.dark};
`;
