import styled from 'styled-components';

export const LandingPage = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background: ${(props) => props.theme.colors.main};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LandingPageIcon = styled.img`
  width: 50%;
`;
