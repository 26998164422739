import styled from 'styled-components';

export const Container = styled.div`
  /* width: 100vw; */
  height: 100vh;
`;

export const Header = styled.div`
  box-sizing: border-box;
  height: 70px;
  background-color: ${(props) => props.theme.colors.white};
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
`;

export const Logo = styled.img`
  height: 60px;
  align-self: center;
  margin-bottom: 40px;
`;

export const Menu = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Username = styled.span`
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: ${(props) => props.theme.colors.gray.six};
  margin-right: 10px;
`;

export const UserAvatar = styled.img`
  width: 40px;
  height: 40px;
  border: 2px solid ${(props) => props.theme.colors.secondary};
  border-radius: 50%;
  padding: 3px;
  margin-right: 10px;
`;

export const MainContent = styled.div`
  /* background-color: #f2f2f2; */
  display: flex;
  height: 100%;
`;

export const Sidebar = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  box-sizing: border-box;
  height: 100%;
  padding: 37px;
  border-right: 2px solid ${(props) => props.theme.colors.gray.one};
  width: 320px;
  display: flex;
  flex-direction: column;
`;

export const PrivateBaseContent = styled.div`
  width: 100%;
  height: calc(100% - 70px);
  box-sizing: border-box;
  overflow: auto;
  padding: 32px;
  position: relative;
`;

export const LogoutButton = styled.span`
  color: ${(props) => props.theme.colors.main};
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: ${(props) => props.theme.fontWeight.medium};
`;

export const Title = styled.h1`
  font-size: ${(props) => props.theme.fontSize.big};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  display: flex;
  align-items: start;
`;

export const MainPanel = styled.div`
  width: calc(100% - 320px);
`;

export const MenuButton = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.gray.one};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & > svg {
    color: ${(props) => props.theme.colors.gray.three};
  }
`;
