import { B2Spinner } from 'components/B2';
import { B2Table, B2TableDataCell, B2TableRow } from 'components/B2/B2Table';
import { AvatarContainer, SecondaryButton } from 'components/ProjectStyles';
import Search from 'components/Search';
import { useToast } from 'contexts/Toast';
import useDebounce from 'hooks/useDebounce';
import useWorkers from 'hooks/Workers';
import { useCallback, useEffect, useState } from 'react';
import { cpfMask } from 'utils/masks';
import { getAge } from 'utils/math';
import WorkerModal from './WorkerModal';

const Requests = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [workersPaginated, setWorkersPaginated] =
    useState<IPaginated<IWorker>>();
  const [selectedWorker, setSelectedWorker] = useState<IWorker>();
  const [workerModalIsOpen, setWorkerModalIsOpen] = useState(false);
  const { addToast } = useToast();

  const [query, setQuery] = useState({
    currentPage: 1,
    search: '',
  });

  const debouncedSearch = useDebounce(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setQuery({
        currentPage: 1,
        search: e.target.value,
      });
    },
    1000
  );

  const {
    listPendingWorkers,
    approveDocument,
    approveFaceCheck,
    rejectDocument,
    rejectFaceCheck,
    banUser,
  } = useWorkers();

  const banWorker = async (worker: IWorker) => {
    if (
      window.confirm(`Tem certeza que deseja banir o usuário ${worker.name} ?`)
    ) {
      const response = await banUser(worker.id);

      if (response.error) {
        addToast('Não foi possível banir esse usuário.');
      } else {
        addToast('Usuário banido com sucesso', 'success');
        getWorkers();
      }

      setWorkerModalIsOpen(false);
    }
  };

  const approveWorkerDocument = async (id: number) => {
    const response = await approveDocument(id);

    if (response.error) {
      addToast('Não foi possível aprovar esse documento.');
    } else {
      setWorkersPaginated({
        ...workersPaginated,
        results: workersPaginated?.results?.map((worker) => {
          if (worker.id === selectedWorker?.id) {
            return { ...worker, documents: response.message };
          }
          return worker;
        }),
      });
      selectedWorker &&
        setSelectedWorker({ ...selectedWorker, documents: response.message });
    }
  };

  const rejectWorkerDocument = async (id: number, reason: string) => {
    const response = await rejectDocument(id, reason);

    if (response.error) {
      addToast('Não foi possível rejeitar esse documento.');
    } else {
      setWorkersPaginated({
        ...workersPaginated,
        results: workersPaginated?.results?.map((worker) => {
          if (worker.id === selectedWorker?.id) {
            return { ...worker, documents: response.message };
          }
          return worker;
        }),
      });
      selectedWorker &&
        setSelectedWorker({ ...selectedWorker, documents: response.message });
    }
  };

  const approveWorkerFaceCheck = async (id: number) => {
    const response = await approveFaceCheck(id);

    if (response.error) {
      addToast('Não foi possível aprovar essa foto.');
    } else {
      setWorkersPaginated({
        ...workersPaginated,
        results: workersPaginated?.results?.map((worker) => {
          if (worker.id === selectedWorker?.id) {
            return { ...worker, face_check: response.message };
          }
          return worker;
        }),
      });
      selectedWorker &&
        setSelectedWorker({ ...selectedWorker, face_check: response.message });
    }
  };

  const rejectWorkerFaceCheck = async (id: number, reason: string) => {
    const response = await rejectFaceCheck(id, reason);

    if (response.error) {
      addToast('Não foi possível rejeitar essa foto.');
    } else {
      setWorkersPaginated({
        ...workersPaginated,
        results: workersPaginated?.results?.map((worker) => {
          if (worker.id === selectedWorker?.id) {
            return { ...worker, face_check: response.message };
          }
          return worker;
        }),
      });
      selectedWorker &&
        setSelectedWorker({ ...selectedWorker, face_check: response.message });
    }
  };

  const analyseProfile = {
    document: {
      approve: approveWorkerDocument,
      reject: rejectWorkerDocument,
    },
    faceCheck: {
      approve: approveWorkerFaceCheck,
      reject: rejectWorkerFaceCheck,
    },
    endAnalysis: () => {
      getWorkers();
    },
    banWorker,
  };

  const getWorkers = useCallback(async () => {
    setIsLoading(true);
    const response = await listPendingWorkers(query.currentPage, query.search);

    if (response.error) {
      addToast('Não foi possível carregar a lista de prestadores', 'error');
    } else {
      setWorkersPaginated(response.message);
    }
    setIsLoading(false);
  }, [query, listPendingWorkers, addToast]);

  useEffect(() => {
    getWorkers();
  }, [getWorkers]);

  return (
    <>
      <Search onChange={debouncedSearch} />
      {isLoading ? (
        <B2Spinner />
      ) : (
        <B2Table
          headerData={['Prestador', 'CPF', 'Serviços']}
          tableData={workersPaginated?.results || []}
          tableEmptyComponent={() => <p>Nenhum dado encontrado</p>}
          paginator
          renderRow={(worker: IWorker) => (
            <B2TableRow key={worker.id}>
              <B2TableDataCell>
                <AvatarContainer>
                  <img
                    src={
                      worker.avatar?.image_high_url ||
                      'images/blank-profile.png'
                    }
                    alt="Imagem do avatar"
                  />
                  <span>{worker.name}</span>
                </AvatarContainer>
              </B2TableDataCell>
              <B2TableDataCell>{cpfMask(worker.cpf) || '-'}</B2TableDataCell>
              <B2TableDataCell>
                {worker.skills?.map((skill) => skill.service.name).join('; ') ||
                  '-'}
              </B2TableDataCell>
              <B2TableDataCell style={{ textAlign: 'right' }}>
                <SecondaryButton
                  onClick={() => {
                    setSelectedWorker({
                      ...worker,
                      age: getAge(worker.birth_date),
                      experience: getAge(worker.works_since || ''),
                    });
                    setWorkerModalIsOpen(true);
                  }}
                >
                  Ver perfil
                </SecondaryButton>
              </B2TableDataCell>
            </B2TableRow>
          )}
          total={workersPaginated?.count}
          changePage={(newPage) =>
            setQuery({
              ...query,
              currentPage: newPage,
            })
          }
          currentPage={query.currentPage}
        />
      )}
      {selectedWorker && (
        <WorkerModal
          closeModal={() => setWorkerModalIsOpen(false)}
          modalIsOpen={workerModalIsOpen}
          worker={selectedWorker}
          analyseProfile={analyseProfile}
        />
      )}
    </>
  );
};

export default Requests;
