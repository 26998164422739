import styled, { css } from 'styled-components';

interface StepProps {
  selected: boolean;
  disabled?: boolean;
}

export const StepsContainer = styled.div`
  display: flex;
  border-bottom: 2px solid ${({ theme }) => theme.colors.gray.one};

  & > button + button {
    margin-left: 24px;
  }
`;

export const Step = styled.button<StepProps>`
  display: flex;
  align-items: center;
  padding: 8px 4px;
  color: ${({ theme }) => theme.colors.gray.five};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  ${({ selected }) =>
    selected &&
    css`
      font-weight: bold;
      border-bottom: 2px solid ${({ theme }) => theme.colors.orange.dark};
    `}
`;

interface IWarningCircle {
  dirty?: boolean;
  error?: boolean;
}

export const WarningCircle = styled.div<IWarningCircle>`
  margin-left: 8px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: transparent;

  ${({ dirty }) =>
    dirty &&
    css`
      background-color: ${({ theme }) => theme.colors.orange.light};
    `}

  ${({ error }) =>
    error &&
    css`
      background-color: ${({ theme }) => theme.colors.red.dark};
    `}
`;

export const Header = styled.div`
  background-color: ${(props) => props.theme.colors.gray.one};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 16px 32px;
  color: ${(props) => props.theme.colors.gray.four};
  line-height: 16px;
  display: flex;

  span {
    font-weight: ${(props) => props.theme.fontWeight.bold};
    margin: 0 8px;
  }
`;

interface ILabel {
  active: boolean;
}

export const Label = styled.button<ILabel>`
  font-weight: ${(props) =>
    props.active ? props.theme.fontWeight.bold : props.theme.fontWeight.normal};
`;

export const Container = styled.div`
  margin-top: 40px;
`;
