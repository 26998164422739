import { B2Spinner, B2ToggleSwitch } from 'components/B2';
import { B2Table, B2TableDataCell, B2TableRow } from 'components/B2/B2Table';
import {
  AvatarContainer,
  PageHeaderContainer,
  PageHeaderTitle,
  SecondaryButton,
} from 'components/ProjectStyles';
import Search from 'components/Search';
import { useToast } from 'contexts/Toast';
import { useCustomers, useDebounce } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { phoneMask } from 'utils/masks';
import { Container } from './styles';

const Customers = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [customersPaginated, setCustomersPaginated] =
    useState<IPaginated<ICustomer>>();
  const { addToast } = useToast();
  const { listCustomers, updateCustomer } = useCustomers();
  const history = useHistory();

  const [query, setQuery] = useState({
    currentPage: 1,
    search: '',
  });

  const debouncedSearch = useDebounce((search: string) => {
    setQuery({
      currentPage: 1,
      search,
    });
  }, 1000);

  const getCustomers = useCallback(async () => {
    setIsLoading(true);
    const response = await listCustomers(query.currentPage, query.search);

    if (response.error) {
      addToast('Não foi possível carregar a lista de clientes', 'error');
    } else {
      setCustomersPaginated(response.message);
    }
    setIsLoading(false);
  }, [query, listCustomers, addToast]);

  useEffect(() => {
    getCustomers();
  }, [getCustomers]);

  const toggleActivateCustomer = useCallback(
    async (id: number, activate: boolean) => {
      const response = await updateCustomer(id, { is_active: activate });

      if (response.error) {
        addToast(
          `Não foi possível ${activate ? 'ativar' : 'desativar'} esse cliente`,
          'error'
        );
      } else {
        setCustomersPaginated((prevState) => ({
          ...prevState,
          results: prevState?.results?.map((customer) =>
            customer.id === id ? { ...customer, is_active: activate } : customer
          ),
        }));
      }
    },
    [addToast, updateCustomer]
  );

  return (
    <Container>
      <PageHeaderContainer>
        <PageHeaderTitle>Clientes</PageHeaderTitle>
      </PageHeaderContainer>
      <Search
        onChange={(e) => {
          debouncedSearch(e.target.value);
        }}
      />
      {isLoading ? (
        <B2Spinner />
      ) : (
        <B2Table
          headerData={['Cliente', 'E-mail', 'Telefone', 'Ativo']}
          tableData={customersPaginated?.results || []}
          tableEmptyComponent={() => <p>Nenhum dado encontrado</p>}
          paginator
          renderRow={(customer: ICustomer) => (
            <B2TableRow key={customer.id}>
              <B2TableDataCell>
                <AvatarContainer>
                  <img
                    src={
                      customer.avatar?.image_high_url ||
                      '/images/blank-profile.png'
                    }
                    alt="Imagem do avatar"
                  />
                  <span>{customer.name}</span>
                </AvatarContainer>
              </B2TableDataCell>
              <B2TableDataCell>{customer.email}</B2TableDataCell>
              <B2TableDataCell>
                {customer.phone
                  ? phoneMask(
                      customer.phone.ddd.toString() +
                        customer.phone.number.toString()
                    )
                  : ''}
              </B2TableDataCell>
              <B2TableDataCell>
                <B2ToggleSwitch
                  defaultChecked={customer.is_active}
                  id={customer.id.toString()}
                  handleToggle={() => {
                    toggleActivateCustomer(customer.id, !customer.is_active);
                  }}
                />
              </B2TableDataCell>
              <B2TableDataCell
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <SecondaryButton
                  onClick={() => {
                    history.push(`/clientes/${customer.id}`);
                  }}
                >
                  Ver perfil
                </SecondaryButton>
              </B2TableDataCell>
            </B2TableRow>
          )}
          total={customersPaginated?.count}
          changePage={(newPage) => setQuery({ ...query, currentPage: newPage })}
          currentPage={query.currentPage}
        />
      )}
    </Container>
  );
};

export default Customers;
