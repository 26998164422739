import React, { useEffect, useRef, useState } from 'react';
import { clamp } from '../../../utils';

import { Container, LeftPanel, RightPanel, Logo, Card } from './styles';

const AuthBase: React.FC = ({ children }) => {
  const [mouseMovement, setMouseMovement] = useState({ x: 0, y: 0 });
  const logo = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (logo.current) {
      logo.current.style.transform = `translate(${mouseMovement.x}px, ${mouseMovement.y}px)`;
    }
  }, [mouseMovement]);

  return (
    <Container>
      <LeftPanel>
        <Logo
          src="images/main-logo.svg"
          alt="Logo"
          movement={mouseMovement}
          ref={logo}
          onMouseMove={(event) => {
            const newPosition = {
              x: clamp(mouseMovement.x + event.movementX / 40, -100, 100),
              y: clamp(mouseMovement.y + event.movementY / 40, -100, 100),
            };
            setMouseMovement(newPosition);
          }}
          onMouseLeave={() => {
            setMouseMovement({ x: 0, y: 0 });
          }}
        />
      </LeftPanel>
      <RightPanel>
        <Card>{children}</Card>
      </RightPanel>
    </Container>
  );
};

export default AuthBase;
