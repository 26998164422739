import { endpoints, useApi } from 'contexts/Api';
import { useCallback } from 'react';
import { requestContextError } from './settings';

const useStaffs = () => {
  const { request } = useApi();

  const listStaffs = useCallback(
    (page: number, search?: string) => {
      if (request) {
        return request({
          url: endpoints.staffs,
          method: 'get',
          params: {
            page,
            search,
          },
        });
      }

      return requestContextError;
    },
    [request]
  );

  const readStaff = useCallback(
    (id: number) => {
      if (request) {
        return request({
          url: `${endpoints.staffs}${id}/`,
          method: 'get',
        });
      }

      return requestContextError;
    },
    [request]
  );

  const createStaff = useCallback(
    (data) => {
      if (request) {
        return request({
          url: endpoints.staffs,
          method: 'post',
          data,
        });
      }
      return requestContextError;
    },
    [request]
  );

  const updateStaff = useCallback(
    (id: number, data) => {
      if (request) {
        return request({
          url: `${endpoints.staffs}${id}/`,
          method: 'put',
          data,
        });
      }
      return requestContextError;
    },
    [request]
  );

  const deleteStaff = useCallback(
    (id: number) => {
      if (request) {
        return request({
          url: `${endpoints.staffs}${id}/`,
          method: 'delete',
        });
      }
      return requestContextError;
    },
    [request]
  );

  return {
    listStaffs,
    createStaff,
    updateStaff,
    readStaff,
    deleteStaff,
  };
};

export default useStaffs;
