import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface IContainer {
  selected?: boolean;
  index: number;
}

export const Container = styled(Link)<IContainer>`
  height: 60px;
  color: ${(props) =>
    props.selected ? props.theme.colors.main : props.theme.colors.gray.five};
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0;
  opacity: 0;
  animation: show-rightwards 0.5s ease-out ${(props) => props.index * 0.1}s
      forwards,
    fade-in 0.5s ease-out ${(props) => props.index * 0.1}s forwards;
  background-color: ${(props) =>
    props.selected ? props.theme.colors.gray.one : 'transparent'};
  border-radius: ${(props) => props.theme.borderRadius};

  & svg {
    margin-right: 10px;
    transition: all 0.5s ease;
    ${(props) => (props.selected ? 'margin-left: 10px' : '')};
  }

  & p {
    font-weight: ${(props) =>
      props.selected
        ? props.theme.fontWeight.bold
        : props.theme.fontWeight.medium};
    transition: all 0.5s ease;
  }
`;
