import { PrimaryButton } from 'components/ProjectStyles';
import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 80px;

  table {
    margin: 32px 0;
  }

  tr button {
    margin-left: auto;
  }

  tr {
    border-bottom: 2px solid #eaeaea;
  }

  th {
    padding: 16px 0;
  }
`;

export const Title = styled.span`
  font-weight: 500;
  color: ${(props) => props.theme.colors.gray.four};
  margin-top: 32px;
  display: inline-block;
`;

interface ISaveButton {
  blocked?: boolean;
}

export const SaveButton = styled(PrimaryButton)<ISaveButton>`
  width: 272px;
  justify-content: center;
  position: fixed;
  bottom: 12px;
  right: 48px;
  background-color: ${(props) =>
    props.blocked
      ? props.theme.colors.gray.three
      : props.theme.colors.orange.dark};
`;
