import axios from 'axios';
import CloseButton from 'components/CloseButton';
import { FormGroup } from 'components/ProjectStyles';
import StateSelect from 'components/StateSelect';
import { useToast } from 'contexts/Toast';
import { FormikProps } from 'formik';
import { useState } from 'react';
import { MdArrowBackIos } from 'react-icons/md';
import { cepMask, onlyNumbers } from 'utils/masks';
import { renderFormikErrors } from 'utils/renderFormikErrors';
import {
  GridForm,
  HeaderContainer,
  NextButton,
  Title,
  TitleContainer,
} from '../styles';

interface ILocationData {
  closeModal: () => void;
  nextStep: () => void;
  prevStep: () => void;
  formik: FormikProps<IWorkerFormik>;
}

const LocationData: React.FC<ILocationData> = ({
  closeModal,
  nextStep,
  prevStep,
  formik,
}) => {
  const [loadingAddress, setLoadingAddress] = useState(false);
  const formHasAnError = !!formik.errors.address;

  const touchFields = () => {
    formik.setTouched({
      address: {
        city: true,
        complement: true,
        lat: true,
        long: true,
        neighborhood: true,
        number: true,
        state: true,
        street: true,
        zip_code: true,
      },
    });
  };

  const { addToast } = useToast();

  const getAddress = async () => {
    const zip_code = formik.values.address.zip_code;
    if (!formik.errors.address?.zip_code) {
      try {
        setLoadingAddress(true);
        const response = await axios.get(
          `https://brasilapi.com.br/api/cep/v2/${zip_code}`
        );
        setLoadingAddress(false);
        const address = response.data;
        formik.setFieldValue('address.state', address.state || '');
        formik.setFieldValue('address.city', address.city || '');
        formik.setFieldValue(
          'address.neighborhood',
          address.neighborhood || ''
        );
        formik.setFieldValue('address.street', address.street || '');
        formik.setFieldValue(
          'address.lat',
          Number(address.location?.coordinates?.latitude).toFixed(6) ||
            undefined
        );
        formik.setFieldValue(
          'address.long',
          Number(address.location?.coordinates?.longitude).toFixed(6) ||
            undefined
        );
      } catch (error) {
        setLoadingAddress(false);
        formik.setFieldValue('address', {
          street: '',
          number: '',
          city: '',
          state: '',
          neighborhood: '',
          complement: '',
          lat: undefined,
          long: undefined,
          zip_code,
        });
        addToast(
          'Não foi possível pré-carregar os dados de endereço, verifique o CEP digitado',
          'warning'
        );
      }
    }
  };

  return (
    <>
      <HeaderContainer>
        <TitleContainer>
          <MdArrowBackIos onClick={prevStep} />
          <Title>Localização</Title>
        </TitleContainer>
        <CloseButton onClick={closeModal} />
      </HeaderContainer>
      <GridForm>
        <FormGroup>
          <label htmlFor="zip_code">CEP *</label>
          <input
            type="text"
            id="zip_code"
            placeholder="00000-000"
            value={cepMask(formik.values.address.zip_code)}
            onChange={(e) => {
              e.target.value = onlyNumbers(e.target.value);
              formik.setFieldValue('address', {
                ...formik.values.address,
                zip_code: e.target.value,
              });
            }}
            maxLength={10}
            onBlur={() => {
              formik.setTouched({
                address: {
                  zip_code: true,
                },
              });
              getAddress();
            }}
          />
          {renderFormikErrors(
            formik.errors.address?.zip_code,
            formik.touched.address?.zip_code,
            formik.status?.address?.zip_code
          )}
        </FormGroup>
        <FormGroup>
          <label htmlFor="state">Estado *</label>
          <StateSelect
            id="state"
            name="state"
            value={formik.values.address.state.toUpperCase()}
            onChange={(e) => {
              e.target.value = e.target.value.toUpperCase();
              formik.setFieldValue('address', {
                ...formik.values.address,
                state: e.target.value,
              });
            }}
            onBlur={() => {
              formik.setTouched({
                address: {
                  ...formik.touched.address,
                  state: true,
                },
              });
            }}
            disabled={loadingAddress}
          />
          {renderFormikErrors(
            formik.errors.address?.state,
            formik.touched.address?.state,
            formik.status?.address?.state
          )}
        </FormGroup>
        <FormGroup>
          <label htmlFor="city">Cidade *</label>
          <input
            type="text"
            id="city"
            placeholder="Insira a cidade"
            value={formik.values.address.city}
            onChange={(e) => {
              formik.setFieldValue('address', {
                ...formik.values.address,
                city: e.target.value,
              });
            }}
            onBlur={() => {
              formik.setTouched({
                address: {
                  ...formik.touched.address,
                  city: true,
                },
              });
            }}
            disabled={loadingAddress}
          />
          {renderFormikErrors(
            formik.errors.address?.city,
            formik.touched.address?.city,
            formik.status?.address?.city
          )}
        </FormGroup>
        <FormGroup>
          <label htmlFor="neighborhood">Bairro *</label>
          <input
            type="text"
            id="neighborhood"
            placeholder="Insira o bairro"
            value={formik.values.address.neighborhood}
            onChange={(e) => {
              formik.setFieldValue('address', {
                ...formik.values.address,
                neighborhood: e.target.value,
              });
            }}
            onBlur={() => {
              formik.setTouched({
                address: {
                  ...formik.touched.address,
                  neighborhood: true,
                },
              });
            }}
            disabled={loadingAddress}
          />
          {renderFormikErrors(
            formik.errors.address?.neighborhood,
            formik.touched.address?.neighborhood,
            formik.status?.address?.neighborhood
          )}
        </FormGroup>
        <FormGroup>
          <label htmlFor="street">Rua *</label>
          <input
            type="text"
            id="street"
            placeholder="Insira a rua"
            value={formik.values.address.street}
            onChange={(e) => {
              formik.setFieldValue('address', {
                ...formik.values.address,
                street: e.target.value,
              });
            }}
            onBlur={() => {
              formik.setTouched({
                address: {
                  ...formik.touched.address,
                  street: true,
                },
              });
            }}
            disabled={loadingAddress}
          />
          {renderFormikErrors(
            formik.errors.address?.state,
            formik.touched.address?.street,
            formik.status?.address?.street
          )}
        </FormGroup>
        <FormGroup>
          <label htmlFor="number">Número *</label>
          <input
            type="text"
            id="number"
            placeholder="Insira o número"
            value={formik.values.address.number}
            onChange={(e) => {
              formik.setFieldValue('address', {
                ...formik.values.address,
                number: e.target.value,
              });
            }}
            onBlur={() => {
              formik.setTouched({
                address: {
                  ...formik.touched.address,
                  number: true,
                },
              });
            }}
            disabled={loadingAddress}
          />
          {renderFormikErrors(
            formik.errors.address?.number,
            formik.touched.address?.number,
            formik.status?.address?.number
          )}
        </FormGroup>
        <FormGroup>
          <label htmlFor="lat">Latitude</label>
          <input
            type="number"
            name="lat"
            id="lat"
            max={999.999999}
            min={-999.999999}
            step={0.000001}
            value={formik.values.address.lat || ''}
            onChange={(e) => {
              formik.setFieldValue('address', {
                ...formik.values.address,
                lat: Number(e.target.value),
              });
            }}
            placeholder="Insira a latitude (-90.000000 a 90.000000)"
            onBlur={() => {
              formik.setTouched({
                address: {
                  ...formik.touched.address,
                  lat: true,
                },
              });
            }}
            disabled={loadingAddress}
          />
          {renderFormikErrors(
            formik.errors.address?.lat,
            formik.touched.address?.lat,
            formik.status?.address?.lat
          )}
        </FormGroup>
        <FormGroup>
          <label htmlFor="long">Longitude</label>
          <input
            type="number"
            name="long"
            id="long"
            max={999.999999}
            min={-999.999999}
            step={0.000001}
            value={formik.values.address.long || ''}
            onChange={(e) => {
              formik.setFieldValue('address', {
                ...formik.values.address,
                long: Number(e.target.value),
              });
            }}
            placeholder="Insira a longitude (-180.000000 a 180.000000)"
            onBlur={() => {
              formik.setTouched({
                address: {
                  ...formik.touched.address,
                  long: true,
                },
              });
            }}
            disabled={loadingAddress}
          />
          {renderFormikErrors(
            formik.errors.address?.long,
            formik.touched.address?.long,
            formik.status?.address?.long
          )}
        </FormGroup>
        <FormGroup>
          <label htmlFor="complement">Complemento</label>
          <input
            type="text"
            id="complement"
            placeholder="Insira um complemento"
            value={formik.values.address.complement}
            onChange={(e) => {
              formik.setFieldValue('address', {
                ...formik.values.address,
                complement: e.target.value,
              });
            }}
            disabled={loadingAddress}
          />
        </FormGroup>
      </GridForm>
      <NextButton
        blocked={formHasAnError}
        onClick={formHasAnError ? touchFields : nextStep}
      >
        Próximo
      </NextButton>
    </>
  );
};

export default LocationData;
