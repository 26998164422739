import styled from "styled-components";

export const Container = styled.div`
    width: 285px;
    height: 40px;
    padding: 8px;
    border: 1px solid ${(props) => props.theme.colors.gray.two};
    border-radius: 8px;
    display: flex;
    align-items: center;

    svg {
        color: ${(props) => props.theme.colors.gray.two};
        font-size: 24px;
    }

    input {
        margin-left: 8px;
        
    }
`;