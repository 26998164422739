import { B2Modal } from 'components/B2';
import { useToast } from 'contexts/Toast';
import { useFormik } from 'formik';
import { useWorkers } from 'hooks';
import { useState } from 'react';
import * as yup from 'yup';
import {
  cepValidator,
  cpfValidator,
  stateValidator,
} from '../../../../utils/validators';
import InfoMessage from './InfoMessage';
import LocationData from './LocationData';
import PersonalData from './PersonalData';
import ServicesData from './ServicesData';

interface ICreateEditWorkerModal {
  modalIsOpen: boolean;
  closeModal: () => void;
  selectedWorker?: IWorker;
  onSubmit: () => void;
}

const CreateEditWorkerModal: React.FC<ICreateEditWorkerModal> = ({
  modalIsOpen,
  closeModal,
  selectedWorker,
  onSubmit,
}) => {
  const [currentStep, setCurrentStep] = useState(0);

  const { registerWorker, updateWorker } = useWorkers();
  const { addToast } = useToast();

  const nextStep = () => setCurrentStep((prevState) => prevState + 1);

  const prevStep = () => setCurrentStep((prevState) => prevState - 1);

  const createWorker = async (worker: IWorkerFormik) => {
    const response = await registerWorker(worker);
    if (response.error) {
      handleServerErrors(response.message);
      addToast(
        'Erro a salvar os dados, verifique o formulário novamente',
        'error'
      );
    } else {
      nextStep();
    }
  };

  const editWorker = async (worker: IWorkerFormik) => {
    const response = await updateWorker(selectedWorker!.id, worker);
    if (response.error) {
      handleServerErrors(response.message);
      addToast(
        'Erro a salvar os dados, verifique o formulário novamente',
        'error'
      );
    } else {
      nextStep();
    }
  };

  const workerFormik = useFormik<IWorkerFormik>({
    initialValues: {
      name: selectedWorker?.name || '',
      cpf: selectedWorker?.cpf || '',
      birth_date: selectedWorker?.birth_date || '',
      phone: selectedWorker?.phone && {
        ddd: selectedWorker?.phone.ddd,
        number: selectedWorker?.phone.number,
        type: selectedWorker?.phone?.type?.value || 2,
      },
      email: selectedWorker?.email || '',
      address: {
        street: selectedWorker?.address?.street || '',
        number: selectedWorker?.address?.number || '',
        city: selectedWorker?.address?.city || '',
        state: selectedWorker?.address?.state || '',
        zip_code: selectedWorker?.address?.zip_code || '',
        neighborhood: selectedWorker?.address?.neighborhood || '',
        complement: selectedWorker?.address?.complement || '',
        lat: selectedWorker?.address?.point?.coordinates[0],
        long: selectedWorker?.address?.point?.coordinates[0],
      },
      skills:
        selectedWorker?.skills?.map((skill) => ({
          service: skill.service.id,
          since: skill.since,
        })) || [],
    },
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: yup.object({
      name: yup.string().required('Esse campo é obrigatório'),
      cpf: yup
        .string()
        .required('Esse campo é obrigatório')
        .length(11, 'CPF deve ter 11 dígitos')
        .test(
          'is-cpf-valid',
          'Campo inválido',
          (cpf) => !!cpf && cpfValidator(cpf)
        ),
      birth_date: yup.string().required('Esse campo é obrigatório'),
      phone: yup.object().nullable().required('Número é obrigatório'),
      email: yup
        .string()
        .email('Email inválido')
        .required('Esse campo é obrigatório'),
      address: yup.object().shape({
        zip_code: yup
          .string()
          .required('Esse campo é obrigatório')
          .length(8, 'CEP deve ter 8 dígitos')
          .test(
            'is-zipe_code-valid',
            'Campo inválido',
            (zip_code) => !!zip_code && cepValidator(zip_code)
          ),
        state: yup
          .string()
          .required('Esse campo é obrigatório')
          .test(
            'is-state-valid',
            'Campo inválido',
            (state) => !!state && stateValidator(state)
          ),
        city: yup.string().required('Esse campo é obrigatório'),
        neighborhood: yup.string().required('Esse campo é obrigatório'),
        street: yup.string().required('Esse campo é obrigatório'),
        number: yup.string().required('Esse campo é obrigatório'),
        lat: yup
          .number()
          .max(90, 'Número acima do limite')
          .min(-90, 'Número abaixo do limite'),
        long: yup
          .number()
          .max(180, 'Número acima do limite')
          .min(-180, 'Número abaixo do limite'),
      }),
      skills: yup
        .array()
        .of(
          yup.object().shape({
            service: yup.number().required('Informe o tipo de serviço'),
            since: yup
              .string()
              .required('Informe o tempo de experiência para cada serviço'),
          })
        )
        .min(1, 'Selecione pelo menos 1 serviço'),
    }),
    onSubmit: !!selectedWorker ? editWorker : createWorker,
  });

  const handleServerErrors = (error: any) => {
    workerFormik.setStatus(error);

    let firstStep = 2;

    if (error.address) {
      firstStep = 1;
    }

    if (
      error.name ||
      error.cpf ||
      error.birth_date ||
      error.phone ||
      error.email
    ) {
      firstStep = 0;
    }

    setCurrentStep(firstStep);
  };

  const steps = [
    <PersonalData
      closeModal={closeModal}
      nextStep={nextStep}
      formik={workerFormik}
    />,
    <LocationData
      closeModal={closeModal}
      nextStep={nextStep}
      prevStep={prevStep}
      formik={workerFormik}
    />,
    <ServicesData
      closeModal={closeModal}
      nextStep={nextStep}
      prevStep={prevStep}
      formik={workerFormik}
    />,
    <InfoMessage
      onSubmit={() => {
        onSubmit();
        closeModal();
      }}
      editMode={!!selectedWorker}
    />,
  ];

  return <B2Modal open={modalIsOpen}>{steps[currentStep]}</B2Modal>;
};

export default CreateEditWorkerModal;
