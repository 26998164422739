import { PrimaryButton } from 'components/ProjectStyles';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 2px solid ${(props) => props.theme.colors.gray.one};
`;

export const Title = styled.h2`
  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.gray.six};
`;

interface IApplyButton {
  blocked?: boolean;
}

export const ApplyButton = styled(PrimaryButton)<IApplyButton>`
  width: calc(100% - 84px);
  justify-content: center;
  margin: 16px auto 0px;
  font-weight: bold;
  height: 52px;
  background-color: ${(props) =>
    props.blocked
      ? props.theme.colors.gray.three
      : props.theme.colors.orange.dark};
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    font-size: 20px;
    margin-right: 16px;
    cursor: pointer;
  }
`;

export const ServiceContainer = styled.div`
  margin-top: 32px;
  max-height: 60vh;
  overflow-y: auto;
  min-width: 40vw;
`;

interface IService {
  selected: boolean;
}

export const Service = styled.div<IService>`
  background-color: ${(props) =>
    props.selected
      ? props.theme.colors.orange.dark
      : props.theme.colors.gray.one};
  border-radius: 8px;
  min-width: 460px;
  padding: 14px;
  color: ${(props) =>
    props.selected ? props.theme.colors.white : props.theme.colors.gray.six};
  display: flex;
  align-items: center;
  cursor: pointer;

  & + & {
    margin-top: 8px;
  }

  svg {
    font-size: 20px;
  }

  span {
    font-size: 14px;
    font-weight: 500;
    margin-left: 8px;
  }
`;
