import { MdClose } from 'react-icons/md';
import { Container } from './styles';

interface ICloseButton {
  onClick: () => void;
  style?: React.CSSProperties;
}

const CloseButton: React.FC<ICloseButton> = ({ onClick, style }) => {
  return (
    <Container onClick={onClick} style={style}>
      <MdClose />
    </Container>
  );
};

export default CloseButton;
