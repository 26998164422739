import React, { useState, useEffect } from 'react';

import { useToast } from 'contexts/Toast';
import { B2Spinner } from 'components/B2';
import {
  Card,
  CardTitle,
  CardDescription,
  CardValueContainer,
  CardValue,
  CardValueMeasure,
} from './styles';

interface IMetricProps {
  metric: IMetric;
}

const Metric: React.FC<IMetricProps> = ({ metric }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState(0);

  const { addToast } = useToast();

  useEffect(() => {
    const fetchValue = async () => {
      setIsLoading(true);
      const { error, message } = await metric.request();
      if (error) {
        addToast(`Falha ao carregar ${metric.title}`, 'error');
      } else {
        setValue(message[metric.key]);
      }
      setIsLoading(false);
    };

    fetchValue();
  }, [addToast, metric]);

  return (
    <Card>
      {isLoading ? (
        <B2Spinner />
      ) : (
        <>
          <div>
            <CardTitle>{metric.title}</CardTitle>
            {metric.description && (
              <CardDescription>{metric.description}</CardDescription>
            )}
          </div>
          <CardValueContainer>
            <metric.icon size={24} color={metric.iconColor} />
            {metric.measure === 'reais' ? (
              <>
                <CardValue>
                  {(value / 100).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </CardValue>
              </>
            ) : (
              <>
                <CardValue>{value}</CardValue>
                <CardValueMeasure>{metric.measure}</CardValueMeasure>
              </>
            )}
          </CardValueContainer>
        </>
      )}
    </Card>
  );
};

export default Metric;
