import {
  MdCheckBoxOutlineBlank,
  MdClose,
  MdRadioButtonUnchecked,
} from 'react-icons/md';
import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 32px;

  & > div + div {
    margin-top: 20px;
  }
`;

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
`;

export const CloseButton = styled(MdClose)`
  color: ${({ theme }) => theme.colors.gray.five};
  font-size: 24px;
  cursor: pointer;
`;

export const OptionsIcon = styled(MdRadioButtonUnchecked)`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.gray.two};
`;

export const CheckboxIcon = styled(MdCheckBoxOutlineBlank)`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.gray.two};
`;

export const InputContainer = styled.div`
  margin-left: 14px;

  input {
    color: ${({ theme }) => theme.colors.gray.four};
    cursor: text;
    width: 120px;
    padding: 4px 0;
    background-color: transparent;
  }

  div {
    border-bottom: 1px solid ${({ theme }) => theme.colors.main};
    transition: transform 250ms ease-in-out;
    transform: scaleX(0);
  }

  input:focus + div {
    transform: scaleX(1);
  }
`;
