import { B2Modal } from 'components/B2';
import { PrimaryButton } from 'components/ProjectStyles';
import styled from 'styled-components';

export const Modal = styled(B2Modal)`
  padding: 0px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid ${(props) => props.theme.colors.gray.one};
  padding: 12px 32px;
`;

export const Title = styled.h2`
  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.gray.six};
`;

export const ProfileDataContainer = styled.div`
  padding: 32px 64px 32px 32px;
  height: 70vh;
  overflow-y: auto;

  form {
    width: 100%;
  }

  input {
    width: 100%;
  }
`;

export const ImageFormGroup = styled.div`
  display: flex;
  align-items: flex-end;

  img {
    width: 56px;
    height: 56px;
    border-radius: 8px;
  }
`;

export const ImageDropzoneContainer = styled.div`
  margin-left: 20px;
  flex: 1;

  & > span {
    font-size: 12px;
    color: ${(props) => props.theme.colors.gray.four};
    font-weight: 500;
    margin-bottom: 8px;
    display: block;
  }
`;

interface ISaveButton {
  blocked?: boolean;
}

export const SaveButton = styled(PrimaryButton)<ISaveButton>`
  width: calc(100% - 84px);
  justify-content: center;
  margin: 16px auto 40px;
  font-weight: bold;
  height: 52px;
  background-color: ${(props) =>
    props.blocked
      ? props.theme.colors.gray.three
      : props.theme.colors.orange.dark};
`;
