import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  border-radius: 16px;
  overflow: hidden;
  min-width: 1138px;
`;

export const ImageContainer = styled.div`
  background-color: ${(props) => props.theme.colors.gray.six};
  padding: 16px 32px;
  width: 840px;
  height: 540px;
  position: relative;

  & > svg {
    font-size: 64px;
    color: ${(props) => props.theme.colors.gray.three};
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const LeftArrow = styled.button`
  position: absolute;
  width: 66px;
  height: 66px;
  border-radius: 50%;
  background-color: rgba(205, 205, 205, 0.6);
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: white;
    font-size: 80px;
  }
`;

export const RightArrow = styled.button`
  position: absolute;
  width: 66px;
  height: 66px;
  border-radius: 50%;
  background-color: rgba(205, 205, 205, 0.6);
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: white;
    font-size: 80px;
  }
`;

export const AnalyseContainer = styled.div`
  padding: 16px 32px;
  flex: 1;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 40px;
    height: 40px;
    border-radius: 6px;
  }

  span {
    font-size: 14px;
    font-weight: bold;
    color: ${(props) => props.theme.colors.gray.six};
    margin: 0 16px;
  }
`;

export const ImagesInfo = styled.div`
  margin: 44px 0px;

  span {
    font-size: 16px;
    font-weight: bold;
    color: ${(props) => props.theme.colors.gray.six};
  }

  p {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    margin-top: 12px;
    color: ${(props) => props.theme.colors.gray.four};
  }
`;

export const ConfirmButton = styled.button`
  background-color: ${(props) => props.theme.colors.green.dark};
  height: 40px;
  width: 100%;
  border-radius: 8px;
  font-weight: 500;
  color: white;
`;

export const RejectButton = styled.button`
  height: 40px;
  width: 100%;
  border-radius: 8px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.red.dark};
  border: 1px solid ${(props) => props.theme.colors.red.dark};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.2;
    `}
`;

export const RejectReason = styled.div`
  margin-top: 32px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;

  label {
    font-size: 14px;
    font-weight: bold;
    color: ${(props) => props.theme.colors.gray.six};
  }

  textarea {
    margin-top: 8px;
    height: 100px;
    background-color: #f4f4f4;
    border-radius: 8px;
    padding: 16px;
  }
`;
