import RoutesPath from 'router/routes';
import { StaffRole } from './enums';

export default function getHomePathByStaffRole(role?: StaffRole) {
  if (role === undefined) {
    return RoutesPath.auth.root;
  }

  const homePath = {
    [StaffRole.Admin]: RoutesPath.private.home,
    [StaffRole.Manager]: RoutesPath.private.home,
    [StaffRole.Support]: RoutesPath.private.home,
  };

  return homePath[role];
}
