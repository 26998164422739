import { FormikProps } from 'formik';

import { Form, FormError, FormGroup } from 'components/ProjectStyles';
import {
  FormGroupContainer,
  ResetButton,
  SaveButton,
  ScheduleContainer,
} from './styles';

import IconDropZone from 'components/IconDropZone';
import { renderFormikErrors } from 'utils/renderFormikErrors';

interface IStepOne {
  formik: FormikProps<IServiceDetailsFormik>;
  iconBackground: string;
}

const StepOne: React.FC<IStepOne> = ({ formik, iconBackground }) => {
  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormGroupContainer>
        <FormGroup>
          <label htmlFor="name">Nome do serviço</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Insira o nome"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            autoComplete="off"
          />
          {renderFormikErrors(
            formik.errors.name,
            formik.touched.name,
            formik.status?.name
          )}
        </FormGroup>
        <FormGroup>
          <label htmlFor="unit">Unidade de serviço</label>
          <input
            type="text"
            id="unit"
            name="unit"
            placeholder="Insira a unidade"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.unit}
            autoComplete="off"
          />
          {renderFormikErrors(
            formik.errors.unit,
            formik.touched.unit,
            formik.status?.unit
          )}
        </FormGroup>
      </FormGroupContainer>
      <FormGroup>
        <label htmlFor="icon">Adicione um ícone para a categoria</label>
        <IconDropZone
          style={{ marginRight: 'auto' }}
          icon={formik.values.icon}
          onFileUploaded={(file) => formik.setFieldValue('icon', file)}
          iconBackground={iconBackground}
        />
        {renderFormikErrors(
          formik.errors.icon,
          formik.touched.icon,
          formik.status?.icon
        )}
        <p>O ícone deve estar no formato .svg</p>
      </FormGroup>
      <FormGroup>
        <label>Horários de serviços</label>
        <ScheduleContainer>
          <label>Segunda</label>
          <label>Terça</label>
          <label>Quarta</label>
          <label>Quinta</label>
          <label>Sexta</label>
          <label>Sábado</label>
          <label>Domingo</label>
          <p>Hora inicial</p>
          {[0, 1, 2, 3, 4, 5, 6].map((item) => (
            <input
              key={item}
              type="time"
              value={formik.values.agenda[item].start || ''}
              onChange={(e) =>
                formik.setFieldValue('agenda', {
                  ...formik.values.agenda,
                  [item]: {
                    ...formik.values.agenda[item],
                    start: e.target.value,
                  },
                })
              }
            />
          ))}
          <p>Hora final</p>
          {[0, 1, 2, 3, 4, 5, 6].map((item) => (
            <input
              key={item}
              type="time"
              value={formik.values.agenda[item].end || ''}
              onChange={(e) =>
                formik.setFieldValue('agenda', {
                  ...formik.values.agenda,
                  [item]: {
                    ...formik.values.agenda[item],
                    end: e.target.value,
                  },
                })
              }
            />
          ))}
          {[0, 1, 2, 3, 4, 5, 6].map((item) => (
            <ResetButton
              key={item}
              type="button"
              hide={
                !formik.values.agenda[item].start &&
                !formik.values.agenda[item].end
              }
              onClick={() => {
                formik.setFieldValue('agenda', {
                  ...formik.values.agenda,
                  [item]: {
                    start: null,
                    end: null,
                  },
                });
              }}
            >
              Resetar
            </ResetButton>
          ))}
          {[0, 1, 2, 3, 4, 5, 6].map((item) => (
            <FormError key={item}>
              {formik.touched.agenda && formik.errors.agenda?.[item]}
            </FormError>
          ))}
        </ScheduleContainer>
      </FormGroup>
      <SaveButton
        blocked={Object.keys(formik.errors).length !== 0}
        type="submit"
      >
        Salvar dados
      </SaveButton>
    </Form>
  );
};

export default StepOne;
