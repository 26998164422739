import { useOutsideClick } from 'hooks';
import React, { useMemo, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import {
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  ListItem,
} from './styles';

interface IOption<T> {
  label: string;
  value: T;
}

interface IOptionsSelect<T> {
  options: IOption<T>[];
  value?: T;
  onChange: (op: T) => void;
}

const OptionsSelect = <T extends {}>({
  options,
  value,
  onChange,
}: IOptionsSelect<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = React.useRef(null);

  useOutsideClick(selectRef, () => setIsOpen(false));

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (option: T) => {
    onChange(option);
    setIsOpen(false);
  };

  const selected = useMemo(
    () => options.find((option) => option.value === value),
    [options, value]
  );

  return (
    <DropDownContainer ref={selectRef}>
      <DropDownHeader onClick={toggling} selected={!!selected}>
        {selected?.label || 'Selecione'}
        <FiChevronDown />
      </DropDownHeader>
      {isOpen && (
        <DropDownList>
          {options.map((option, index) => (
            <ListItem onClick={() => onOptionClicked(option.value)} key={index}>
              {option.label}
            </ListItem>
          ))}
        </DropDownList>
      )}
    </DropDownContainer>
  );
};

export default OptionsSelect;
