import React, { useEffect, useState } from 'react';
import { GroupBase, Props } from 'react-select';
import { useToast } from '../../contexts/Toast';
import { useService } from '../../hooks';
import FiltersSelect from '../FilterSelect';

function ServicesFilterSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: Props<Option, IsMulti, Group>) {
  const [availableServices, setAvailableServices] = useState<Array<Option>>();
  const [isLoading, setIsLoading] = useState(true);
  const { addToast } = useToast();

  const { listServices } = useService();

  useEffect(() => {
    const fetchServices = async () => {
      setIsLoading(true);
      const response = await listServices();
      setIsLoading(false);

      if (response.error) {
        addToast('Não foi possível carregar a lista de serviços', 'error');
        return;
      }

      setAvailableServices(
        response.message.map((service: IService) => ({
          value: service.id,
          label: service.name,
        }))
      );
    };

    fetchServices();
  }, [addToast, listServices]);

  return (
    <FiltersSelect
      {...props}
      placeholder="Selecione um serviço"
      options={availableServices}
      isLoading={isLoading}
      isClearable={true}
    />
  );
}

export default ServicesFilterSelect;
