import { useState } from 'react';

import { Container, Header, Label } from './styles';

import List from './List';
import Requests from './Requests';

interface IScreen {
  [key: string]: JSX.Element;
}

const Workers = () => {
  const [currentScreen, setCurrentScreen] = useState('list');

  const goToListScreen = () => setCurrentScreen('list');
  const goToRequestsScreen = () => setCurrentScreen('requests');

  const screen: IScreen = {
    list: <List />,
    requests: <Requests />,
  };

  return (
    <>
      <Header>
        <Label onClick={goToListScreen} active={currentScreen === 'list'}>
          Prestadores cadastrados
        </Label>
        <span>|</span>
        <Label
          onClick={goToRequestsScreen}
          active={currentScreen === 'requests'}
        >
          Solicitações de cadastro
        </Label>
      </Header>
      <Container>{screen[currentScreen]}</Container>
    </>
  );
};

export default Workers;
