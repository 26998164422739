import styled from 'styled-components';

export const Container = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: space-between;
  margin: 10px auto;

  div:nth-child(1) {
    animation: shrink-vertical 1s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite;
  }

  div:nth-child(2) {
    animation: shrink-vertical 1s 0.25s cubic-bezier(0.68, -0.55, 0.27, 1.55)
      infinite;
  }

  div:nth-child(3) {
    animation: shrink-vertical 1s 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55)
      infinite;
  }
`;

export const VerticalBar = styled.div`
  width: 5px;
  border-radius: 10px;
  height: 100%;
  background: ${(props) => props.theme.colors.main};
`;
