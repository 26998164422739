import { useState } from 'react';
import {
  MdImageNotSupported,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';

import {
  AnalyseContainer,
  ConfirmButton,
  Container,
  Header,
  ImageContainer,
  ImagesInfo,
  LeftArrow,
  RejectButton,
  RejectReason,
  RightArrow,
} from './styles';

import { B2Modal } from 'components/B2';
import CloseButton from 'components/CloseButton';

interface IDocumentsModal {
  modalIsOpen: boolean;
  closeModal: () => void;
  worker: IWorker;
  approve: (id: number) => Promise<void>;
  reject: (id: number, reason: string) => Promise<void>;
}

const DocumentsModal: React.FC<IDocumentsModal> = ({
  closeModal,
  modalIsOpen,
  worker,
  approve,
  reject,
}) => {
  const [rejectReason, setRejectReason] = useState('');
  const [currentImage, setCurrentImage] = useState(0);

  const images = [
    worker.documents!.primary_image.image_high_url,
    worker.documents!.secondary_image.image_high_url,
  ];

  return (
    <B2Modal open={modalIsOpen} modalStyle={{ padding: '0', margin: '60px' }}>
      <Container>
        <ImageContainer>
          {worker.documents ? (
            <img
              src={images[currentImage] || 'images/blank-profile.png'}
              alt="Foto enviada"
            />
          ) : (
            <MdImageNotSupported />
          )}

          {currentImage !== 0 && (
            <LeftArrow onClick={() => setCurrentImage(0)}>
              <MdKeyboardArrowLeft />
            </LeftArrow>
          )}
          {currentImage !== 1 && (
            <RightArrow onClick={() => setCurrentImage(1)}>
              <MdKeyboardArrowRight />
            </RightArrow>
          )}
        </ImageContainer>

        <AnalyseContainer>
          <Header>
            <img
              src={worker.avatar?.image_high_url || 'images/blank-profile.png'}
              alt="Foto de perfil"
            />
            <span>{worker.name}</span>
            <CloseButton onClick={closeModal} style={{ marginLeft: 'auto' }} />
          </Header>
          <ImagesInfo>
            <span>Fotos do documento</span>
            {/* <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
              fringilla elementum rutrum.
            </p> */}
          </ImagesInfo>
          <ConfirmButton
            onClick={() => {
              approve(worker.documents!.id);
              closeModal();
            }}
          >
            Aceitar
          </ConfirmButton>
          <RejectReason>
            <label htmlFor="rejectReason">Motivo da rejeição</label>
            <textarea
              id="rejectReason"
              placeholder="Escreva aqui o motivo da rejeição"
              value={rejectReason}
              onChange={(e) => setRejectReason(e.target.value)}
            />
          </RejectReason>
          <RejectButton
            disabled={!rejectReason}
            onClick={() => {
              reject(worker.documents!.id, rejectReason);
              closeModal();
            }}
          >
            Recusar
          </RejectButton>
        </AnalyseContainer>
      </Container>
    </B2Modal>
  );
};

export default DocumentsModal;
