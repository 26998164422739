import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray.two};
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 32px;
`;

export const Title = styled.span`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray.six};
`;

export const InfoContainer = styled.div`
  display: flex;

  & > div + div {
    margin-left: 24px;
  }
`;

export const DeleteQuestionButton = styled.button`
  display: flex;
  align-items: center;
  background-color: rgba(235, 87, 87, 0.16);
  border-radius: 12px;
  padding: 6px 16px;
  color: ${({ theme }) => theme.colors.error};
  margin-top: 32px;

  & > span {
    font-weight: 500;
    margin-left: 12px;
    font-size: 14px;
  }

  & > svg {
    font-size: 18px;
  }
`;
