import styled from "styled-components";

interface IContainer {
    color: string;
}

export const Container = styled.div<IContainer>`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 30px;
        height: 30px;
        object-fit: contain;
    }
`;