import styled from 'styled-components';

export default styled.input`
  border: 1px solid ${(props) => props.theme.colors.gray.one};
  background: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.borderRadius};
  font-family: ${(props) => props.theme.fontFamily};
  color: ${(props) => props.theme.colors.black};

  &:focus {
    border: 1px solid ${(props) => props.theme.colors.main};
  }

  ::-webkit-calendar-picker-indicator {
    background-image: url('/images/calendar-alt-solid.svg');
    filter: invert(0.6);
  }
`;
