import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MdAdd, MdThumbUpOffAlt } from 'react-icons/md';

import { useToast } from 'contexts/Toast';
import { useDebounce, useTrainings } from 'hooks';
import RoutesPath from 'router/routes';
import { getYoutubeId } from 'utils/masks';

import { B2ButtonsPage, B2Spinner } from 'components/B2';
import Search from 'components/Search';
import ServicesFilterSelect from 'components/ServicesFilterSelect';
import DeleteTrainingModal from '../../components/DeleteTrainingModal';

import {
  FiltersContainer,
  PageHeaderContainer,
  PageHeaderTitle,
  PrimaryButton,
} from 'components/ProjectStyles';

import {
  Container,
  DeleteTrainingButton,
  Divider,
  EditTrainingButton,
  TrainingButtonsContainer,
  TrainingItemContainer,
  TrainingItemDescription,
  TrainingItemInfo,
  TrainingItemTitle,
  TrainingLikesContainer,
  TrainingListContainer,
} from './styles';

interface IQuery {
  currentPage: number;
  search: string;
  serviceId?: number;
}

const Training: React.FC = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [trainingListPaginated, setTrainingListPaginated] =
    useState<IPaginated<ITraining>>();
  const [selectedTraining, setSelectedTraining] = useState<ITraining>();
  const [query, setQuery] = useState<IQuery>({
    currentPage: 1,
    search: '',
    serviceId: undefined,
  });

  const pages = useMemo(
    () =>
      trainingListPaginated?.count
        ? Math.ceil(trainingListPaginated?.count / 20)
        : 0,
    [trainingListPaginated?.count]
  );

  const history = useHistory();
  const { listTrainings } = useTrainings();
  const { addToast } = useToast();

  const debouncedSearch = useDebounce(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setQuery({
        ...query,
        currentPage: 1,
        search: e.target.value,
      });
    },
    1000
  );

  const getTrainings = useCallback(async () => {
    setIsLoading(true);
    const response = await listTrainings(
      query.currentPage,
      query.search,
      query.serviceId
    );
    if (response.error) {
      addToast('Erro ao carregar os treinamentos', 'error');
    } else {
      setTrainingListPaginated(response.message);
    }
    setIsLoading(false);
  }, [addToast, listTrainings, query]);

  useEffect(() => {
    getTrainings();
  }, [getTrainings]);

  return (
    <Container>
      <PageHeaderContainer>
        <PageHeaderTitle>Treinamento para os prestadores</PageHeaderTitle>
        <PrimaryButton
          onClick={() => history.push(RoutesPath.private.training.create)}
        >
          <MdAdd />
          <span>Adicionar treinamento</span>
        </PrimaryButton>
      </PageHeaderContainer>
      <FiltersContainer>
        <ServicesFilterSelect
          onChange={(service: any) => {
            setQuery({
              ...query,
              currentPage: 1,
              serviceId: service?.value,
            });
          }}
        />
        <Search onChange={debouncedSearch} />
      </FiltersContainer>

      <Divider />
      <TrainingListContainer>
        {isLoading ? (
          <B2Spinner />
        ) : trainingListPaginated?.results?.length === 0 ? (
          <TrainingItemTitle>Não há nenhum treinamento</TrainingItemTitle>
        ) : (
          trainingListPaginated?.results?.map((training) => (
            <React.Fragment key={training.id}>
              <TrainingItemContainer>
                <TrainingItemInfo>
                  <TrainingItemTitle>{training.name}</TrainingItemTitle>
                  <TrainingLikesContainer>
                    <MdThumbUpOffAlt />
                    <span>{training.likes}</span>
                  </TrainingLikesContainer>
                  <TrainingItemDescription>
                    {training.description}
                  </TrainingItemDescription>
                  <TrainingButtonsContainer>
                    <DeleteTrainingButton
                      variant="outline"
                      onClick={() => {
                        setSelectedTraining(training);
                        setShowDeleteModal(true);
                      }}
                    >
                      Excluir treinamento
                    </DeleteTrainingButton>
                    <EditTrainingButton
                      variant="outline"
                      onClick={() =>
                        history.push(
                          RoutesPath.private.training.edit.replace(
                            ':idTraining',
                            training.id.toString()
                          ),
                          {
                            training,
                          }
                        )
                      }
                    >
                      Editar treinamento
                    </EditTrainingButton>
                  </TrainingButtonsContainer>
                </TrainingItemInfo>
                <iframe
                  key={training.embed}
                  width="203"
                  height="125"
                  src={`https://www.youtube.com/embed/${getYoutubeId(
                    training.embed
                  )}`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </TrainingItemContainer>
              <Divider />
            </React.Fragment>
          ))
        )}
      </TrainingListContainer>
      {pages > 1 && (
        <B2ButtonsPage
          currentPage={query.currentPage}
          changePage={(newPage) => setQuery({ ...query, currentPage: newPage })}
          pages={pages}
        />
      )}
      {selectedTraining && (
        <DeleteTrainingModal
          onClose={() => {
            setSelectedTraining(undefined);
            setShowDeleteModal(false);
          }}
          onSubmit={getTrainings}
          open={showDeleteModal}
          training={selectedTraining}
        />
      )}
    </Container>
  );
};

export default Training;
