import { MdEdit } from 'react-icons/md';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const EditIcon = styled(MdEdit)`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.gray.four};
  cursor: pointer;
  margin-left: auto;
  display: block;
`;
