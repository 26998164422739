import { B2Modal, B2Spinner } from 'components/B2';
import CloseButton from 'components/CloseButton';
import OptionsSelect from 'components/OptionsSelect';
import { FormGroup } from 'components/ProjectStyles';
import { useToast } from 'contexts/Toast';
import { useFormik } from 'formik';
import useStaffs from 'hooks/Staffs';
import React, { useState } from 'react';
import { renderFormikErrors } from 'utils/renderFormikErrors';
import * as yup from 'yup';
import { FormContainer, HeaderContainer, SubmitButton, Title } from './styles';

interface ICreateEditStaffModal {
  modalIsOpen: boolean;
  closeModal: () => void;
  selectedUser?: IStaff;
  getSystemUsers: () => Promise<void>;
}

interface IStaffFormik {
  name: string;
  email: string;
  staff_role?: 0 | 1 | 2;
}

const CreateEditStaffModal: React.FC<ICreateEditStaffModal> = ({
  closeModal,
  modalIsOpen,
  selectedUser,
  getSystemUsers,
}) => {
  const { createStaff, updateStaff } = useStaffs();
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToast();

  const onCreateStaff = async (data: IStaffFormik) => {
    setIsLoading(true);
    const response = await createStaff(data);
    setIsLoading(false);

    if (response.error) {
      addToast('Não foi possível criar o usuário', 'error');
      staffFormik.setStatus(response.message);
      return;
    }

    addToast('Usuário criado com sucesso', 'success');
    closeModal();
    getSystemUsers();
  };

  const onUpdateStaff = async (data: IStaffFormik) => {
    setIsLoading(true);
    const response = await updateStaff(selectedUser!.id, data);
    setIsLoading(false);

    if (response.error) {
      addToast('Não foi possível editar o usuário', 'error');
      staffFormik.setStatus(response.message);
      return;
    }

    addToast('Usuário editado com sucesso', 'success');
    closeModal();
    getSystemUsers();
  };

  const staffFormik = useFormik<IStaffFormik>({
    enableReinitialize: true,
    initialValues: {
      name: selectedUser?.name || '',
      email: selectedUser?.email || '',
      staff_role: selectedUser?.staff_role_info.value,
    },
    validationSchema: yup.object({
      name: yup.string().required('Esse campo é obrigatório'),
      email: yup.string().required('Esse campo é obrigatório'),
      staff_role: yup.number().required('Esse campo é obrigatório'),
    }),
    onSubmit: selectedUser ? onUpdateStaff : onCreateStaff,
  });

  return (
    <B2Modal open={modalIsOpen}>
      <HeaderContainer>
        <Title>{selectedUser ? 'Alterar' : 'Novo'} funcionário</Title>
        <CloseButton
          onClick={() => {
            staffFormik.resetForm();
            closeModal();
          }}
        />
      </HeaderContainer>
      <FormContainer onSubmit={staffFormik.handleSubmit}>
        <FormGroup>
          <label htmlFor="name">Nome do funcionário</label>
          <input
            id="name"
            name="name"
            type="text"
            placeholder="Insira o nome"
            value={staffFormik.values.name}
            onChange={staffFormik.handleChange}
          />
          {renderFormikErrors(
            staffFormik.errors.name,
            staffFormik.touched.name,
            staffFormik.status?.name
          )}
        </FormGroup>
        <FormGroup>
          <label htmlFor="email">E-mail</label>
          <input
            id="email"
            name="email"
            type="text"
            placeholder="Insira o e-mail"
            value={staffFormik.values.email}
            onChange={staffFormik.handleChange}
          />
          {renderFormikErrors(
            staffFormik.errors.email,
            staffFormik.touched.email,
            staffFormik.status?.email
          )}
        </FormGroup>
        <FormGroup>
          <label htmlFor="staff_role">Nível de acesso</label>
          <OptionsSelect
            onChange={(value) => {
              staffFormik.setFieldValue('staff_role', value);
            }}
            options={[
              { label: 'Administrador', value: 0 },
              { label: 'Gerente', value: 1 },
              { label: 'Suporte', value: 2 },
            ]}
            value={staffFormik.values.staff_role}
          />
          {renderFormikErrors(
            staffFormik.errors.staff_role,
            staffFormik.touched.staff_role,
            staffFormik.status?.staff_role
          )}
        </FormGroup>
        {isLoading ? (
          <B2Spinner />
        ) : (
          <SubmitButton type="submit">Salvar dados</SubmitButton>
        )}
      </FormContainer>
    </B2Modal>
  );
};

export default CreateEditStaffModal;
