import React, { useCallback, useState } from 'react';

import { useHistory } from 'react-router';
import { Formik } from 'formik';
import * as yup from 'yup';

import { useAuth } from 'contexts/Auth';
import { errors } from 'utils';
import RoutesPath from 'router/routes';

import { ForgotPasswordButton } from './styles';

import {
  CardTitle,
  Form,
  FormGroup,
  Label,
  TextInput,
  Button,
  Loading,
} from 'components/Base/AuthBase/styles';
import { FormError } from '../../components/ProjectStyles';

const Login: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingError, setLoadingError] = useState('');

  const history = useHistory();

  const { login } = useAuth();

  const onLoginFormSubmit = useCallback(
    async ({ email, password }) => {
      if (login) {
        setIsLoading(true);
        const response = await login(email, password);
        setIsLoading(false);

        if (response.error) {
          setLoadingError(
            'Não foi possivel fazer o login com essas credenciais'
          );
          return;
        }
      }
    },
    [login]
  );

  return (
    <>
      <CardTitle>Bem vindo</CardTitle>
      <Formik
        initialValues={{ email: '', password: '' }}
        onSubmit={onLoginFormSubmit}
        validationSchema={yup.object({
          email: yup.string().email(errors.email).required(errors.required),
          password: yup.string().required(errors.required),
        })}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <Label htmlFor="email">E-mail</Label>
              <TextInput
                type="email"
                placeholder="Digite aqui"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <FormError>{formik.errors.email}</FormError>
              ) : null}
            </FormGroup>
            <FormGroup>
              <Label>Senha</Label>
              <TextInput
                type="password"
                placeholder="Digite aqui"
                name="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              {formik.touched.password && formik.errors.password ? (
                <FormError>{formik.errors.password}</FormError>
              ) : null}
            </FormGroup>

            {isLoading ? (
              <Loading />
            ) : (
              <>
                <Button variant="primary" type="submit">
                  Entrar
                </Button>
                {loadingError && <FormError>{loadingError}</FormError>}
              </>
            )}
          </Form>
        )}
      </Formik>
      <ForgotPasswordButton
        variant="none"
        onClick={() => history.push(RoutesPath.auth.forgotPassword)}
      >
        Esqueceu a senha?
      </ForgotPasswordButton>
    </>
  );
};

export default Login;
