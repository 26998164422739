import styled from 'styled-components';

export const Header = styled.div`
  background-color: ${(props) => props.theme.colors.gray.one};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 16px 32px;
  font-size: 16px;
  line-height: 16px;
  display: flex;

  span {
    margin: 0 64px;
  }
`;

interface ILabel {
  active: boolean;
}

export const Label = styled.button<ILabel>`
  font-weight: ${(props) =>
    props.active ? props.theme.fontWeight.bold : props.theme.fontWeight.normal};
  color: ${(props) =>
    props.active ? props.theme.colors.gray.six : props.theme.colors.gray.three};
`;

export const Container = styled.div`
  margin-top: 40px;

  table > tr {
    border-bottom: 2px solid ${(props) => props.theme.colors.gray.one};
  }
`;
