import { endpoints, useApi } from 'contexts/Api';
import { useCallback } from 'react';
import { requestContextError } from './settings';

const useWorkers = () => {
  const { request } = useApi();

  const listActiveWorkers = useCallback(
    (page: number, search?: string) => {
      if (request) {
        return request({
          url: endpoints.workers.active,
          method: 'get',
          params: {
            page,
            search,
          },
        });
      }

      return requestContextError;
    },
    [request]
  );

  const listPendingWorkers = useCallback(
    (page: number, search?: string) => {
      if (request) {
        return request({
          url: endpoints.workers.pending,
          method: 'get',
          params: {
            page,
            search,
          },
        });
      }

      return requestContextError;
    },
    [request]
  );

  const approveDocument = useCallback(
    (id: number) => {
      if (request) {
        const data = {
          id,
          status: 1,
        };
        return request({
          url: endpoints.workers.documents,
          method: 'post',
          data,
        });
      }

      return requestContextError;
    },
    [request]
  );

  const rejectDocument = useCallback(
    (id: number, reason: string) => {
      if (request) {
        const data = {
          id,
          status: 2,
          reason,
        };
        return request({
          url: endpoints.workers.documents,
          method: 'post',
          data,
        });
      }

      return requestContextError;
    },
    [request]
  );

  const approveFaceCheck = useCallback(
    (id: number) => {
      if (request) {
        const data = {
          id,
          status: 1,
        };
        return request({
          url: endpoints.workers.facechecks,
          method: 'post',
          data,
        });
      }

      return requestContextError;
    },
    [request]
  );

  const rejectFaceCheck = useCallback(
    (id: number, reason: string) => {
      if (request) {
        const data = {
          id,
          status: 2,
          reason,
        };
        return request({
          url: endpoints.workers.facechecks,
          method: 'post',
          data,
        });
      }

      return requestContextError;
    },
    [request]
  );

  const banUser = useCallback(
    (id: number) => {
      if (request) {
        const data = {
          id,
        };
        return request({ url: endpoints.workers.ban, method: 'post', data });
      }

      return requestContextError;
    },
    [request]
  );

  const registerWorker = useCallback(
    (data: IWorkerFormik) => {
      if (request) {
        return request({
          url: endpoints.workers.worker,
          method: 'post',
          data,
        });
      }
      return requestContextError;
    },
    [request]
  );

  const updateWorker = useCallback(
    (id: number, data: Partial<IWorkerFormik>) => {
      if (request) {
        return request({
          url: `${endpoints.workers.worker}${id}/`,
          method: 'patch',
          data,
        });
      }
      return requestContextError;
    },
    [request]
  );

  return {
    listActiveWorkers,
    listPendingWorkers,
    approveDocument,
    rejectDocument,
    approveFaceCheck,
    rejectFaceCheck,
    banUser,
    registerWorker,
    updateWorker,
  };
};

export default useWorkers;
