import styled from 'styled-components';

export const DropDown = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 8px;
  background-color: #EAEAEA;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-left: auto;
  cursor: pointer;
`;

export const DropDownMenuContainer = styled.div`
  position: absolute;
  top: 120%;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 4px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.white};
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  animation: fade-in 0.5s ease-out, show-upwards 0.5s ease-out;
`;

export const DropDownButtonStyle = styled.button`
  display: flex;
  background-color: ${(props) => props.theme.colors.white};
  width: 100%;
  align-items: flex-start;
  border-radius: 5px;
  padding: 5px;
  color: ${(props) => props.theme.colors.gray.four};
  font-size: 14px;

  &:hover {
    background-color: ${(props) => props.theme.colors.gray.two};
  }
`;