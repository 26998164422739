import SplashScreen from 'components/SplashScreen';
import { useAuthentication } from 'hooks';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useApi } from './Api';
import { useToast } from './Toast';

const AuthContext = createContext<Partial<IAuthContextValues>>({});

export const AuthProvider: React.FC = ({ children }) => {
  const [userInfo, setUserInfo] = useState<IUserInfo>();
  const [myInterceptor, setMyInterceptor] = useState<number>();

  const [isLoading, setIsLoading] = useState(true);
  const { performLogin, getUserInfo, getProfile } = useAuthentication();
  const { apiInstance } = useApi();
  const { addToast } = useToast();

  const logout = useCallback(async () => {
    localStorage.removeItem('token');

    setUserInfo(undefined);
  }, []);

  const login = useCallback(
    async (email: string, password: string) => {
      if (myInterceptor !== undefined) {
        apiInstance?.interceptors.request.eject(myInterceptor);
      }

      const response = await performLogin(email, password);
      if (response.error) {
        return response;
      }

      if (response.message.user.role_info.value !== 0) {
        return {
          message: 'Não foi possível fazer login com essas credenciais',
          error: true,
          httpStatus: 401,
        };
      }

      localStorage.setItem('token', JSON.stringify(response.message));

      const newInterceptor = apiInstance?.interceptors.request.use(
        (config) => {
          if (response.message.tokens.access) {
            config.headers.authorization = `Bearer ${response.message.tokens.access}`;
          }

          return config;
        },
        async (error) => {
          const { status } = error.response;
          if (!!userInfo && status === 401) {
            addToast('Sua sessão expirou', 'error');
            logout();
          }

          return Promise.reject(error);
        }
      );

      setMyInterceptor(newInterceptor);
      setUserInfo(response.message.user);

      return response;
    },
    [
      addToast,
      apiInstance?.interceptors.request,
      logout,
      myInterceptor,
      performLogin,
      userInfo,
    ]
  );

  const updateUserInfo = useCallback(async () => {
    const response = await getUserInfo();

    if (response.error) {
      console.log('Error while trying to update user info');
      return response;
    }

    setUserInfo(response.message);
    return response;
  }, [getUserInfo]);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      const parsedToken = JSON.parse(token);

      const newInterceptor = apiInstance?.interceptors.request.use(
        (config) => {
          config.headers.authorization = `Bearer ${parsedToken.tokens.access}`;

          return config;
        },
        async (error) => {
          const { status } = error.response;
          if (status === 401) {
            addToast('Sua sessão expirou');
            logout();
          }

          return Promise.reject(error);
        }
      );

      setMyInterceptor(newInterceptor);

      const fetchUserInfo = async () => {
        const response = await getProfile();

        if (response.error) {
          addToast('Falha ao pegar as informações do usuário', 'error');
          localStorage.removeItem('token');
          setUserInfo(undefined);
          setIsLoading(false);
          return;
        }

        setUserInfo(response.message);
        setIsLoading(false);
      };

      fetchUserInfo();
      return;
    }

    setIsLoading(false);
  }, [addToast, apiInstance, getProfile, logout]);

  return (
    <AuthContext.Provider
      value={{
        userInfo,
        authorized: !!userInfo,
        updateUserInfo,
        login,
        logout,
      }}
    >
      {isLoading ? <SplashScreen /> : children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}
