import styled from 'styled-components';

import { MdAccessTime } from 'react-icons/md';
import { B2Button } from 'components/B2';

interface ISide {
  leftSide?: boolean;
}

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const OrderId = styled.span`
  color: ${(props) => props.theme.colors.main};
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  margin-right: 5px;
`;

export const DateTime = styled.span`
  color: ${(props) => props.theme.colors.gray.four};
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  margin-right: 5px;
`;

export const CloseButton = styled.div`
  background-color: ${(props) => props.theme.colors.gray.one};
  border-radius: ${(props) => props.theme.borderRadius};
  cursor: pointer;
  padding: 8px;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  max-height: 70vh;
  overflow-y: auto;
`;

export const Side = styled.div<ISide>`
  border-right: ${(props) =>
    props.leftSide ? `1px solid ${props.theme.colors.gray.one}` : 'none'};
  padding: 5px 0px;
  padding-right: ${(props) => (props.leftSide ? '32px' : '0px')};
  padding-left: ${(props) => (props.leftSide ? '0px' : '32px')};
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0px;
`;

export const Label = styled.span`
  color: ${(props) => props.theme.colors.main};
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  margin-bottom: 8px;
`;

export const Service = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 5px;
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
`;

export const Avatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 24px;
  margin-right: 10px;
`;

export const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Name = styled.span`
  color: ${(props) => props.theme.colors.gray.six};
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: ${(props) => props.theme.fontWeight.bold};
`;

export const DateTimeItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DateTimeDivider = styled.div`
  height: 20px;
  border-left: ${(props) => `1px solid ${props.theme.colors.gray.two}`};
  margin: 4px 0px 4px 22px;
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

export const CalendarContainer = styled.div`
  background-color: ${(props) => props.theme.colors.gray.one};
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 10px;
  margin-right: 10px;
`;

export const DateText = styled.span`
  text-transform: capitalize;
`;

export const Clock = styled(MdAccessTime)`
  color: ${(props) => props.theme.colors.main};
  font-size: ${(props) => props.theme.fontSize.medium};
  margin-right: 5px;
`;

export const CancelButton = styled(B2Button)`
  border-color: ${(props) => props.theme.colors.error};
  color: ${(props) => props.theme.colors.error};
`;

export const Payment = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Card = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
`;

export const Brand = styled.img`
  height: 100%;
  object-fit: contain;
  margin-right: 5px;
`;

export const PaymentText = styled.span`
  color: ${(props) => props.theme.colors.gray.six};
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: ${(props) => props.theme.fontWeight.bold};
`;

export const Question = styled.div`
  display: flex;
  flex-direction: column;
`;

export const QuestionLabel = styled.span`
  color: ${(props) => props.theme.colors.gray.six};
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  margin-bottom: 5px;
`;

export const QuestionResponse = styled.span`
  color: ${(props) => props.theme.colors.gray.six};
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  margin-left: 10px;
`;
