import { endpoints, useApi } from 'contexts/Api';
import { useCallback } from 'react';
import { requestContextError } from './settings';

const useCustomers = () => {
  const { request } = useApi();

  const listCustomers = useCallback(
    (page: number, search?: string) => {
      if (request) {
        return request({
          url: endpoints.customers,
          method: 'get',
          params: {
            page,
            search,
          },
        });
      }

      return requestContextError;
    },
    [request]
  );

  const updateCustomer = useCallback(
    (id: number, customer: Partial<ICustomerFormik>) => {
      const data = {
        email: customer.email,
        cpf: customer.cpf,
        name: customer.name,
        phone: customer.phone && {
          ddd: customer.phone.ddd,
          number: customer.phone.number,
          type: 2,
        },
        avatar: customer.avatar?.id,
        is_active: customer.is_active,
      };

      if (request) {
        return request({
          url: `${endpoints.customers}${id}/`,
          method: 'patch',
          data,
        });
      }

      return requestContextError;
    },
    [request]
  );

  const readCustomer = useCallback(
    (id: number) => {
      if (request) {
        return request({
          url: `${endpoints.customers}${id}/`,
          method: 'get',
        });
      }

      return requestContextError;
    },
    [request]
  );

  return {
    listCustomers,
    readCustomer,
    updateCustomer,
  };
};

export default useCustomers;
