import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import AuthBase from 'components/Base/AuthBase';
import RoutesPath from './routes';
import Login from 'pages/Login';
import ForgotPassword from 'pages/ForgotPassword';
import ChangePassword from 'pages/ChangePassword';

const PublicRoutes: React.FC = () => {
  return (
    <AuthBase>
      <Switch>
        <Route path={RoutesPath.auth.login} exact component={Login} />
        <Route
          path={RoutesPath.auth.forgotPassword}
          exact
          component={ForgotPassword}
        />
        <Route
          path={RoutesPath.auth.changePassword}
          exact
          component={ChangePassword}
        />
        <Route>
          <Redirect to={RoutesPath.auth.root} />
        </Route>
      </Switch>
    </AuthBase>
  );
};

export default PublicRoutes;
