import React, { useCallback, useState } from 'react';

import { useHistory } from 'react-router';
import { Formik } from 'formik';
import * as yup from 'yup';

import { useAuthentication } from 'hooks';

import {
  CardTitle,
  Form,
  FormGroup,
  Label,
  TextInput,
  Loading,
  Button,
} from 'components/Base/AuthBase/styles';
import { FormError } from '../../components/ProjectStyles';
import { useToast } from '../../contexts/Toast';
import RoutesPath from '../../router/routes';
import { BackToLoginButton } from './styles';

const ForgotPassword: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const history = useHistory();

  const { requestChangePassword } = useAuthentication();
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async ({ email }) => {
      setIsLoading(true);
      const response = await requestChangePassword(email);
      setIsLoading(false);

      if (response?.error) {
        setError(response.message.detail);
        addToast(response.message.detail, 'error');
        return;
      }

      addToast(response?.message.details, 'success');
      history.push('/login');
    },
    [addToast, history, requestChangePassword]
  );

  return (
    <>
      <CardTitle>Esqueci minha senha</CardTitle>
      <Formik
        initialValues={{ email: '' }}
        onSubmit={handleSubmit}
        validationSchema={yup.object({
          email: yup
            .string()
            .email('Email inválido')
            .required('Esse campo é obrigatório'),
        })}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <Label htmlFor="email">E-mail</Label>
              <TextInput
                type="email"
                placeholder="Digite aqui"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <FormError>{formik.errors.email}</FormError>
              ) : null}
            </FormGroup>

            {isLoading ? (
              <Loading />
            ) : (
              <>
                <Button variant="primary" type="submit">
                  Enviar
                </Button>
                {error && <FormError>{error}</FormError>}
              </>
            )}
          </Form>
        )}
      </Formik>
      <BackToLoginButton
        variant="none"
        onClick={() => history.push(RoutesPath.auth.login)}
      >
        Voltar para o login
      </BackToLoginButton>
    </>
  );
};

export default ForgotPassword;
