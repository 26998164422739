import { endpoints, useApi } from 'contexts/Api';
import { useCallback } from 'react';
import { requestContextError } from './settings';

const useService = () => {
  const { request } = useApi();

  const createService = useCallback(
    (
      idCategory: number,
      values: Pick<IService, 'icon' | 'name' | 'agenda' | 'unit'>
    ) => {
      if (request) {
        const { agenda, name, unit, icon } = values;

        const data = {
          category: idCategory,
          icon: icon?.id,
          name,
          unit,
          agenda,
        };
        return request({
          url: endpoints.service.services,
          method: 'post',
          data,
        });
      }

      return requestContextError;
    },
    [request]
  );

  const editService = useCallback(
    (
      id: number,
      values: Pick<IService, 'icon' | 'name' | 'agenda' | 'unit'>
    ) => {
      if (request) {
        const { icon, unit, name, agenda } = values;
        const data = {
          icon: icon?.id,
          name,
          unit,
          agenda,
        };
        return request({
          url: `${endpoints.service.services}${id}/`,
          method: 'patch',
          data,
        });
      }

      return requestContextError;
    },
    [request]
  );

  const listServices = useCallback(() => {
    if (request) {
      return request({ url: `${endpoints.service.services}`, method: 'get' });
    }

    return requestContextError;
  }, [request]);

  const readService = useCallback(
    (id: number) => {
      if (request) {
        return request({
          url: `${endpoints.service.services}${id}/`,
          method: 'get',
        });
      }

      return requestContextError;
    },
    [request]
  );

  const setServicePriceScheme = useCallback(
    (id: number, priceScheme: IPriceScheme[]) => {
      if (request) {
        const data = {
          price_scheme: priceScheme,
        };
        return request({
          url: `${endpoints.service.services}${id}/`,
          method: 'patch',
          data,
        });
      }

      return requestContextError;
    },
    [request]
  );

  const setServiceForm = useCallback(
    (id: number, form: IForm[]) => {
      if (request) {
        const data = {
          form,
        };
        return request({
          url: `${endpoints.service.services}${id}/`,
          method: 'patch',
          data,
        });
      }

      return requestContextError;
    },
    [request]
  );

  const getLog = useCallback(
    (orderItemId: number) => {
      if (request) {
        return request({
          url: endpoints.service.log,
          method: 'get',
          params: { order_item: orderItemId },
        });
      }

      return requestContextError;
    },
    [request]
  );

  return {
    createService,
    setServicePriceScheme,
    setServiceForm,
    listServices,
    readService,
    editService,
    getLog,
  };
};

export default useService;
