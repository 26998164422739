import { useState } from 'react';
import { MdImageNotSupported } from 'react-icons/md';

import {
  AnalyseContainer,
  ConfirmButton,
  Container,
  Header,
  ImageContainer,
  ImagesInfo,
  RejectButton,
  RejectReason,
} from './styles';

import { B2Modal } from 'components/B2';
import CloseButton from 'components/CloseButton';

interface IFaceCheckModal {
  modalIsOpen: boolean;
  closeModal: () => void;
  worker: IWorker;
  approve: (id: number) => Promise<void>;
  reject: (id: number, reason: string) => Promise<void>;
}

const FaceCheckModal: React.FC<IFaceCheckModal> = ({
  closeModal,
  modalIsOpen,
  worker,
  approve,
  reject,
}) => {
  const [rejectReason, setRejectReason] = useState('');

  return (
    <B2Modal open={modalIsOpen} modalStyle={{ padding: '0', margin: '60px' }}>
      <Container>
        <ImageContainer>
          {worker.face_check ? (
            <img
              src={
                worker.face_check?.image.image_high_url ||
                'images/blank-profile.png'
              }
              alt="Foto enviada"
            />
          ) : (
            <MdImageNotSupported />
          )}
        </ImageContainer>

        <AnalyseContainer>
          <Header>
            <img
              src={worker.avatar?.image_high_url || 'images/blank-profile.png'}
              alt="Foto de perfil"
            />
            <span>{worker.name}</span>
            <CloseButton onClick={closeModal} style={{ marginLeft: 'auto' }} />
          </Header>
          <ImagesInfo>
            <span>Foto do rosto</span>
            {/* <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
              fringilla elementum rutrum.
            </p> */}
          </ImagesInfo>
          <ConfirmButton
            onClick={() => {
              approve(worker.face_check!.id);
              closeModal();
            }}
          >
            Aceitar
          </ConfirmButton>
          <RejectReason>
            <label htmlFor="rejectReason">Motivo da rejeição</label>
            <textarea
              id="rejectReason"
              placeholder="Escreva aqui o motivo da rejeição"
              value={rejectReason}
              onChange={(e) => setRejectReason(e.target.value)}
            />
          </RejectReason>
          <RejectButton
            disabled={!rejectReason}
            onClick={() => {
              reject(worker.face_check!.id, rejectReason);
              closeModal();
            }}
          >
            Recusar
          </RejectButton>
        </AnalyseContainer>
      </Container>
    </B2Modal>
  );
};

export default FaceCheckModal;
