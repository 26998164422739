import CloseButton from 'components/CloseButton';
import { FormGroup } from 'components/ProjectStyles';
import { FormikProps } from 'formik';
import { cpfMask, onlyNumbers, phoneMask } from 'utils/masks';
import { dateJsToDateString } from 'utils/math';
import { renderFormikErrors } from 'utils/renderFormikErrors';
import { GridForm, HeaderContainer, NextButton, Title } from '../styles';

interface IPersonalData {
  closeModal: () => void;
  nextStep: () => void;
  formik: FormikProps<IWorkerFormik>;
}

const PersonalData: React.FC<IPersonalData> = ({
  closeModal,
  nextStep,
  formik,
}) => {
  const formHasAhError =
    !!formik.errors.name ||
    !!formik.errors.cpf ||
    !!formik.errors.phone ||
    !!formik.errors.email ||
    !!formik.errors.birth_date;

  const touchFields = () => {
    formik.setTouched({
      name: true,
      cpf: true,
      birth_date: true,
      phone: true,
      email: true,
    });
  };

  return (
    <>
      <HeaderContainer>
        <Title>Dados pessoais</Title>
        <CloseButton onClick={closeModal} />
      </HeaderContainer>
      <GridForm>
        <FormGroup>
          <label htmlFor="name">Nome *</label>
          <input
            type="text"
            id="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            placeholder="Insira o nome"
            onBlur={formik.handleBlur}
          />
          {renderFormikErrors(
            formik.errors.name,
            formik.touched.name,
            formik.status?.name
          )}
        </FormGroup>
        <FormGroup>
          <label htmlFor="cpf">CPF *</label>
          <input
            type="text"
            id="cpf"
            value={cpfMask(formik.values.cpf)}
            onChange={(e) => {
              e.target.value = onlyNumbers(e.target.value);
              formik.handleChange(e);
            }}
            placeholder="000.000.000-00"
            maxLength={14}
            onBlur={formik.handleBlur}
          />
          {renderFormikErrors(
            formik.errors.cpf,
            formik.touched.cpf,
            formik.status?.cpf
          )}
        </FormGroup>
        <FormGroup>
          <label htmlFor="birth_date">Data de nascimento</label>
          <input
            type="date"
            id="birth_date"
            value={formik.values.birth_date}
            onChange={formik.handleChange}
            max={dateJsToDateString(new Date())}
            onBlur={formik.handleBlur}
          />
          {renderFormikErrors(
            formik.errors.birth_date,
            formik.touched.birth_date,
            formik.status?.birth_date
          )}
        </FormGroup>
        <FormGroup>
          <label htmlFor="phone">Telefone *</label>
          <input
            type="tel"
            id="phone"
            value={
              formik.values.phone
                ? phoneMask(
                    formik.values.phone.ddd?.toString() +
                      formik.values.phone.number?.toString() || ''
                  )
                : ''
            }
            onChange={(e) => {
              const value = onlyNumbers(e.target.value);

              if (value) {
                const ddd = value.slice(0, 2);
                const number = value.slice(2);
                formik.setFieldValue('phone', {
                  type: 2,
                  ddd,
                  number,
                });
                return;
              }

              formik.setFieldValue('phone', undefined);
            }}
            maxLength={15}
            placeholder="(00) 00000-0000"
            onBlur={formik.handleBlur}
          />
          {renderFormikErrors(
            formik.errors.phone,
            formik.touched.phone,
            formik.status?.phone
          )}
        </FormGroup>
        <FormGroup>
          <label htmlFor="email">E-mail *</label>
          <input
            type="email"
            id="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            placeholder="Insira o e-mail"
            onBlur={formik.handleBlur}
          />
          {renderFormikErrors(
            formik.errors.email,
            formik.touched.email,
            formik.status?.email
          )}
        </FormGroup>
      </GridForm>
      <NextButton
        blocked={formHasAhError}
        onClick={formHasAhError ? touchFields : nextStep}
      >
        Próximo
      </NextButton>
    </>
  );
};

export default PersonalData;
