import styled from 'styled-components';

interface IDropDownHeader {
  selected: boolean;
}

export const DropDownContainer = styled.div`
  margin-top: 8px;
  width: 270px;
  position: relative;
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: ${(props) => props.theme.fontWeight.medium};
`;

export const DropDownHeader = styled.div<IDropDownHeader>`
  cursor: pointer;
  padding: 14px 16px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 12px;
  color: ${({ theme, selected }) =>
    selected ? theme.colors.black : theme.colors.placeholder};
  border: 1px solid ${({ theme }) => theme.colors.gray.one};
  font-weight: 500;
  height: 40px;

  svg {
    color: ${({ theme }) => theme.colors.gray.four};
  }
`;

export const DropDownList = styled.ul`
  padding: 10px;
  position: absolute;
  top: 48px;
  z-index: 100;
  width: 100%;
  margin: 0;
  background-color: ${({ theme }) => theme.colors.white};
  border: 2px solid #e8e8e8;
  border-radius: ${(props) => props.theme.borderRadius};
`;

export const ListItem = styled.li`
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  border-radius: ${(props) => props.theme.borderRadius};
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 16px;
  color: #4d4d4d;
  &:hover {
    background-color: #f5f5f5;
  }
`;
