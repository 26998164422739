import { useCallback, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { FormikProps } from 'formik';

import { AddButton } from 'components/ProjectStyles';
import { Title, Container, SaveButton } from './styles';

import { B2Table, B2TableDataCell, B2TableRow } from 'components/B2/B2Table';
import { brCurrencyMask } from 'utils/masks';
import PriceSchemeModal from './PriceSchemeModal';
import ModifyRowDropDown from 'components/ModifyRowDropDown';

interface IStepThree {
  formik: FormikProps<IPriceFormikProps>;
  unit?: string;
}

const StepThree: React.FC<IStepThree> = ({ formik, unit }) => {
  const [priceSchemeModalIsOpen, setPriceSchemeModalIsOpen] = useState(false);
  const [selectedPriceIndex, setSelectedPriceIndex] = useState<number>(-1);

  const addPriceScheme = useCallback(
    (newPriceScheme: IPriceScheme) => {
      formik.setFieldValue('priceScheme', [
        ...formik.values.priceScheme,
        newPriceScheme,
      ]);
    },
    [formik]
  );

  const editPriceScheme = useCallback(
    (newPriceScheme: IPriceScheme) => {
      formik.setFieldValue(
        'priceScheme',
        formik.values.priceScheme.map((item, index) => {
          if (index === selectedPriceIndex) {
            return newPriceScheme;
          } else {
            return item;
          }
        })
      );
    },
    [formik, selectedPriceIndex]
  );

  const removePriceScheme = useCallback(
    (listIndex: number) => {
      formik.setFieldValue(
        'priceScheme',
        formik.values.priceScheme.filter((_, index) => index !== listIndex)
      );
    },
    [formik]
  );

  return (
    <Container>
      <Title>Tabela de preços</Title>
      <B2Table
        headerData={[
          'Turno (horas)',
          `Unidade inicial (${unit})`,
          `Unidade final (${unit})`,
          'Preço',
        ]}
        tableData={formik.values.priceScheme}
        tableEmptyComponent={() => <p>Nenhum dado encontrado</p>}
        renderRow={(priceScheme: IPriceScheme, index) => (
          <B2TableRow key={index}>
            <B2TableDataCell>
              {`${priceScheme.time} hora${priceScheme.time > 1 ? 's' : ''}`}
            </B2TableDataCell>
            <B2TableDataCell>{priceScheme.field_start}</B2TableDataCell>
            <B2TableDataCell>{priceScheme.field_end}</B2TableDataCell>
            <B2TableDataCell>
              {brCurrencyMask(priceScheme.price)}
            </B2TableDataCell>
            <B2TableDataCell>
              <ModifyRowDropDown
                onEdit={() => {
                  setSelectedPriceIndex(index);
                  setPriceSchemeModalIsOpen(true);
                }}
                onDelete={() => removePriceScheme(index)}
              />
            </B2TableDataCell>
          </B2TableRow>
        )}
      />
      <AddButton
        onClick={() => {
          setPriceSchemeModalIsOpen(true);
        }}
      >
        <MdAdd />
        <span>Adicionar outro campo</span>
      </AddButton>
      <SaveButton
        blocked={Object.keys(formik.errors).length !== 0}
        onClick={() => formik.handleSubmit()}
        type="button"
      >
        Salvar preços
      </SaveButton>
      {priceSchemeModalIsOpen && (
        <PriceSchemeModal
          unit={unit || ''}
          closeModal={() => {
            setPriceSchemeModalIsOpen(false);
            setSelectedPriceIndex(-1);
          }}
          addPriceScheme={addPriceScheme}
          editPriceScheme={editPriceScheme}
          selectedPriceIndex={selectedPriceIndex}
          priceScheme={formik.values.priceScheme}
        />
      )}
    </Container>
  );
};

export default StepThree;
