import { PrimaryButton } from 'components/ProjectStyles';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 2px solid ${(props) => props.theme.colors.gray.one};
`;

export const Title = styled.h2`
  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.gray.six};
`;

export const FormContainer = styled.form``;

export const SubmitButton = styled(PrimaryButton)`
  width: 100%;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  margin-top: 40px;
`;
