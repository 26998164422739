import { B2Modal } from 'components/B2';
import CloseButton from 'components/CloseButton';
import { useToast } from 'contexts/Toast';
import { useState } from 'react';
import {
  MdCheck,
  MdClose,
  MdImageNotSupported,
  MdReport,
} from 'react-icons/md';
import { cpfMask, phoneMask } from 'utils/masks';
import { getAge } from 'utils/math';
import DocumentsModal from './DocumentsModal';
import FaceCheckModal from './FaceCheckModal';
import {
  AnalysisIconContainer,
  BanButton,
  FooterButton,
  HeaderContainer,
  ImageContainer,
  ImagesContainer,
  ImagesList,
  InfoBottom,
  InfoData,
  InfoTop,
  InfoUser,
  ProfileContainer,
  Service,
  ServiceData,
  ServiceHeader,
  ServicesContainer,
  Title,
} from './styles';

interface IWorkerModal {
  modalIsOpen: boolean;
  closeModal: () => void;
  worker: IWorker;
  analyseProfile: {
    document: {
      approve: (id: number) => Promise<void>;
      reject: (id: number, reason: string) => Promise<void>;
    };
    faceCheck: {
      approve: (id: number) => Promise<void>;
      reject: (id: number, reason: string) => Promise<void>;
    };
    endAnalysis: () => void;
    banWorker: (worker: IWorker) => void;
  };
}

const WorkerModal: React.FC<IWorkerModal> = ({
  worker,
  modalIsOpen,
  closeModal,
  analyseProfile,
}) => {
  const [faceCheckModalIsOpen, setFaceCheckModalIsOpen] = useState(false);
  const [documentsModalIsOpen, setDocumentsModalIsOpen] = useState(false);
  const { addToast } = useToast();

  return (
    <B2Modal open={modalIsOpen}>
      <HeaderContainer>
        <Title>Prestador de serviço</Title>
        <CloseButton onClick={closeModal} />
      </HeaderContainer>
      <ProfileContainer>
        <InfoTop>
          <img
            src={worker?.avatar?.image_high_url || 'images/blank-profile.png'}
            alt="Foto de perfil"
          />
          <InfoUser>
            <Title>
              {worker.name}
              {worker.birth_date && (
                <p>{`(${getAge(worker.birth_date)} anos)`}</p>
              )}
            </Title>

            <BanButton onClick={() => analyseProfile.banWorker(worker)}>
              <MdReport />
              <span>Banir usuário</span>
            </BanButton>
          </InfoUser>
        </InfoTop>
        <InfoBottom>
          <InfoData>
            <p>CPF</p>
            <p>{cpfMask(worker.cpf)}</p>
          </InfoData>
          <InfoData>
            <p>Telefone</p>
            <p>
              {worker.phone
                ? phoneMask(
                    worker.phone.ddd.toString() + worker.phone.number.toString()
                  )
                : '-'}
            </p>
          </InfoData>
          <InfoData>
            <p>E-mail</p>
            <p>{worker.email}</p>
          </InfoData>
          <InfoData>
            <p>Endereço de serviço</p>
            <p>{`${worker.address?.city || ''} - ${
              worker.address?.state || ''
            }`}</p>
          </InfoData>
        </InfoBottom>
      </ProfileContainer>
      <ServicesContainer>
        <Service>
          <ServiceHeader>Serviços</ServiceHeader>
          {worker.skills?.map((skill) => (
            <ServiceData key={skill.service.id}>
              {skill.service.name}
            </ServiceData>
          )) || '-'}
        </Service>
        <Service>
          <ServiceHeader>Experiência</ServiceHeader>
          <ServiceData>
            <p>
              {worker.experience
                ? `${worker.experience} ano${worker.experience > 1 ? 's' : ''}`
                : '-'}
            </p>
          </ServiceData>
        </Service>
      </ServicesContainer>
      <ImagesContainer>
        <Title>Imagens</Title>
        <ImagesList>
          <ImageContainer
            status={worker.face_check?.status}
            onClick={() =>
              worker.face_check?.status.value === 0 &&
              setFaceCheckModalIsOpen(true)
            }
          >
            {worker.face_check ? (
              <img
                src={worker.face_check?.image.image_high_url}
                alt="Checagem da foto do rosto"
              />
            ) : (
              <MdImageNotSupported />
            )}
            {worker.face_check?.status.value === 1 && (
              <AnalysisIconContainer status={worker.face_check?.status}>
                <MdCheck />
              </AnalysisIconContainer>
            )}
            {worker.face_check?.status.value === 2 && (
              <AnalysisIconContainer status={worker.face_check?.status}>
                <MdClose />
              </AnalysisIconContainer>
            )}
          </ImageContainer>
          <ImageContainer
            status={worker.documents?.status}
            onClick={() =>
              worker.documents?.status.value === 0 &&
              setDocumentsModalIsOpen(true)
            }
          >
            {worker.documents ? (
              <img
                src={worker.documents?.primary_image.image_high_url}
                alt="Checagem da foto do documennto"
              />
            ) : (
              <MdImageNotSupported />
            )}
            {worker.documents?.status.value === 1 && (
              <AnalysisIconContainer status={worker.documents?.status}>
                <MdCheck />
              </AnalysisIconContainer>
            )}
            {worker.documents?.status.value === 2 && (
              <AnalysisIconContainer status={worker.documents?.status}>
                <MdClose />
              </AnalysisIconContainer>
            )}
          </ImageContainer>
        </ImagesList>
      </ImagesContainer>
      <FooterButton
        disabled={
          !worker.face_check ||
          worker.face_check?.status.value === 0 ||
          !worker.documents ||
          worker.documents?.status.value === 0
        }
        onClick={() => {
          analyseProfile.endAnalysis();
          closeModal();
          worker.documents?.status.value === 1 &&
            worker.face_check?.status.value === 1 &&
            addToast(
              'Enviamos um e-mail informando que já é possível começar a utilizar a plataforma.',
              'success'
            );
        }}
      >
        Finalizar análise
      </FooterButton>
      {faceCheckModalIsOpen && worker.face_check && (
        <FaceCheckModal
          approve={analyseProfile.faceCheck.approve}
          reject={analyseProfile.faceCheck.reject}
          worker={worker}
          modalIsOpen={true}
          closeModal={() => setFaceCheckModalIsOpen(false)}
        />
      )}
      {documentsModalIsOpen && worker.documents && (
        <DocumentsModal
          approve={analyseProfile.document.approve}
          reject={analyseProfile.document.reject}
          worker={worker}
          modalIsOpen={true}
          closeModal={() => setDocumentsModalIsOpen(false)}
        />
      )}
    </B2Modal>
  );
};

export default WorkerModal;
