import { DefaultTheme } from 'styled-components';

const lightTheme: DefaultTheme = {
  borderRadius: '8px',
  sidebarIconSize: '30px',
  colors: {
    placeholder: '#70757A',
    main: '#FE5C1E',
    secondary: '#FDCF00',
    info: '#2F80ED',
    success: '#27AE60',
    danger: '#E2B93B',
    error: '#EB5757',
    black: '#000000',
    white: '#ffffff',
    orange: {
      dark: '#FE5C1E',
      light: '#FE9167',
    },
    green: {
      dark: '#47B472',
      light: '#91D4AB',
    },
    yellow: {
      dark: '#F9A825',
      light: '#FEDCA4',
    },
    red: {
      dark: '#FF143B',
      light: '#FF667F',
    },
    blue: {
      dark: '#3B80FF',
      light: '#9EB0FA',
    },
    purple: {
      dark: '#9E24FF',
      light: '#BB66FF',
    },
    pink: {
      dark: '#F2358D',
      light: '#F66FAE',
    },
    gray: {
      one: '#EAEAEA',
      two: '#CDCDCD',
      three: '#B1B1B1',
      four: '#5F5F5F',
      five: '#393939',
      six: '#1B1919',
    },
    buttonText: {
      primary: '#FFFFFF',
      secondary: '#FFFFFF',
      outline: '#02274F',
      text: '#02274F',
    },
  },
  fontFamily: "'Inter', sans-serif",
  fontSize: {
    small: '16px',
    medium: '24px',
    big: '32px',
  },
  fontWeight: {
    normal: '400',
    medium: '500',
    semiBold: '600',
    bold: '700',
  },
};

export { lightTheme };
