import React, { useCallback, useEffect, useState } from 'react';
import { MdCheckBox, MdOutlineCheckBoxOutlineBlank } from 'react-icons/md';

import { B2Modal, B2Spinner } from 'components/B2';
import CloseButton from 'components/CloseButton';
import { useToast } from 'contexts/Toast';
import { useService } from 'hooks';

import {
  HeaderContainer,
  ApplyButton,
  Service,
  ServiceContainer,
  Title,
} from './styles';

interface ISelectServicesModal {
  open: boolean;
  onClose: () => void;
  services?: IService[];
  selectServices: (services: IService[]) => void;
}

const SelectServicesModal: React.FC<ISelectServicesModal> = ({
  open,
  onClose,
  services,
  selectServices,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [servicesList, setServicesList] = useState<IService[]>([]);
  const [selectedServices, setSelectedServices] = useState<IService[]>([]);

  const { listServices } = useService();
  const { addToast } = useToast();

  useEffect(() => {
    if (open) {
      setSelectedServices(services || []);
    }
  }, [open, services]);

  const getServices = useCallback(async () => {
    setIsLoading(true);
    const response = await listServices();

    if (response.error) {
      addToast('Não foi possível carregar os serviços', 'error');
    } else {
      setServicesList(response.message);
    }
    setIsLoading(false);
  }, [addToast, listServices]);

  useEffect(() => {
    getServices();
  }, [getServices]);

  return (
    <B2Modal open={open}>
      <HeaderContainer>
        <Title>Serviços</Title>
        <CloseButton onClick={onClose} />
      </HeaderContainer>
      <ServiceContainer>
        {isLoading ? (
          <B2Spinner />
        ) : (
          servicesList.map((serviceItem) => {
            const selectedService = Boolean(
              selectedServices.find((service) => service.id === serviceItem.id)
            );

            return (
              <React.Fragment key={serviceItem.id}>
                <Service
                  selected={selectedService}
                  onClick={() => {
                    selectedService
                      ? setSelectedServices((prevState) =>
                          selectedServices.filter(
                            (service) => service.id !== serviceItem.id
                          )
                        )
                      : setSelectedServices((prevState) => [
                          ...prevState,
                          serviceItem,
                        ]);
                  }}
                >
                  {selectedService ? (
                    <MdCheckBox />
                  ) : (
                    <MdOutlineCheckBoxOutlineBlank />
                  )}
                  <span>{serviceItem.name}</span>
                </Service>
              </React.Fragment>
            );
          })
        )}
      </ServiceContainer>
      <ApplyButton
        type="button"
        onClick={() => {
          selectServices(selectedServices);
          onClose();
        }}
      >
        Aplicar
      </ApplyButton>
    </B2Modal>
  );
};

export default SelectServicesModal;
