import { cepMask } from 'utils/masks';

const validateCnpjByFormationAlgorithm = (cnpj: string) => {
  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj === '') return false;

  if (cnpj.length !== 14) return false;

  if (
    cnpj === '00000000000000' ||
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999'
  ) {
    return false;
  }

  let size = cnpj.length - 2;
  let numbers = cnpj.substring(0, size);
  const digits = cnpj.substring(size);
  let sum = 0;
  let pos = size - 7;
  for (let i = size; i >= 1; i--) {
    sum += Number(numbers.charAt(size - i)) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (resultado !== Number(digits.charAt(0))) {
    return false;
  }

  size = size + 1;
  numbers = cnpj.substring(0, size);
  sum = 0;
  pos = size - 7;
  for (let i = size; i >= 1; i--) {
    sum += Number(numbers.charAt(size - i)) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (resultado !== Number(digits.charAt(1))) {
    return false;
  }

  return true;
};

export const cnpjValidator = (cnpj: string) => {
  if (cnpj !== undefined) {
    if (cnpj.length > 18) {
      return validateCnpjByFormationAlgorithm(cnpj.substring(0, 18));
    } else {
      return validateCnpjByFormationAlgorithm(cnpj);
    }
  } else {
    return false;
  }
};

const validateCpfByFormationAlgorithm = (cpf: string) => {
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf === '') return false;
  // Remove invalids CPFs
  if (
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  )
    return false;
  // Validates the first digit
  var add = 0;
  for (var i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  var rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(9))) {
    return false;
  }
  // Validates the second digit
  add = 0;
  for (var idx = 0; idx < 10; idx++)
    add += parseInt(cpf.charAt(idx)) * (11 - idx);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(10))) {
    return false;
  }
  return true;
};

export const cpfValidator = (cpf: string) => {
  cpf = cpf.replace(/[^\d]+/g, '');

  if (cpf !== undefined) {
    if (cpf.length > 14) {
      return validateCpfByFormationAlgorithm(cpf.substring(0, 14));
    } else {
      return validateCpfByFormationAlgorithm(cpf);
    }
  } else {
    return false;
  }
};

export const cepValidator = (cep: string) => {
  cep = cep.replace(/[^\d]+/g, '');

  if (cep !== undefined) {
    if (cep.length > 10) {
      return /^\d{2}\.\d{3}-\d{3}$/.test(cepMask(cep).substring(0, 10));
    } else {
      return /^\d{2}\.\d{3}-\d{3}$/.test(cepMask(cep));
    }
  } else {
    return false;
  }
};

export const stateValidator = (state: string) => {
  const stateList = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ];
  return stateList.includes(state);
};

export const timeDiffValidator = (start: string, end: string) => {
  const timeStart = start.split(':');
  const timeEnd = end.split(':');
  const startDate = new Date(
    0,
    0,
    0,
    Number(timeStart[0]),
    Number(timeStart[1]),
    0
  );
  const endDate = new Date(0, 0, 0, Number(timeEnd[0]), Number(timeEnd[1]), 0);
  const diff = endDate.getTime() - startDate.getTime();

  return diff >= 0;
};
