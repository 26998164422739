import IconDropZone from 'components/IconDropZone';
import {
  ColorPickerButton,
  Form,
  FormGroup,
  PageHeaderContainer,
  PageHeaderTitle,
  PrimaryButton,
  SelectedColor,
} from 'components/ProjectStyles';
import { useToast } from 'contexts/Toast';
import { useFormik } from 'formik';
import useCategory from 'hooks/Category';
import useOutsideClick from 'hooks/useOutsideClick';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ChromePicker } from 'react-color';
import { useHistory, useParams } from 'react-router';
import RoutesPath from 'router/routes';
import { renderFormikErrors } from 'utils/renderFormikErrors';
import * as yup from 'yup';
import { Container, Header, Label } from './styles';

interface IParams {
  idCategory: string;
}

interface ICategoryFormik {
  name: string;
  color?: string;
  icon?: IICon;
}

const CreateEditCategory = () => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const colorPickerRef = useRef(null);
  const { createCategory, editCategory, readCategory } = useCategory();
  const { addToast } = useToast();
  const history = useHistory();
  const { idCategory } = useParams<IParams>();

  const getCategory = useCallback(async () => {
    const response = await readCategory(Number(idCategory));
    if (response.error) {
      addToast('Não foi possível carregar os dados da categoria', 'error');
    } else {
      categoryFormik.setValues(response.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idCategory, readCategory]);

  useEffect(() => {
    idCategory && getCategory();
  }, [getCategory, idCategory]);

  const edit = useCallback(
    async (values: Omit<ICategory, 'services' | 'id'>) => {
      const response = await editCategory(Number(idCategory), values);
      if (response.error) {
        addToast('Não foi possível editar essa categoria', 'error');
      } else {
        history.push(RoutesPath.private.category.services.list);
        addToast('Categoria alterada com sucesso', 'success');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editCategory, history, idCategory]
  );

  const create = useCallback(
    async (values: Omit<ICategory, 'services' | 'id'>) => {
      const response = await createCategory(values);
      if (response.error) {
        addToast('Não foi possível cadastrar essa categoria', 'error');
      } else {
        history.push(RoutesPath.private.category.services.list);
        addToast('Categoria cadastrada com sucesso', 'success');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createCategory, history]
  );

  const categoryFormik = useFormik<ICategoryFormik>({
    initialValues: {
      name: '',
      color: '#ffffff',
      icon: undefined,
    },
    onSubmit: idCategory ? edit : create,
    validationSchema: yup.object({
      name: yup.string().required('Esse campo é obrigatório'),
      icon: yup.object().nullable().required('Você precisa escolher um ícone'),
    }),
  });

  useOutsideClick(
    colorPickerRef,
    () => displayColorPicker && setDisplayColorPicker(false)
  );

  return (
    <>
      <Header>
        <Label
          onClick={() =>
            history.push(RoutesPath.private.category.services.list)
          }
          active={false}
        >
          Serviços
        </Label>
        <span>{'>'}</span>
        <Label active={true}>
          {idCategory ? 'Edição' : 'Cadastro'} de categoria
        </Label>
      </Header>
      <Container>
        <PageHeaderContainer>
          <PageHeaderTitle>
            {idCategory ? 'Atualizar' : 'Nova'} categoria
          </PageHeaderTitle>
        </PageHeaderContainer>
        <Form>
          <FormGroup>
            <label htmlFor="name">Nome da categoria</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Insira o nome"
              onChange={categoryFormik.handleChange}
              value={categoryFormik.values.name}
            />
            {renderFormikErrors(
              categoryFormik.errors.name,
              categoryFormik.touched.name,
              categoryFormik.status?.name
            )}
          </FormGroup>
          <FormGroup>
            <label htmlFor="color">Cor da categoria</label>
            <ColorPickerButton
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setDisplayColorPicker(!displayColorPicker);
              }}
            >
              <SelectedColor color={categoryFormik.values.color || '#FFF'} />
              <span>Selecione</span>
            </ColorPickerButton>
            {displayColorPicker && (
              <div ref={colorPickerRef} style={{ width: 'fit-content' }}>
                <ChromePicker
                  color={categoryFormik.values.color}
                  onChange={(value) =>
                    categoryFormik.setFieldValue('color', value.hex)
                  }
                />
              </div>
            )}
          </FormGroup>
          <FormGroup>
            <label htmlFor="icon">Adicione um ícone para a categoria</label>
            <IconDropZone
              icon={categoryFormik.values.icon}
              onFileUploaded={(file) =>
                categoryFormik.setFieldValue('icon', file)
              }
              iconBackground={categoryFormik.values.color}
            />
            {renderFormikErrors(
              categoryFormik.errors.icon,
              categoryFormik.touched.icon,
              categoryFormik.status?.icon
            )}
            <p>O ícone deve estar no formato .svg</p>
          </FormGroup>
          <PrimaryButton
            onClick={(e) => {
              e.preventDefault();
              categoryFormik.handleSubmit();
            }}
            type="button"
            style={{
              width: '272px',
              justifyContent: 'center',
              position: 'fixed',
              bottom: '12px',
              right: '48px',
            }}
          >
            Salvar dados
          </PrimaryButton>
        </Form>
      </Container>
    </>
  );
};

export default CreateEditCategory;
