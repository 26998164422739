export enum StaffRole {
  Admin = 0,
  Manager = 1,
  Support = 2,
}

export enum Role {
  Staff = 0,
  Customer = 1,
  Worker = 2,
}

export enum AnalyzedImage {
  Pending = 0,
  Approved = 1,
  Rejected = 2,
}

export enum Order {
  Canceled = -1,
  AwaitingPreAuthorization = 0,
  SearchingWorker = 1,
  AwaitingWorkerConfirmation = 2,
  AwaitingPayment = 3,
  PaymentConfirmed = 4,
  ServiceStarted = 5,
  ServiceCompleted = 6,
  WorkerPaid = 7,
}
