import { useState } from 'react';
import { MdEdit } from 'react-icons/md';

import { AddressData, Container } from './styles';

import AddressModal from '../AddressModal';
import { addressMask } from 'utils/masks';

interface IAddressCard {
  address: IAddress;
  setAddress: (updatedAddress: IAddress) => void;
}

const AddressCard: React.FC<IAddressCard> = ({ address, setAddress }) => {
  const [addressModalIsOpen, setAddressModalIsOpen] = useState(false);

  return (
    <>
      <Container>
        <AddressData>
          <span>{address.type}</span>
          <span>{addressMask(address)}</span>
        </AddressData>
        <MdEdit onClick={() => setAddressModalIsOpen(true)} />
      </Container>
      {addressModalIsOpen && (
        <AddressModal
          address={address}
          closeModal={() => setAddressModalIsOpen(false)}
          setAddress={setAddress}
        />
      )}
    </>
  );
};

export default AddressCard;
