import { B2TableDataCell, B2TableRow } from 'components/B2/B2Table';
import CategoryIcon from 'components/CategoryIcon';
import ModifyRowDropDown from 'components/ModifyRowDropDown';
import ServicesRows from 'components/ServicesRows';
import { useState } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import { useHistory } from 'react-router';
import { ExpandButton, TableDataCellContainer } from './styles';

interface ICategoryTableRow {
  category: ICategory;
}

const CategoryTableRow: React.FC<ICategoryTableRow> = ({ category }) => {
  const [expand, setExpand] = useState(false);
  const totalServices = category.services.length;
  const history = useHistory();

  return (
    <>
      <B2TableRow key={category.id}>
        <B2TableDataCell>
          <CategoryIcon color={category.color} imageUrl={category.icon?.file} />
        </B2TableDataCell>
        <B2TableDataCell>{category.name}</B2TableDataCell>
        <B2TableDataCell>
          {totalServices
            ? `${totalServices} serviço${totalServices > 1 ? 's' : ''}`
            : '-'}
        </B2TableDataCell>
        <B2TableDataCell>
          <TableDataCellContainer style={{ display: 'flex' }}>
            {
              <ExpandButton expand={expand} onClick={() => setExpand(!expand)}>
                <p>{expand ? 'Ocultar serviços' : 'Ver serviços'}</p>
                <MdArrowDropDown size={28} />
              </ExpandButton>
            }
            <ModifyRowDropDown
              onDelete={() => {}}
              onEdit={() => history.push(`/categorias/editar/${category.id}`)}
            />
          </TableDataCellContainer>
        </B2TableDataCell>
      </B2TableRow>
      {expand && <ServicesRows category={category} />}
      <tr>
        <td colSpan={4}>
          <hr />
        </td>
      </tr>
    </>
  );
};

export default CategoryTableRow;
