import React from 'react';

import { LandingPage, LandingPageIcon } from './styles';

const SplashScreen: React.FC = () => {
  return (
    <LandingPage>
      <LandingPageIcon src="/images/main-logo.svg" alt="Logo" />
    </LandingPage>
  );
};

export default SplashScreen;
