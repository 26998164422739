import { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useHistory, useParams } from 'react-router';
import {
  MdKeyboardBackspace,
  MdVideocam,
  MdDeleteOutline,
  MdAdd,
} from 'react-icons/md';
import * as yup from 'yup';

import { useToast } from 'contexts/Toast';
import useTrainings from 'hooks/Trainings';
import RoutesPath from 'router/routes';
import { renderFormikErrors } from '../../utils/renderFormikErrors';
import { getYoutubeId } from 'utils/masks';
import SelectServicesModal from './SelectServicesModal';

import { FormGroup, PrimaryButton } from 'components/ProjectStyles';
import {
  AddServiceContainer,
  EmbedContainer,
  Form,
  FormButtonGroup,
  FormContainer,
  Header,
  Label,
  SelectAllServicesContainer,
  SelectedService,
  SelectedServicesContainer,
} from './styles';

interface IParams {
  idTraining: string;
}

const CreateEditTraining = () => {
  const [openServicesModal, setOpenServicesModal] = useState(false);

  const { createTraining, editTraining, readTraining } = useTrainings();
  const { addToast } = useToast();
  const history = useHistory();
  const { idTraining } = useParams<IParams>();

  const create = useCallback(
    async (data: ITrainingFormik) => {
      const response = await createTraining(data);
      if (response.error) {
        addToast('Não foi possível cadastrar esse treinamento', 'error');
      } else {
        history.push(RoutesPath.private.training.list);
        addToast('Treinamento cadastrada com sucesso', 'success');
      }
    },
    [addToast, createTraining, history]
  );

  const edit = useCallback(
    async (data: ITrainingFormik) => {
      const response = await editTraining(Number(idTraining), data);
      if (response.error) {
        addToast('Não foi possível editar esse treinamento', 'error');
      } else {
        history.push(RoutesPath.private.training.list);
        addToast('Treinamento alterado com sucesso', 'success');
      }
    },
    [addToast, editTraining, history, idTraining]
  );

  // services === [] ----> no service selected
  // services === undefined ------> all services selected
  const trainingFormik = useFormik<ITrainingFormik>({
    initialValues: {
      name: '',
      description: '',
      embed: '',
      services: [],
    },
    onSubmit: idTraining ? edit : create,
    validationSchema: yup.object({
      name: yup.string().required('Esse campo é obrigatório'),
      description: yup.string().required('Esse campo é obrigatório'),
      embed: yup.string().required('Esse campo é obrigatório'),
      services: yup.array().min(1, 'Selecione pelo menos 1 serviço'),
    }),
  });

  const selectServices = (services: IService[]) => {
    trainingFormik.setFieldValue('services', services);
  };

  const removeService = (id: number) => {
    trainingFormik.setFieldValue(
      'services',
      trainingFormik.values.services?.filter((service) => service.id !== id) ||
        []
    );
  };

  const getTraining = useCallback(async () => {
    const response = await readTraining(Number(idTraining));
    if (response.error) {
      addToast('Não foi possível carregar os dados do treinamento', 'error');
    } else {
      const { description, embed, name, services } =
        response.message as ITraining;
      trainingFormik.setValues({
        name,
        embed,
        description,
        services: services.length === 0 ? undefined : services,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToast, idTraining, readTraining]);

  useEffect(() => {
    idTraining && getTraining();
  }, [getTraining, idTraining]);

  return (
    <>
      <Header>
        <Label
          onClick={() => history.push(RoutesPath.private.training.list)}
          active={false}
        >
          <MdKeyboardBackspace />
          Voltar
        </Label>
      </Header>
      <FormContainer>
        <Form onSubmit={trainingFormik.handleSubmit}>
          <FormGroup maxWidth>
            <label htmlFor="name">Título do treinamento</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Insira o nome"
              onChange={trainingFormik.handleChange}
              value={trainingFormik.values.name}
            />
            {renderFormikErrors(
              trainingFormik.errors.name,
              trainingFormik.touched.name,
              trainingFormik.status?.name
            )}
          </FormGroup>
          <FormGroup maxWidth>
            <label htmlFor="description">Descrição do treinamento</label>
            <textarea
              id="description"
              name="description"
              placeholder="Insira a descrição"
              onChange={trainingFormik.handleChange}
              value={trainingFormik.values.description}
            />
            {renderFormikErrors(
              trainingFormik.errors.description,
              trainingFormik.touched.description,
              trainingFormik.status?.description
            )}
          </FormGroup>
          <FormGroup maxWidth>
            <label htmlFor="embed">Informe o link</label>
            <input
              type="text"
              id="embed"
              name="embed"
              placeholder="Insira o embed"
              onChange={trainingFormik.handleChange}
              value={trainingFormik.values.embed}
            />
            {renderFormikErrors(
              trainingFormik.errors.embed,
              trainingFormik.touched.embed,
              trainingFormik.status?.embed
            )}
          </FormGroup>
          <FormGroup>
            <label>Informe o tipo de serviço</label>
            <SelectAllServicesContainer>
              <input
                type="checkbox"
                name="Selecionar todos"
                id="allServices"
                onChange={() =>
                  trainingFormik.setFieldValue(
                    'services',
                    trainingFormik.values.services ? undefined : []
                  )
                }
                checked={!trainingFormik.values.services}
              />
              <label htmlFor="allServices">Selecionar todos</label>
            </SelectAllServicesContainer>
          </FormGroup>
          <SelectedServicesContainer>
            {trainingFormik.values.services?.map((service) => (
              <SelectedService key={service.id}>
                <span>{service.name}</span>
                <MdDeleteOutline onClick={() => removeService(service.id)} />
              </SelectedService>
            ))}
          </SelectedServicesContainer>
          <AddServiceContainer
            disabled={!trainingFormik.values.services}
            onClick={() =>
              !!trainingFormik.values.services && setOpenServicesModal(true)
            }
          >
            <span>Adicionar Serviços</span>
            <MdAdd />
          </AddServiceContainer>
          {renderFormikErrors(
            trainingFormik.errors.services,
            trainingFormik.touched.services,
            trainingFormik.status?.services
          )}
          <FormButtonGroup>
            <PrimaryButton type="submit">
              <MdVideocam />
              <span>Salvar treinamento</span>
            </PrimaryButton>
          </FormButtonGroup>
        </Form>
        <EmbedContainer>
          <p>Prévia</p>
          <iframe
            key={trainingFormik.values.embed}
            width="268"
            height="140"
            src={`https://www.youtube.com/embed/${getYoutubeId(
              trainingFormik.values.embed
            )}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </EmbedContainer>
      </FormContainer>
      <SelectServicesModal
        open={openServicesModal}
        onClose={() => setOpenServicesModal(false)}
        services={trainingFormik.values.services}
        selectServices={selectServices}
      />
    </>
  );
};

export default CreateEditTraining;
