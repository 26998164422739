import React, { useMemo } from 'react';

import { useTheme } from 'styled-components';
import {
  MdGroups,
  MdEmojiPeople,
  MdReceipt,
  MdAttachMoney,
} from 'react-icons/md';

import { useMetrics } from 'hooks';
import { PageHeaderContainer, PageHeaderTitle } from 'components/ProjectStyles';

import { Content } from './styles';
import Metric from './Metric';

const Home = () => {
  const theme = useTheme();

  const {
    fetchAvg,
    fetchCustomers,
    fetchCustomersMonthly,
    fetchGmv,
    fetchOrders,
    fetchWorker,
  } = useMetrics();

  const metrics: Array<IMetric> = useMemo(() => {
    return [
      {
        title: 'N° de Prestadores cadastrados',
        icon: MdGroups,
        iconColor: theme.colors.yellow.dark,
        measure: 'pessoas',
        request: fetchWorker,
        key: 'workers_count',
      },
      {
        title: 'N° de Clientes cadastrados',
        icon: MdEmojiPeople,
        iconColor: theme.colors.red.dark,
        measure: 'pessoas',
        request: fetchCustomers,
        key: 'customers_count',
      },
      {
        title: 'N° de Pedidos',
        icon: MdReceipt,
        iconColor: theme.colors.blue.dark,
        measure: 'pedidos',
        request: fetchOrders,
        key: 'services_ordered_count',
      },
      {
        title: 'Novos usuários este mês',
        icon: MdEmojiPeople,
        iconColor: theme.colors.red.dark,
        measure: 'pessoas',
        request: fetchCustomersMonthly,
        key: 'customers_this_month_count',
      },
      {
        title: 'Ticket médio',
        description:
          'O ticket médio é um indicador de desempenho que fornece o valor médio de vendas por cliente.',
        icon: MdAttachMoney,
        iconColor: theme.colors.green.dark,
        measure: 'reais',
        request: fetchAvg,
        key: 'avg_ticket',
      },
      {
        title: 'Total transacionado',
        icon: MdAttachMoney,
        iconColor: theme.colors.red.dark,
        request: fetchGmv,
        measure: 'reais',
        key: 'gmv',
      },
    ];
  }, [
    fetchAvg,
    fetchCustomers,
    fetchCustomersMonthly,
    fetchGmv,
    fetchOrders,
    fetchWorker,
    theme,
  ]);

  return (
    <>
      <PageHeaderContainer>
        <PageHeaderTitle>Métricas</PageHeaderTitle>
      </PageHeaderContainer>
      <Content>
        {metrics.map((metric: IMetric) => (
          <Metric key={metric.title} metric={metric} />
        ))}
      </Content>
    </>
  );
};

export default Home;
