import styled from 'styled-components';
import { B2Button } from '../../components/B2';

export const BackToLoginButton = styled(B2Button)`
  width: 100%;
  margin-top: 25px;
  height: 45px;
  font-family: ${(props) => props.theme.fontFamily};
  color: ${(props) => props.theme.colors.gray.four};
`;
