import { B2Spinner, B2ToggleSwitch } from 'components/B2';
import { B2Table, B2TableDataCell, B2TableRow } from 'components/B2/B2Table';
import { AvatarContainer, PrimaryButton } from 'components/ProjectStyles';
import Search from 'components/Search';
import { useToast } from 'contexts/Toast';
import { useDebounce } from 'hooks';
import useWorkers from 'hooks/Workers';
import { useCallback, useEffect, useState } from 'react';
import { MdOutlineAdd } from 'react-icons/md';
import CreateEditWorkerModal from './CreateEditWorkerModal';
import { HeaderContainer, EditIcon } from './styles';

const List = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [workersPaginated, setWorkersPaginated] =
    useState<IPaginated<IWorker>>();
  const [workerModalIsOpen, setWorkerModalIsOpen] = useState(false);
  const [selectedWorker, setSelectedWorker] = useState<IWorker>();

  const [query, setQuery] = useState({
    currentPage: 1,
    search: '',
  });

  const debouncedSearch = useDebounce((search: string) => {
    setQuery({
      currentPage: 1,
      search,
    });
  }, 1000);

  const { addToast } = useToast();
  const { listActiveWorkers, updateWorker } = useWorkers();

  const getWorkers = useCallback(async () => {
    setIsLoading(true);
    const response = await listActiveWorkers(query.currentPage, query.search);

    if (response.error) {
      addToast('Não foi possível carregar a lista de prestadores', 'error');
    } else {
      setWorkersPaginated(response.message);
    }
    setIsLoading(false);
  }, [addToast, listActiveWorkers, query]);

  useEffect(() => {
    getWorkers();
  }, [getWorkers]);

  const toggleActivateWorker = useCallback(
    async (id: number, activate: boolean) => {
      const response = await updateWorker(id, { is_active: activate });

      if (response.error) {
        addToast(
          `Não foi possível ${
            activate ? 'ativar' : 'desativar'
          } esse prestador`,
          'error'
        );
      } else {
        setWorkersPaginated((prevState) => ({
          ...prevState,
          results: prevState?.results?.map((worker) =>
            worker.id === id ? { ...worker, is_active: activate } : worker
          ),
        }));
      }
    },
    [addToast, updateWorker]
  );

  return (
    <>
      <HeaderContainer>
        <Search
          onChange={(e) => {
            debouncedSearch(e.target.value);
          }}
        />
        <PrimaryButton
          onClick={() => {
            setSelectedWorker(undefined);
            setWorkerModalIsOpen(true);
          }}
        >
          <MdOutlineAdd />
          <span>Novo prestador</span>
        </PrimaryButton>
      </HeaderContainer>
      {isLoading ? (
        <B2Spinner />
      ) : (
        <B2Table
          headerData={['Prestador', 'Serviços', 'Ativo']}
          tableData={workersPaginated?.results || []}
          tableEmptyComponent={() => <p>Nenhum dado encontrado</p>}
          paginator
          renderRow={(worker: IWorker) => (
            <B2TableRow key={worker.id}>
              <B2TableDataCell>
                <AvatarContainer>
                  <img
                    src={
                      worker.avatar?.image_high_url ||
                      'images/blank-profile.png'
                    }
                    alt="Avatar"
                  />
                  <span>{worker.name}</span>
                </AvatarContainer>
              </B2TableDataCell>
              <B2TableDataCell>
                {worker.skills?.map((skill) => skill.service.name).join('; ') ||
                  '-'}
              </B2TableDataCell>
              <B2TableDataCell>
                <B2ToggleSwitch
                  defaultChecked={worker.is_active}
                  id={worker.id.toString()}
                  handleToggle={() => {
                    toggleActivateWorker(worker.id, !worker.is_active);
                  }}
                />
              </B2TableDataCell>
              <B2TableDataCell>
                <EditIcon
                  onClick={() => {
                    setSelectedWorker(worker);
                    setWorkerModalIsOpen(true);
                  }}
                />
              </B2TableDataCell>
            </B2TableRow>
          )}
          total={workersPaginated?.count}
          changePage={(newPage) => setQuery({ ...query, currentPage: newPage })}
          currentPage={query.currentPage}
        />
      )}
      {workerModalIsOpen && (
        <CreateEditWorkerModal
          modalIsOpen={workerModalIsOpen}
          closeModal={() => setWorkerModalIsOpen(false)}
          selectedWorker={selectedWorker}
          onSubmit={getWorkers}
        />
      )}
    </>
  );
};

export default List;
