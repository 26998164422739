import React, { useState, useCallback, useEffect } from 'react';

import { Formik } from 'formik';
import * as yup from 'yup';
import { useHistory } from 'react-router';

import {
  TextInput,
  Button,
  Form,
  Label,
  CardTitle,
  Loading,
} from 'components/Base/AuthBase/styles';

import { useAuthentication, useQuery } from 'hooks';
import { errors } from 'utils';
import { FormError } from '../../components/ProjectStyles';
import { useToast } from '../../contexts/Toast';

const ChangePassword: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const query = useQuery();
  const history = useHistory();
  const { changePassword } = useAuthentication();
  const { addToast } = useToast();

  useEffect(() => {
    if (!query.get('token')) {
      history.push('/login');
    }
  }, [history, query]);

  const handleSubmit = useCallback(
    async ({ password }) => {
      setIsLoading(true);
      const response = await changePassword(password, query.get('token'));
      setIsLoading(false);

      if (response?.error) {
        addToast(
          'Não foi possivel alterar sua senha, tente solicitar novamente',
          'error'
        );
        history.replace('/forgotPassword');
        return;
      }

      addToast('Senha atualizada com sucesso', 'success');
      history.replace('/login');
    },
    [addToast, changePassword, history, query]
  );

  return (
    <>
      <CardTitle>Atualizar senha</CardTitle>
      <Formik
        initialValues={{ password: '', confirmPassword: '' }}
        onSubmit={handleSubmit}
        validationSchema={yup.object({
          password: yup.string().required(errors.required),
          confirmPassword: yup
            .string()
            .oneOf([yup.ref('password')], errors.passwordConfirm)
            .required(errors.required),
        })}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <Label>Senha</Label>
            <TextInput
              type="password"
              placeholder="Insira sua senha"
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            {formik.touched.password && formik.errors.password ? (
              <FormError>{formik.errors.password}</FormError>
            ) : null}

            <Label htmlFor="confirmPassword">Confirmar senha</Label>
            <TextInput
              type="password"
              placeholder="Insira sua senha novamente"
              name="confirmPassword"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <FormError>{formik.errors.confirmPassword}</FormError>
            ) : null}

            {isLoading ? (
              <Loading />
            ) : (
              <>
                <Button variant="primary" type="submit">
                  Atualizar
                </Button>
              </>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ChangePassword;
