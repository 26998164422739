import React, { useState } from 'react';
import { DropDown, DropDownButtonStyle, DropDownMenuContainer } from './styles';
import { FiMoreHorizontal } from 'react-icons/fi';
import { lightTheme } from 'themes/default';
import useOutsideClick from 'hooks/useOutsideClick';

interface IModifyRowDropDown {
  onEdit: () => void;
  onDelete: () => void;
  style?: React.CSSProperties;
}

const ModifyRowDropDown: React.FC<IModifyRowDropDown> = ({
  onEdit,
  onDelete,
  style,
}) => {
  const [openDropDown, setOpenDropDown] = useState(false);
  const dropDownRef = React.useRef<any>(null);

  useOutsideClick(dropDownRef, () => setOpenDropDown(false));

  return (
    <DropDown
      ref={dropDownRef}
      onClick={() => setOpenDropDown((state) => !state)}
      style={style}
    >
      <FiMoreHorizontal
        style={{ fontSize: 25, color: lightTheme.colors.black }}
      />
      {openDropDown && (
        <DropDownMenuContainer>
          <DropDownButtonStyle onClick={onEdit}>Editar</DropDownButtonStyle>

          <DropDownButtonStyle onClick={onDelete}>Deletar</DropDownButtonStyle>
        </DropDownMenuContainer>
      )}
    </DropDown>
  );
};

export default ModifyRowDropDown;
