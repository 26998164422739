export const getAge = (birthDateString?: string) => {
  if (!birthDateString) return 0;
  const today = new Date();
  const birthDate = dateStringToDateJs(birthDateString);
  let age = today.getFullYear() - birthDate.getFullYear();

  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const dateStringToDateJs = (date: string) => {
  const [year, month, day] = date.split('-');
  return new Date(Number(year), Number(month) - 1, Number(day));
};

export const dateJsToDateString = (date: Date) => {
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const timeDiff = (start: string, end: string) => {
  const timeStart = start.split(':');
  const timeEnd = end.split(':');
  const startDate = new Date(
    0,
    0,
    0,
    Number(timeStart[0]),
    Number(timeStart[1]),
    0
  );
  const endDate = new Date(0, 0, 0, Number(timeEnd[0]), Number(timeEnd[1]), 0);
  const diff = endDate.getTime() - startDate.getTime();
  const hours = Math.floor(diff / 1000 / 60 / 60);
  const timeDiff = diff - hours * 1000 * 60 * 60;
  const minutes = Math.floor(timeDiff / 1000 / 60);

  return `${hours ? `${hours}h ` : ''}${minutes ? `${minutes}min` : ''}`;
};

export const clamp = (value: number, min: number, max: number) => {
  return Math.min(Math.max(value, min), max);
};
