import React, { useState } from 'react';

import { CancelOrderButton, NotCancelOrderButton, Modal } from './styles';

import { useToast } from '../../contexts/Toast';
import { useOrders } from 'hooks';
import { B2Spinner } from '../B2';
import sleep from '../../utils/sleep';

interface ICancelOrderModalProps extends ISimpleModal {
  order: IOrder;
  onSubmit: () => void;
}

const CancelOrderModal: React.FC<ICancelOrderModalProps> = ({
  open,
  onClose,
  onSubmit,
  order,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);
  const { addToast } = useToast();
  const { cancelOrderItem } = useOrders();

  const onCancelOrder = async () => {
    setIsLoading(true);
    const response = await cancelOrderItem(order.id, order.items[0].id);
    setIsLoading(false);

    if (response.error) {
      addToast('Erro ao cancelar o pedido', 'error');
      return;
    }

    setShowDeleted(true);
    await sleep(1500);
    setShowDeleted(false);
    onClose();
    onSubmit();
  };

  return (
    <Modal open={open} success={showDeleted}>
      {isLoading ? (
        <B2Spinner style={{ margin: '0 auto' }} />
      ) : showDeleted ? (
        <span>Pedido cancelado com sucesso</span>
      ) : (
        <>
          <p>Tem certeza que deseja cancelar o pedido {order.items[0].id}?</p>
          <CancelOrderButton variant="primary" onClick={onCancelOrder}>
            Cancelar
          </CancelOrderButton>
          <NotCancelOrderButton variant="primary" onClick={onClose}>
            Não, cliquei errado
          </NotCancelOrderButton>
        </>
      )}
    </Modal>
  );
};

export default CancelOrderModal;
