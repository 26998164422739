import CategoryIcon from 'components/CategoryIcon';
import { useToast } from 'contexts/Toast';
import { useAssets } from 'hooks';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { DropZoneContainer } from './styles';

interface IDropzone {
  onFileUploaded: (uploadedImage: IICon) => void;
  icon?: IICon;
  style?: React.CSSProperties;
  iconBackground?: string;
}

const IconDropZone: React.FC<IDropzone> = ({
  onFileUploaded,
  style,
  iconBackground,
  icon,
}) => {
  const { addToast } = useToast();
  const { sendIcon } = useAssets();

  const uploadIcon = useCallback(
    async (icon: File) => {
      const response = await sendIcon(icon);

      if (response.error) {
        const msg = response.message.details
          ? response.message.details
          : response.message.image_high_url;
        addToast(msg, 'error');
        return;
      } else {
        onFileUploaded({
          id: response.message.id,
          file: response.message.file,
        });
      }
    },
    [onFileUploaded, sendIcon, addToast]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (!file) {
        addToast('Formato de arquivo não válido', 'error');
        return;
      }
      uploadIcon(file);
    },
    [uploadIcon, addToast]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.svg',
  });

  return (
    <DropZoneContainer {...getRootProps()} style={style}>
      <input {...getInputProps()} accept=".svg" />
      <CategoryIcon color={iconBackground} imageUrl={icon?.file} />
      <p>
        Arraste a imagem para cá ou <span>procure</span>
      </p>
    </DropZoneContainer>
  );
};

export default IconDropZone;
