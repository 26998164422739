import { endpoints, useApi } from 'contexts/Api';
import { useCallback } from 'react';
import { requestContextError } from './settings';

const useOrders = () => {
  const { request } = useApi();

  const listOrders = useCallback(
    ({
      currentPage: page,
      search,
      service: orders__service,
      status: orders__status,
    }: IOrdersQuery) => {
      if (request) {
        return request({
          url: endpoints.order.getAll,
          method: 'get',
          params: {
            page,
            search,
            orders__service,
            orders__status,
          },
        });
      }

      return requestContextError;
    },
    [request]
  );

  const cancelOrderItem = useCallback(
    (orderId: number, orderItemId: number) => {
      const url = endpoints.order.cancel
        .replace(':order_id', orderId.toString())
        .replace(':order_item_id', orderItemId.toString());

      if (request) {
        return request({
          method: 'post',
          url,
        });
      }

      return requestContextError;
    },
    [request]
  );

  return {
    listOrders,
    cancelOrderItem,
  };
};

export default useOrders;
