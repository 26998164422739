import { B2Modal } from 'components/B2';
import { Form, PrimaryButton } from 'components/ProjectStyles';
import styled from 'styled-components';

export const Modal = styled(B2Modal)`
  padding: 0px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid ${(props) => props.theme.colors.gray.one};
  padding: 12px 32px;
`;

export const Title = styled.h2`
  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.gray.six};
`;

export const GridForm = styled(Form)`
  display: grid;
  grid-column-gap: 36px;
  grid-template-columns: 1fr 1fr;
  max-height: 70vh;
  overflow: auto;
  padding: 0px 32px 64px 32px;

  input {
    min-width: 460px;
  }
`;

export const SaveButton = styled(PrimaryButton)`
  width: calc(100% - 84px);
  justify-content: center;
  margin: 16px auto 40px;
  font-weight: bold;
  height: 52px;
`;
