import React, { ComponentType, useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import CreateEditCategory from 'pages/CreateEditCategory';
import CreateEditService from 'pages/CreateEditService';
import CustomerProfile from 'pages/CustomerProfile';
import Customers from 'pages/Customers';
import Home from 'pages/Home';
import ServiceCategories from 'pages/ServiceCategories';
import Staffs from 'pages/Staffs';
import Workers from 'pages/Workers';
import CreateEditTraining from '../pages/CreateEditTraining';
import Orders from '../pages/Orders';
import Training from '../pages/Training';

import PrivateBase from 'components/Base/PrivateBase';
import RoutesPath from './routes';
import { StaffRole } from 'utils/enums';
import StaffRoleGuard from 'components/StaffRoleGuard';
import getHomePathByStaffRole from 'utils/getHomePathByStaffRole';

const PrivateRoutes: React.FC = () => {
  const generateRouteProps = (
    path: string,
    Component: ComponentType,
    allowedStaffs: StaffRole[]
  ) => ({
    path,
    key: path,
    exact: true,
    render: () => (
      <StaffRoleGuard
        allowedStaffs={allowedStaffs}
        fallback={(staffRole) => (
          <Redirect to={getHomePathByStaffRole(staffRole)} />
        )}
      >
        <Component />
      </StaffRoleGuard>
    ),
  });

  const routes = useMemo(
    () => [
      generateRouteProps(RoutesPath.private.home, Home, [
        StaffRole.Admin,
        StaffRole.Manager,
        StaffRole.Support,
      ]),
      generateRouteProps(RoutesPath.private.providers, Workers, [
        StaffRole.Admin,
        StaffRole.Manager,
      ]),
      generateRouteProps(RoutesPath.private.customers.list, Customers, [
        StaffRole.Admin,
        StaffRole.Manager,
      ]),
      generateRouteProps(
        RoutesPath.private.customers.details,
        CustomerProfile,
        [StaffRole.Admin, StaffRole.Manager]
      ),
      generateRouteProps(RoutesPath.private.orders.list, Orders, [
        StaffRole.Admin,
        StaffRole.Manager,
        StaffRole.Support,
      ]),
      generateRouteProps(
        RoutesPath.private.category.services.list,
        ServiceCategories,
        [StaffRole.Admin]
      ),
      generateRouteProps(
        RoutesPath.private.category.create,
        CreateEditCategory,
        [StaffRole.Admin]
      ),
      generateRouteProps(RoutesPath.private.category.edit, CreateEditCategory, [
        StaffRole.Admin,
      ]),
      generateRouteProps(
        RoutesPath.private.category.services.create,
        CreateEditService,
        [StaffRole.Admin]
      ),
      generateRouteProps(
        RoutesPath.private.category.services.edit,
        CreateEditService,
        [StaffRole.Admin]
      ),
      generateRouteProps(RoutesPath.private.staffs, Staffs, [StaffRole.Admin]),
      generateRouteProps(RoutesPath.private.training.list, Training, [
        StaffRole.Admin,
        StaffRole.Manager,
      ]),
      generateRouteProps(
        RoutesPath.private.training.create,
        CreateEditTraining,
        [StaffRole.Admin, StaffRole.Manager]
      ),
      generateRouteProps(RoutesPath.private.training.edit, CreateEditTraining, [
        StaffRole.Admin,
        StaffRole.Manager,
      ]),
    ],
    []
  );

  return (
    <PrivateBase>
      <Switch>
        {routes.map((routerProps) => (
          <Route {...routerProps} />
        ))}
        <Route>
          <Redirect to={RoutesPath.private.home} />
        </Route>
      </Switch>
    </PrivateBase>
  );
};

export default PrivateRoutes;
