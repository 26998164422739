import { B2Spinner } from 'components/B2';
import { B2Table, B2TableDataCell, B2TableRow } from 'components/B2/B2Table';
import DeleteModal from 'components/DeleteModal';
import ModifyRowDropDown from 'components/ModifyRowDropDown';
import {
  AvatarContainer,
  PageHeaderContainer,
  PageHeaderTitle,
  PrimaryButton,
} from 'components/ProjectStyles';
import Search from 'components/Search';
import { useToast } from 'contexts/Toast';
import { useDebounce } from 'hooks';
import useStaffs from 'hooks/Staffs';
import { useCallback, useEffect, useState } from 'react';
import { MdOutlineAdd } from 'react-icons/md';
import CreateEditStaffModal from './CreateEditStaffModal';

const Staffs = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [systemUsersPaginated, setSystemUsersPaginated] =
    useState<IPaginated<IStaff>>();
  const [selectedSystemUser, setSelectedSystemUser] = useState<IStaff>();
  const { listStaffs, deleteStaff } = useStaffs();
  const { addToast } = useToast();
  const [userModalIsOpen, setUserModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const [query, setQuery] = useState({
    currentPage: 1,
    search: '',
  });

  const ondeleteStaff = async () => {
    const response = await deleteStaff(selectedSystemUser!.id);
    if (response.error) {
      return addToast('Não foi possível deletar esse usuário', 'error');
    }
    addToast('Usuário deletado com sucesso', 'success');
    getSystemUsers();
  };

  const getSystemUsers = useCallback(async () => {
    setIsLoading(true);
    const response = await listStaffs(query.currentPage, query.search);
    setIsLoading(false);
    if (response.error) {
      return addToast('Não foi possível carregar a lista de usuários', 'error');
    }
    setSystemUsersPaginated(response.message);
  }, [addToast, listStaffs, query]);

  useEffect(() => {
    getSystemUsers();
  }, [getSystemUsers]);

  const debouncedSearch = useDebounce((search: string) => {
    setQuery({
      currentPage: 1,
      search,
    });
  }, 1000);

  return (
    <>
      <PageHeaderContainer>
        <PageHeaderTitle>Funcionários</PageHeaderTitle>
        <PrimaryButton onClick={() => setUserModalIsOpen(true)}>
          <MdOutlineAdd />
          <span>Adicionar funcionário</span>
        </PrimaryButton>
      </PageHeaderContainer>
      <Search
        onChange={(e) => {
          debouncedSearch(e.target.value);
        }}
      />
      {isLoading ? (
        <B2Spinner />
      ) : (
        <B2Table
          headerData={['Usuário', 'Nível de acesso']}
          tableData={systemUsersPaginated?.results || []}
          tableEmptyComponent={() => <p>Nenhum dado encontrado</p>}
          paginator
          renderRow={(user: IStaff) => (
            <B2TableRow key={user.id}>
              <B2TableDataCell>
                <AvatarContainer>
                  <span>{user.name || 'Sem nome'}</span>
                </AvatarContainer>
              </B2TableDataCell>
              <B2TableDataCell>{user.staff_role_info.label}</B2TableDataCell>
              <B2TableDataCell>
                <ModifyRowDropDown
                  onDelete={() => {
                    setSelectedSystemUser(user);
                    setDeleteModalIsOpen(true);
                  }}
                  onEdit={() => {
                    setSelectedSystemUser(user);
                    setUserModalIsOpen(true);
                  }}
                />
              </B2TableDataCell>
            </B2TableRow>
          )}
          total={systemUsersPaginated?.count}
          changePage={(newPage) =>
            setQuery((state) => ({
              ...state,
              currentPage: newPage,
            }))
          }
          currentPage={query.currentPage}
        />
      )}
      <CreateEditStaffModal
        closeModal={() => {
          setUserModalIsOpen(false);
          setSelectedSystemUser(undefined);
        }}
        modalIsOpen={userModalIsOpen}
        getSystemUsers={getSystemUsers}
        selectedUser={selectedSystemUser}
      />
      <DeleteModal
        open={deleteModalIsOpen}
        onHide={() => setDeleteModalIsOpen(false)}
        title={`Deseja excluir o usuário ${
          selectedSystemUser?.name || 'Sem nome'
        } ?`}
        onDelete={ondeleteStaff}
      />
    </>
  );
};

export default Staffs;
