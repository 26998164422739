import CloseButton from 'components/CloseButton';
import ImageDropzone from 'components/ImageDropzone';
import { Form, FormGroup } from 'components/ProjectStyles';
import { useToast } from 'contexts/Toast';
import { useFormik } from 'formik';
import { useCustomers } from 'hooks';
import { cpfMask, onlyNumbers, phoneMask } from 'utils/masks';
import { renderFormikErrors } from 'utils/renderFormikErrors';
import { cpfValidator } from 'utils/validators';
import * as yup from 'yup';
import {
  HeaderContainer,
  ImageDropzoneContainer,
  ImageFormGroup,
  Modal,
  ProfileDataContainer,
  SaveButton,
  Title,
} from './styles';

interface ICustomerModal {
  modalIsOpen: boolean;
  closeModal: () => void;
  customer: ICustomer;
  setCustomer: (customer: ICustomer) => void;
}

const CustomerModal: React.FC<ICustomerModal> = ({
  closeModal,
  customer,
  modalIsOpen,
  setCustomer,
}) => {
  const { updateCustomer } = useCustomers();
  const { addToast } = useToast();

  const customerFormik = useFormik({
    initialValues: {
      name: customer.name,
      phone: customer.phone,
      email: customer.email,
      avatar: customer.avatar,
      cpf: customer.cpf,
    },
    validationSchema: yup.object({
      name: yup.string().required('Esse campo é obrigatório'),
      cpf: yup
        .string()
        .required('Esse campo é obrigatório')
        .length(11, 'CPF deve ter 11 dígitos')
        .test(
          'is-cpf-valid',
          'Campo inválido',
          (cpf) => !!cpf && cpfValidator(cpf)
        ),
      phone: yup.object().nullable().required('Número é obrigatório'),
      email: yup
        .string()
        .email('Email inválido')
        .required('Esse campo é obrigatório'),
    }),
    onSubmit: async (values) => {
      const response = await updateCustomer(customer.id, values);
      if (response.error) {
        addToast('Não foi possível atualizar esse cliente', 'error');
        customerFormik.setStatus(response.message);
      } else {
        addToast('Cliente atualizado com sucesso', 'success');
        setCustomer(response.message);
        closeModal();
      }
    },
  });

  return (
    <Modal open={modalIsOpen}>
      <HeaderContainer>
        <Title>Conta do usuário</Title>
        <CloseButton onClick={closeModal} />
      </HeaderContainer>
      <ProfileDataContainer>
        <Form>
          <ImageFormGroup>
            <img
              src={
                customerFormik.values.avatar?.image_high_url ||
                '/images/blank-profile.png'
              }
              alt="Avatar"
            />
            <ImageDropzoneContainer>
              <span>Foto do perfil</span>
              <ImageDropzone
                onFileUploaded={(file) => {
                  customerFormik.setFieldValue('avatar', file);
                }}
              />
            </ImageDropzoneContainer>
          </ImageFormGroup>
          <FormGroup maxWidth>
            <label htmlFor="name">Nome</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Insira o nome"
              value={customerFormik.values.name}
              onChange={customerFormik.handleChange}
            />
            {renderFormikErrors(
              customerFormik.errors.name,
              customerFormik.touched.name,
              customerFormik.status?.name
            )}
          </FormGroup>
          <FormGroup maxWidth>
            <label htmlFor="email">E-mail</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Insira o E-mail"
              value={customerFormik.values.email}
              onChange={customerFormik.handleChange}
            />
            {renderFormikErrors(
              customerFormik.errors.email,
              customerFormik.touched.email,
              customerFormik.status?.email
            )}
          </FormGroup>
          <FormGroup maxWidth>
            <label htmlFor="cpf">CPF</label>
            <input
              type="text"
              value={cpfMask(customerFormik.values.cpf || '')}
              onChange={(e) => {
                e.target.value = onlyNumbers(e.target.value);
                customerFormik.handleChange(e);
              }}
              name="cpf"
              id="cpf"
              maxLength={14}
              placeholder="000.000.000-00"
            />
            {renderFormikErrors(
              customerFormik.errors.cpf,
              customerFormik.touched.cpf,
              customerFormik.status?.cpf
            )}
          </FormGroup>
          <FormGroup maxWidth>
            <label htmlFor="phone">Telefone</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder="(00) 00000-0000"
              maxLength={15}
              value={
                customerFormik.values.phone
                  ? phoneMask(
                      customerFormik.values.phone.ddd.toString() +
                        customerFormik.values.phone.number.toString()
                    )
                  : ''
              }
              onChange={(e) => {
                const pureNumber = onlyNumbers(e.target.value);
                const ddd = pureNumber.slice(0, 2);
                const number = pureNumber.slice(2);
                customerFormik.setFieldValue('phone', {
                  ...customerFormik.values.phone,
                  ddd,
                  number,
                });
              }}
            />
            {renderFormikErrors(
              customerFormik.errors.phone,
              customerFormik.touched.phone,
              customerFormik.status?.phone
            )}
          </FormGroup>
        </Form>
      </ProfileDataContainer>
      <SaveButton
        blocked={
          !!customerFormik.errors.name ||
          !!customerFormik.errors.phone ||
          !!customerFormik.errors.email ||
          !!customerFormik.errors.cpf
        }
        onClick={() => customerFormik.handleSubmit()}
        type="button"
      >
        Salvar alterações
      </SaveButton>
    </Modal>
  );
};

export default CustomerModal;
