import React, {
  createContext,
  useContext,
  useMemo,
  useCallback,
  useEffect,
} from 'react';

import axios from 'axios';

const ApiContext = createContext<Partial<IApiContextValues>>({});

const ApiProvider: React.FC = ({ children }) => {
  const apiInstance = useMemo(
    () =>
      axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
          moises: process.env.REACT_APP_API_KEY,
        },
      }),
    []
  );

  useEffect(() => {
    apiInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const { status, data } = error?.response;

        if (status === 403 && data.updated === 'False') {
          alert(
            'Essa versão do resolvetudo não é mais suportada. Por favor, clique no botão abaixo para atualizar a página.'
          );
          window.location.reload();
          return;
        }

        return Promise.reject(error);
      }
    );
  }, [apiInstance]);

  const request = useCallback(
    async (args: IRequestConfig): Promise<IRequestResponse> => {
      try {
        let response = await apiInstance(args);
        return {
          error: false,
          message: response.data,
          httpStatus: response.status,
        };
      } catch (error: any) {
        console.log(error.message);
        return {
          error: true,
          httpStatus: error.response?.status,
          message: error.response?.data,
        };
      }
    },
    [apiInstance]
  );

  return (
    <ApiContext.Provider value={{ apiInstance, request }}>
      {children}
    </ApiContext.Provider>
  );
};

const useApi = () => {
  const context = useContext(ApiContext);
  return context;
};

export { ApiProvider, useApi };
