import useTrainings from 'hooks/Trainings';
import React, { useState } from 'react';
import { useToast } from '../../contexts/Toast';
import sleep from '../../utils/sleep';
import { B2Spinner } from '../B2';
import {
  CancelDeleteTrainingButton,
  DeleteTrainingButton,
  Modal,
} from './styles';

interface IDeleteTrainingModalProps extends ISimpleModal {
  training: ITraining;
  onSubmit: () => void;
}

const DeleteTrainingModal: React.FC<IDeleteTrainingModalProps> = ({
  training,
  open,
  onClose,
  onSubmit,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);
  const { addToast } = useToast();
  const { deleteTraining } = useTrainings();

  const onDeleteTraining = async () => {
    setIsLoading(true);
    const response = await deleteTraining(training.id);
    setIsLoading(false);

    if (response.error) {
      addToast('Erro ao deletar o treinamento', 'error');
      return;
    }

    setShowDeleted(true);
    await sleep(1500);
    setShowDeleted(false);
    onClose();
    onSubmit();
  };

  return (
    <Modal open={open} success={showDeleted}>
      {isLoading ? (
        <B2Spinner style={{ margin: '0 auto' }} />
      ) : showDeleted ? (
        <span>Treinamento deletado com sucesso</span>
      ) : (
        <>
          <p>
            Tem certeza que deseja excluir o treinamento
            {training ? ` "${training.name}"` : ''}?
          </p>
          <DeleteTrainingButton variant="primary" onClick={onDeleteTraining}>
            Excluir
          </DeleteTrainingButton>
          <CancelDeleteTrainingButton variant="primary" onClick={onClose}>
            Não, cliquei errado
          </CancelDeleteTrainingButton>
        </>
      )}
    </Modal>
  );
};

export default DeleteTrainingModal;
