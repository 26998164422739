import styled from 'styled-components';

export const Container = styled.div``;

export const FiltersContainer = styled.div`
  display: flex;

  & > div:not(:last-child) {
    margin-right: 10px;
  }
`;
