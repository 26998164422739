import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 30px;
  border-collapse: collapse;
  margin-top: 32px;
  font-size: 12px;

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
  }
`;

export const TableHeader = styled.thead`
  /* font-size: 12px; */
`;

export const TableRow = styled.tr`
  tbody & {
    padding: 40px 0px;
  }
`;

export const TableH = styled.th`
  color: ${(props) => props.theme.colors.gray.three};
  & > div {
    display: flex;
    align-items: center;
  }
`;

export const TableBody = styled.tbody`
  animation: show-rightwards 0.5s ease-out, fade-in 0.5s ease-out;
`;

export const TableDataCell = styled.td`
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: ${(props) => props.theme.colors.gray.six};
  padding: 16px 0px;
  max-width: 400px;
`;
