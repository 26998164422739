import { useCallback } from 'react';
import { useApi, endpoints } from 'contexts/Api';
import { requestContextError } from './settings';

const useAssets = () => {
  const { request } = useApi();

  const sendImage = useCallback(
    async (imageFile: File) => {
      var data = new FormData();
      data.set('image_high_url', imageFile);

      if (request) {
        return await request({
          method: 'post',
          url: endpoints.assets.sendImage,
          data,
          headers: {
            Accept: '*/*',
            'Content-Type': 'multipart/form-data',
          },
        });
      }

      return requestContextError;
    },
    [request]
  );

  const sendIcon = useCallback(
    async (iconFile: File) => {
      var data = new FormData();
      data.set('file', iconFile);

      if (request) {
        return await request({
          method: 'post',
          url: endpoints.assets.sendIcon,
          data,
          headers: {
            Accept: '*/*',
            'Content-Type': 'multipart/form-data',
          },
        });
      }

      return requestContextError;
    },
    [request]
  );

  return { sendImage, sendIcon };
};

export default useAssets;
