import { B2Spinner } from 'components/B2';
import { useToast } from 'contexts/Toast';
import { useCustomers } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { MdKeyboardBackspace, MdStar } from 'react-icons/md';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { phoneMask } from 'utils/masks';
import AddressCard from './AddressCard';
import CustomerModal from './CustomerModal';
import {
  CustomerAddressContainer,
  CustomerButtons,
  CustomerContainer,
  CustomerContent,
  CustomerData,
  CustomerDataFooter,
  CustomerDataHeader,
  CustomerImage,
  CustomerInfo,
  EditInfoButton,
  Header,
} from './styles';

interface IParams {
  idCustomer: string;
}

const CustomerProfile = () => {
  const history = useHistory();
  const { idCustomer } = useParams<IParams>();
  const [customerModalIsOpen, setCustomerModalIsOpen] = useState(false);
  const [customer, setCustomer] = useState<ICustomer>();
  const { readCustomer } = useCustomers();
  const { addToast } = useToast();

  const populateCustomer = useCallback(async () => {
    const response = await readCustomer(Number(idCustomer));
    if (response.error) {
      addToast(
        'Não foi possível carregar as informações desse cliente',
        'error'
      );
    } else {
      setCustomer(response.message);
    }
  }, [addToast, idCustomer, readCustomer]);

  useEffect(() => {
    idCustomer && populateCustomer();
  }, [idCustomer, populateCustomer]);

  return (
    <>
      <Header>
        <MdKeyboardBackspace />
        <span
          onClick={() => {
            history.push('/clientes');
          }}
        >
          Clientes
        </span>
        &nbsp;/&nbsp;<span>Detalhes</span>
      </Header>
      {customer ? (
        <>
          <CustomerContainer>
            <CustomerInfo>
              <CustomerContent>
                <CustomerImage
                  src={
                    customer.avatar?.image_high_url ||
                    '/images/blank-profile.png'
                  }
                  alt="Avatar"
                />
                <CustomerData>
                  <CustomerDataHeader>
                    <span>{customer.name}</span>
                    <span>5.0</span>
                    <MdStar />
                  </CustomerDataHeader>
                  <CustomerDataFooter>
                    <span>
                      <span>E-mail:</span> {customer.email}
                    </span>
                    <span>
                      <span>Telefone:</span>{' '}
                      {customer.phone
                        ? phoneMask(
                            customer.phone.ddd.toString() +
                              customer.phone.number.toString()
                          )
                        : '-'}
                    </span>
                  </CustomerDataFooter>
                </CustomerData>
              </CustomerContent>

              <CustomerButtons>
                <EditInfoButton onClick={() => setCustomerModalIsOpen(true)}>
                  Editar informações
                </EditInfoButton>
              </CustomerButtons>
            </CustomerInfo>
            <CustomerAddressContainer>
              <h1>Endereços</h1>
              {customer && customer.addresses ? (
                <AddressCard
                  address={customer.addresses[0]}
                  setAddress={(updatedAddress) => {
                    setCustomer({
                      ...customer,
                      addresses: customer.addresses?.map((address) => {
                        if (address.id !== updatedAddress.id) {
                          return address;
                        } else {
                          return updatedAddress;
                        }
                      }),
                    });
                  }}
                />
              ) : (
                <span>Não há nenhum endereço cadastrado</span>
              )}
            </CustomerAddressContainer>
          </CustomerContainer>
        </>
      ) : (
        <B2Spinner />
      )}

      {customerModalIsOpen && customer && (
        <CustomerModal
          closeModal={() => {
            setCustomerModalIsOpen(false);
          }}
          customer={customer}
          setCustomer={setCustomer}
          modalIsOpen={customerModalIsOpen}
        />
      )}
    </>
  );
};

export default CustomerProfile;
