import React, { useMemo, useRef, useState } from 'react';
import {
  MdArrowDropDown,
  MdAssignment,
  MdEmojiPeople,
  MdManageAccounts,
  MdPieChart,
  MdReceipt,
  MdRoomService,
  MdVideocam,
} from 'react-icons/md';

import {
  Container,
  Header,
  Logo,
  LogoutButton,
  MainContent,
  MainPanel,
  Menu,
  MenuButton,
  PrivateBaseContent,
  Sidebar,
  UserAvatar,
  Username,
} from './styles';

import SideBarItem from 'components/SideBarItem';
import { B2Menu, B2MenuItem } from 'components/B2';
import { useAuth } from 'contexts/Auth';
import RoutesPath from 'router/routes';
import { StaffRole } from 'utils/enums';
import StaffRoleGuard from 'components/StaffRoleGuard';

const PrivateBase: React.FC = ({ children }) => {
  const [showMenu, setShowMenu] = useState(false);
  const menu = useRef<HTMLDivElement | null>(null);
  const { logout } = useAuth();

  const items = useMemo(
    () => [
      {
        name: 'Métricas',
        icon: MdPieChart,
        route: RoutesPath.private.home,
        allowedStaffs: [StaffRole.Admin, StaffRole.Manager, StaffRole.Support],
      },
      {
        name: 'Prestadores de serviço',
        icon: MdAssignment,
        route: RoutesPath.private.providers,
        allowedStaffs: [StaffRole.Admin, StaffRole.Manager],
      },
      {
        name: 'Clientes',
        icon: MdEmojiPeople,
        route: RoutesPath.private.customers.list,
        allowedStaffs: [StaffRole.Admin, StaffRole.Manager],
      },
      {
        name: 'Pedidos',
        icon: MdReceipt,
        route: RoutesPath.private.orders.list,
        allowedStaffs: [StaffRole.Admin, StaffRole.Manager, StaffRole.Support],
      },
      {
        name: 'Categoria de serviços',
        icon: MdRoomService,
        route: RoutesPath.private.category.services.list,
        allowedStaffs: [StaffRole.Admin],
      },
      {
        name: 'Funcionários',
        icon: MdManageAccounts,
        route: RoutesPath.private.staffs,
        allowedStaffs: [StaffRole.Admin],
      },
      {
        name: 'Treinamentos',
        icon: MdVideocam,
        route: RoutesPath.private.training.list,
        allowedStaffs: [StaffRole.Admin, StaffRole.Manager],
      },
    ],
    []
  );

  return (
    <Container>
      <MainContent>
        <Sidebar>
          <Logo src="/images/main-logo.svg" alt="Logo" />
          {items.map(({ name, route, icon, allowedStaffs }, index) => (
            <StaffRoleGuard key={index} allowedStaffs={allowedStaffs}>
              <SideBarItem
                index={index}
                icon={icon}
                name={name}
                route={route}
              />
            </StaffRoleGuard>
          ))}
        </Sidebar>
        <MainPanel>
          <Header>
            <Menu ref={menu} onClick={() => setShowMenu(true)}>
              <Username>ResolveTudo</Username>
              <UserAvatar
                src="https://d1bvpoagx8hqbg.cloudfront.net/259/5557dc8dc59d815eacda0306558d848f.jpg"
                alt="User"
              />
              <MenuButton>
                <MdArrowDropDown size={28} />
              </MenuButton>
            </Menu>
          </Header>
          <PrivateBaseContent>{children}</PrivateBaseContent>
        </MainPanel>
      </MainContent>
      <B2Menu
        show={showMenu}
        onHide={() => setShowMenu(false)}
        anchor={menu.current}
      >
        <B2MenuItem onClick={() => logout && logout()}>
          <LogoutButton>Logout</LogoutButton>
        </B2MenuItem>
      </B2Menu>
    </Container>
  );
};

export default PrivateBase;
