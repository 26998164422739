import styled, { css } from 'styled-components';
import { B2Button, B2Modal } from '../B2';

interface ISuccess {
  success?: boolean;
}

export const Container = styled.div``;

export const Modal = styled(B2Modal)<ISuccess>`
  display: flex;
  flex-direction: column;
  padding-right: 60px;
  padding-left: 60px;
  width: 350px;
  text-align: center;
  ${(props) =>
    props.success &&
    css`
      background-color: ${props.theme.colors.error};
      color: ${props.theme.colors.white};
      animation: fade-out 0.5s ease-out 1s forwards,
        hide-downwards 0.5s ease-out 1s forwards;
    `}

  & > p {
    font-weight: bold;
    font-size: ${(props) => props.theme.fontSize.small};
  }

  & > *:not(:last-child) {
    margin-bottom: 24px;
  }

  & > span {
    font-weight: ${(props) => props.theme.fontWeight.bold};
    font-size: ${(props) => props.theme.fontSize.medium};
    margin: 40px 0;
  }
`;

export const CancelOrderButton = styled(B2Button)`
  background-color: ${(props) => props.theme.colors.error};
  font-weight: 500;
`;

export const NotCancelOrderButton = styled(B2Button)`
  background-color: ${(props) => props.theme.colors.gray.one};
  color: ${(props) => props.theme.colors.gray.six};
  font-weight: 500;
`;
