import { useCallback, useEffect, useState } from 'react';

import {
  PageHeaderContainer,
  PageHeaderTitle,
  SecondaryButton,
} from 'components/ProjectStyles';
import { B2Spinner } from 'components/B2';
import { B2Table, B2TableDataCell, B2TableRow } from 'components/B2/B2Table';
import Search from 'components/Search';
import { useToast } from 'contexts/Toast';
import { useDebounce } from 'hooks';

import { Container, FiltersContainer } from './styles';
import ServicesFilterSelect from '../../components/ServicesFilterSelect';
import useOrders from 'hooks/Orders';
import { dateAndHourMask } from 'utils/masks';
import FiltersSelect from 'components/FilterSelect';
import DetailModal from './DetailModal';

const Orders = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState<IOrder>();
  const [ordersPaginated, setOrdersPaginated] = useState<IPaginated<IOrder>>();

  const { addToast } = useToast();
  const { listOrders } = useOrders();

  const [query, setQuery] = useState<IOrdersQuery>({
    currentPage: 1,
    search: '',
  });

  const debouncedSearch = useDebounce((search: string) => {
    setQuery({
      ...query,
      currentPage: 1,
      search,
    });
  }, 1000);

  const getOrders = useCallback(async () => {
    setIsLoading(true);
    const response = await listOrders(query);

    if (response.error) {
      addToast('Não foi possível carregar a lista de pedidos', 'error');
    } else {
      setOrdersPaginated(response.message);
    }
    setIsLoading(false);
  }, [addToast, listOrders, query]);

  useEffect(() => {
    getOrders();
  }, [getOrders]);

  return (
    <Container>
      <DetailModal
        isOpen={!!selectedOrder}
        onClose={() => setSelectedOrder(undefined)}
        order={selectedOrder}
        refreshList={() => getOrders()}
      />
      <PageHeaderContainer>
        <PageHeaderTitle>Pedidos</PageHeaderTitle>
      </PageHeaderContainer>
      <FiltersContainer>
        <Search
          onChange={(e) => {
            debouncedSearch(e.target.value);
          }}
          placeholder="Pesquisar por código"
        />
        <ServicesFilterSelect<IOption>
          onChange={(service) => {
            setQuery({
              ...query,
              service: service?.value,
              currentPage: 1,
            });
          }}
        />
        <FiltersSelect<IOption>
          placeholder="Selecione um status"
          onChange={(status) => {
            setQuery({
              ...query,
              status: status?.value,
              currentPage: 1,
            });
          }}
          options={[
            {
              value: -1,
              label: 'Cancelado',
            },
            {
              value: 0,
              label: 'Aguardando pré autorização',
            },
            {
              value: 1,
              label: 'Buscando prestador',
            },
            {
              value: 2,
              label: 'Aguardando confirmação do prestador',
            },
            {
              value: 3,
              label: 'Aguardando pagamento',
            },
            {
              value: 4,
              label: 'Pagamento confirmado',
            },
            {
              value: 5,
              label: 'Trabalho iniciado',
            },
            {
              value: 6,
              label: 'Trabalho finalizado',
            },
            {
              value: 7,
              label: 'Prestador pago',
            },
          ]}
          isClearable={true}
        />
      </FiltersContainer>
      {isLoading ? (
        <B2Spinner />
      ) : (
        <B2Table
          headerData={['Código', 'Status', 'Serviço', 'Região', 'Data e hora']}
          tableData={ordersPaginated?.results || []}
          tableEmptyComponent={() => <p>Nenhum dado encontrado</p>}
          paginator
          total={ordersPaginated?.count}
          currentPage={query.currentPage}
          changePage={(newPage) => setQuery({ ...query, currentPage: newPage })}
          renderRow={(order: IOrder) => (
            <B2TableRow key={order.id}>
              <B2TableDataCell>{`#${order.id}`}</B2TableDataCell>
              <B2TableDataCell>
                {order.items[0].status_info.label}
              </B2TableDataCell>
              <B2TableDataCell>{order.items[0].service.name}</B2TableDataCell>
              <B2TableDataCell>{`${order.items[0].address.city} - ${order.items[0].address.state}`}</B2TableDataCell>
              <B2TableDataCell>
                {dateAndHourMask(new Date(order.items[0].initial_time))}
              </B2TableDataCell>
              <B2TableDataCell
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingRight: '0px',
                }}
              >
                <SecondaryButton onClick={() => setSelectedOrder(order)}>
                  Visualizar pedido
                </SecondaryButton>
              </B2TableDataCell>
            </B2TableRow>
          )}
        />
      )}
    </Container>
  );
};

export default Orders;
