import React from 'react';

import { Container, VerticalBar } from './styles';

const B2Spinner: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
  return (
    <Container {...props}>
      <VerticalBar />
      <VerticalBar />
      <VerticalBar />
    </Container>
  );
};

export default B2Spinner;
