import styled from 'styled-components';

export const Header = styled.div`
  background-color: ${(props) => props.theme.colors.gray.one};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 16px 32px;
  color: ${(props) => props.theme.colors.gray.four};
  line-height: 16px;
  display: flex;

  span {
    font-weight: ${(props) => props.theme.fontWeight.bold};
    margin: 0 8px;
  }
`;

interface ILabel {
  active: boolean;
}

export const Label = styled.button<ILabel>`
  font-weight: ${(props) =>
    props.active ? props.theme.fontWeight.bold : props.theme.fontWeight.normal};
`;

export const Container = styled.div`
  margin-top: 40px;
`;
