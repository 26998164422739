import { MdTagFaces } from 'react-icons/md';
import { Container } from './styles';

interface ICategoryIcon {
  color?: string;
  imageUrl?: string;
}

const CategoryIcon: React.FC<ICategoryIcon> = ({ color, imageUrl }) => (
  <Container color={color || '#FFF'}>
    {imageUrl ? (
      <img src={imageUrl} alt="Ícone" />
    ) : (
      <MdTagFaces color="#000" size="30" />
    )}
  </Container>
);

export default CategoryIcon;
