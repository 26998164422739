import styled from "styled-components";

export const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 2px solid ${(props) => props.theme.colors.gray.one};

`;

export const Title = styled.h2`
    font-size: 16px;
    font-weight: bold;
    color: ${(props) => props.theme.colors.gray.six};
    `;
    
export const FooterButton = styled.button`
    background-color: ${(props) => props.disabled ? props.theme.colors.gray.three : props.theme.colors.orange.dark};
    border-radius: 6px;
    height: 44px;
    color: white;
    font-weight: bold;
    font-size: 16px;
    width: 100%;
    margin-top: 30px;
    cursor: ${(props) => props.disabled ? 'not-allowed' : 'pointer'};
`;

