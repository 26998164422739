export const onlyNumbers = (str: string) => str.replace(/\D/g, '');

export const getYoutubeId = (url: string) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
};

export const dateAndHourMask = (date: Date) => {
  const months = [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
  ];
  return `${date.getDate().toString().padStart(2, '0')} ${
    months[date.getMonth()]
  } ${date.getFullYear()} - ${date
    .getHours()
    .toString()
    .padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
};

export const addressMask = (address: IAddress) =>
  `${address.street}, ${address.number}, ${address.neighborhood}, ${address.city} - ${address.state}`;

export const dateMask = (str: string) =>
  str
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\/\d{4})\d+?$/, '$1');

export const cepMask = (str: string) =>
  str
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d)/, '$1.$2')
    .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2-$3')
    .replace(/(-\d{3})\d+?$/, '$1');

export const cardNumberMask = (str: string) =>
  str
    .replace(/\D/g, '')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})(\d)/, '$1 $2');

export const cpfMask = (str: string) =>
  str
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2');

export const cnpjMask = (str: string) =>
  str
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d)/, '$1.$2')
    .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    .replace(/\.(\d{3})(\d)/, '.$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2');

export const cpfOrCnpjMask = (str: string) => {
  if (str.length <= 11) {
    return cpfMask(str);
  } else {
    return cnpjMask(str);
  }
};

export const phoneMask = (str: string) => {
  let res = str.replace(/\D/g, '').replace(/^0/, '');
  if (res.length > 11) {
    return res.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
  } else if (res.length > 7) {
    return res.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2-$3');
  } else if (res.length > 2) {
    return res.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
  } else if (res.trim() !== '') {
    return res.replace(/^(\d*)/, '($1');
  }
};

export const brCurrencyMask = (num: number) =>
  `R$ ${(num / 100).toFixed(2)}`.replace('.', ',');
