import { useCallback } from 'react';

import { useApi, endpoints } from 'contexts/Api';
import { requestContextError } from './settings';

const useAddress = () => {
  const { request } = useApi();

  const updateAddress = useCallback(
    (id: number, address: IAddressFormik) => {
      if (request) {
        return request({
          url: `${endpoints.address}${id}/`,
          method: 'patch',
          data: address,
        });
      }

      return requestContextError;
    },
    [request]
  );

  return {
    updateAddress,
  };
};

export default useAddress;
