import { useCallback } from 'react';

import { endpoints, useApi } from 'contexts/Api';
import { requestContextError } from './settings';

const useMetrics = () => {
  const { request } = useApi();

  const fetchWorker = useCallback(() => {
    if (request) {
      return request({
        url: endpoints.metrics.workers,
        method: 'get',
      });
    }

    return requestContextError;
  }, [request]);

  const fetchAvg = useCallback(() => {
    if (request) {
      return request({
        url: endpoints.metrics.avg,
        method: 'get',
      });
    }

    return requestContextError;
  }, [request]);

  const fetchCustomers = useCallback(() => {
    if (request) {
      return request({
        url: endpoints.metrics.customers,
        method: 'get',
      });
    }

    return requestContextError;
  }, [request]);

  const fetchGmv = useCallback(() => {
    if (request) {
      return request({
        url: endpoints.metrics.gmv,
        method: 'get',
      });
    }

    return requestContextError;
  }, [request]);

  const fetchCustomersMonthly = useCallback(() => {
    if (request) {
      return request({
        url: endpoints.metrics.customersMonthly,
        method: 'get',
      });
    }

    return requestContextError;
  }, [request]);

  const fetchOrders = useCallback(() => {
    if (request) {
      return request({
        url: endpoints.metrics.orders,
        method: 'get',
      });
    }

    return requestContextError;
  }, [request]);

  return {
    fetchWorker,
    fetchAvg,
    fetchCustomers,
    fetchGmv,
    fetchCustomersMonthly,
    fetchOrders,
  };
};

export default useMetrics;
