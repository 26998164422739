import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams, Prompt } from 'react-router';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { PageHeaderContainer, PageHeaderTitle } from 'components/ProjectStyles';
import {
  Container,
  Header,
  Label,
  Step,
  StepsContainer,
  WarningCircle,
} from './styles';

import RoutesPath from 'router/routes';
import { useToast } from 'contexts/Toast';
import useCategory from 'hooks/Category';
import useService from 'hooks/Service';
import { timeDiffValidator } from 'utils/validators';

import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';

interface IParams {
  idCategory: string;
  idService?: string;
}

const CreateEditService = () => {
  const {
    readService,
    setServiceForm,
    setServicePriceScheme,
    createService,
    editService,
  } = useService();
  const { readCategory } = useCategory();
  const { addToast } = useToast();
  const history = useHistory();
  const { idService, idCategory } = useParams<IParams>();
  const [currentStep, setCurrentStep] = useState(0);
  const [savedService, setSavedService] = useState<IService | null>(null);
  const [iconBackground, setIconBackground] = useState('#fff');

  const goToNextStep = useCallback(() => {
    setCurrentStep((current) => current + 1);
  }, []);

  const getService = useCallback(async () => {
    const response = await readService(Number(idService));

    if (response.error) {
      addToast('Não foi possível carregar o serviço');
    } else {
      setSavedService(response.message);
    }
  }, [addToast, idService, readService]);

  useEffect(() => {
    idService && getService();
  }, [getService, idService]);

  const getBackgroundIcon = useCallback(async () => {
    const response = await readCategory(Number(idCategory));

    if (response.error) {
      addToast('Não foi possível carregar a cor do ícone', 'error');
    } else {
      setIconBackground(response.message.color);
    }
  }, [addToast, idCategory, readCategory]);

  useEffect(() => {
    idCategory && getBackgroundIcon();
  }, [getBackgroundIcon, idCategory]);

  const validateTimeDiff = useCallback((time: any) => {
    if (!!time.start && !!time.end) {
      return timeDiffValidator(time.start, time.end);
    } else {
      return true;
    }
  }, []);

  const validateMissingTime = useCallback((time: any) => {
    if ((!time.start && !time.end) || (!!time.start && !!time.end)) {
      return true;
    } else {
      return false;
    }
  }, []);

  //StepOneFormik

  const saveService = async (
    values: Pick<IService, 'icon' | 'name' | 'agenda' | 'unit'>
  ) => {
    const response = await createService(Number(idCategory), values);

    if (response.error) {
      addToast('Não foi possível criar esse serviço', 'error');
      return;
    }

    setSavedService(response.message);
    goToNextStep();
    addToast('Serviço cadastrado com sucesso', 'success');
    serviceFormik.resetForm();
  };

  const updateService = async (
    values: Pick<IService, 'icon' | 'name' | 'agenda' | 'unit'>
  ) => {
    const response = await editService(savedService!.id, values);

    if (response.error) {
      addToast('Não foi possível editar esse serviço', 'error');
      return;
    }
    setSavedService(response.message);
    addToast('Serviço atualizado com sucesso', 'success');
  };

  const serviceFormik = useFormik<IServiceDetailsFormik>({
    initialValues: {
      name: savedService?.name || '',
      unit: savedService?.unit || '',
      icon: savedService?.icon,
      agenda: {
        0: {
          start: savedService?.agenda[0].start || null,
          end: savedService?.agenda[0].end || null,
        },
        1: {
          start: savedService?.agenda[1].start || null,
          end: savedService?.agenda[1].end || null,
        },
        2: {
          start: savedService?.agenda[2].start || null,
          end: savedService?.agenda[2].end || null,
        },
        3: {
          start: savedService?.agenda[3].start || null,
          end: savedService?.agenda[3].end || null,
        },
        4: {
          start: savedService?.agenda[4].start || null,
          end: savedService?.agenda[4].end || null,
        },
        5: {
          start: savedService?.agenda[5].start || null,
          end: savedService?.agenda[5].end || null,
        },
        6: {
          start: savedService?.agenda[6].start || null,
          end: savedService?.agenda[6].end || null,
        },
      },
    },
    onSubmit: savedService ? updateService : saveService,
    enableReinitialize: true,
    validationSchema: yup.object({
      name: yup.string().required('Esse campo é obrigatório'),
      unit: yup.string().required('Esse campo é obrigatório'),
      icon: yup.object().nullable().required('Você precisa escolher um ícone'),
      agenda: yup.object().shape({
        0: yup
          .object()
          .test(
            'is-time-0-missing-field',
            'Selecione o campo não preenchido',
            validateMissingTime
          )
          .test(
            'is-time-0-valid',
            'A hora final está anterior a hora inicial',
            validateTimeDiff
          ),
        1: yup
          .object()
          .test(
            'is-time-1-missing-field',
            'Selecione o campo não preenchido',
            validateMissingTime
          )
          .test(
            'is-time-1-valid',
            'A hora final está anterior a hora inicial',
            validateTimeDiff
          ),
        2: yup
          .object()
          .test(
            'is-time-2-missing-field',
            'Selecione o campo não preenchido',
            validateMissingTime
          )
          .test(
            'is-time-2-valid',
            'A hora final está anterior a hora inicial',
            validateTimeDiff
          ),
        3: yup
          .object()
          .test(
            'is-time-3-missing-field',
            'Selecione o campo não preenchido',
            validateMissingTime
          )
          .test(
            'is-time-3-valid',
            'A hora final está anterior a hora inicial',
            validateTimeDiff
          ),
        4: yup
          .object()
          .test(
            'is-time-4-missing-field',
            'Selecione o campo não preenchido',
            validateMissingTime
          )
          .test(
            'is-time-4-valid',
            'A hora final está anterior a hora inicial',
            validateTimeDiff
          ),
        5: yup
          .object()
          .test(
            'is-time-5-missing-field',
            'Selecione o campo não preenchido',
            validateMissingTime
          )
          .test(
            'is-time-5-valid',
            'A hora final está anterior a hora inicial',
            validateTimeDiff
          ),
        6: yup
          .object()
          .test(
            'is-time-6-missing-field',
            'Selecione o campo não preenchido',
            validateMissingTime
          )
          .test(
            'is-time-6-valid',
            'A hora final está anterior a hora inicial',
            validateTimeDiff
          ),
      }),
    }),
  });

  //StepTwoFormik

  const saveFormFormik = async (values: IFormFormikProps) => {
    if (!savedService) {
      return;
    }
    const response = await setServiceForm(savedService.id, values.forms);

    if (response.error) {
      addToast('Não foi possível salvar o formulário', 'error');
      return;
    }
    setSavedService(response.message);
    addToast('Formulário salvo com sucesso', 'success');
  };

  const formFormik = useFormik<IFormFormikProps>({
    initialValues: { forms: savedService?.form || [] },
    onSubmit: saveFormFormik,
    enableReinitialize: true,
    validationSchema: yup.object({
      forms: yup.array().of(
        yup.object().shape({
          question: yup.string().required('Campo obrigatório'),
          values: yup
            .array()
            .min(1, 'Adicione pelo menos uma opção')
            .nullable(),
        })
      ),
    }),
  });

  //StepThreeFormik

  const savePriceScheme = async (values: IPriceFormikProps) => {
    if (!savedService) {
      return;
    }

    const response = await setServicePriceScheme(
      savedService.id,
      values.priceScheme
    );

    if (response.error) {
      addToast('Não foi possível salvar a tabela de preço', 'error');
      return;
    }

    setSavedService(response.message);
    addToast('Tabela de preço salva com sucesso', 'success');
  };

  const priceFormik = useFormik<IPriceFormikProps>({
    initialValues: {
      priceScheme: savedService?.price_scheme || [],
    },
    enableReinitialize: true,
    onSubmit: savePriceScheme,
  });

  const steps = [
    <StepOne formik={serviceFormik} iconBackground={iconBackground} />,
    <StepTwo formik={formFormik} />,
    <StepThree formik={priceFormik} unit={savedService?.unit} />,
  ];

  return (
    <>
      <Header>
        <Label
          onClick={() =>
            history.push(RoutesPath.private.category.services.list)
          }
          active={false}
        >
          Serviços
        </Label>
        <span>{'>'}</span>
        <Label active={true}>
          {savedService ? 'Edição' : 'Cadastro'} de serviço
        </Label>
      </Header>
      <Container>
        <PageHeaderContainer>
          <PageHeaderTitle>
            {savedService ? 'Atualizar' : 'Novo'} serviço
          </PageHeaderTitle>
        </PageHeaderContainer>
        <StepsContainer>
          <Step onClick={() => setCurrentStep(0)} selected={currentStep === 0}>
            <span>Detalhes</span>
            <WarningCircle
              dirty={serviceFormik.dirty}
              error={
                serviceFormik.dirty &&
                Object.keys(serviceFormik.errors).length !== 0
              }
            />
          </Step>
          <Step
            disabled={!savedService}
            onClick={() => {
              !!savedService && setCurrentStep(1);
            }}
            selected={currentStep === 1}
          >
            <span>Formulário</span>
            <WarningCircle
              dirty={formFormik.dirty}
              error={
                formFormik.dirty && Object.keys(formFormik.errors).length !== 0
              }
            />
          </Step>
          <Step
            disabled={!savedService}
            onClick={() => {
              !!savedService && setCurrentStep(2);
            }}
            selected={currentStep === 2}
          >
            <span>Preços</span>
            <WarningCircle
              dirty={priceFormik.dirty}
              error={
                priceFormik.dirty &&
                Object.keys(priceFormik.errors).length !== 0
              }
            />
          </Step>
        </StepsContainer>
        {steps[currentStep]}
        <Prompt
          when={serviceFormik.dirty || formFormik.dirty || priceFormik.dirty}
          message="Tem certeza que quer sair sem salvar os dados do formulário?"
        />
      </Container>
    </>
  );
};

export default CreateEditService;
