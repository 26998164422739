import { useToast } from 'contexts/Toast';
import { useAssets } from 'hooks';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { DropZoneContainer } from './styles';

interface IDropzone {
  onFileUploaded: (uploadedImage: IImage) => void;
  style?: React.CSSProperties;
}

const ImageDropzone: React.FC<IDropzone> = ({ onFileUploaded, style }) => {
  const { addToast } = useToast();
  const { sendImage } = useAssets();

  const uploadImage = useCallback(
    async (image: File) => {
      const response = await sendImage(image);

      if (response.error) {
        const msg = response.message.details
          ? response.message.details
          : response.message.image_high_url;
        addToast(msg, 'error');
        return;
      } else {
        onFileUploaded({
          id: response.message.id,
          image_high_url: response.message.image_high_url,
        });
      }
    },
    [onFileUploaded, sendImage, addToast]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (!file) {
        addToast('Formato de arquivo não válido', 'error');
        return;
      }
      uploadImage(file);
    },
    [uploadImage, addToast]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.jpeg, .png, .jpg',
  });

  return (
    <DropZoneContainer {...getRootProps()} style={style}>
      <input {...getInputProps()} />
      <p>
        Arraste a imagem para cá ou <span>procure</span>
      </p>
    </DropZoneContainer>
  );
};

export default ImageDropzone;
