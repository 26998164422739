const RoutesPath = {
  auth: {
    root: '/login',
    login: '/login',
    forgotPassword: '/esqueci-minha-senha',
    changePassword: '/trocar-senha',
  },
  private: {
    home: '/',
    category: {
      create: '/categorias/criar',
      edit: '/categorias/editar/:idCategory',
      services: {
        list: '/categorias/servicos',
        create: '/categorias/servicos/:idCategory/criar',
        edit: '/categorias/servicos/:idCategory/editar/:idService',
      },
    },
    training: {
      list: '/treinamentos',
      create: '/treinamentos/criar',
      edit: '/treinamentos/editar/:idTraining',
    },
    providers: '/prestadores',
    orders: {
      list: '/pedidos',
    },
    customers: {
      list: '/clientes',
      details: '/clientes/:idCustomer',
    },
    staffs: '/funcionarios',
  },
};

export default RoutesPath;
