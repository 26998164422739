import { MdSearch } from 'react-icons/md';
import { Container } from './styles';

const Search: React.FC<React.InputHTMLAttributes<HTMLInputElement>> = (
  props
) => {
  return (
    <Container className={props.className}>
      <MdSearch />
      <input type="text" placeholder="Pesquisar" {...props} />
    </Container>
  );
};

export default Search;
