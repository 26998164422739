import { FormError } from '../components/ProjectStyles';

export function renderFormikErrors(
  error?: string,
  touched?: boolean,
  status?: any
) {
  return (
    <>
      {error && touched && <FormError>{error}</FormError>}
      {status && status.map((error: string) => <FormError>{error}</FormError>)}
    </>
  );
}
