import styled, { css } from 'styled-components';

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  span {
    margin-left: 8px;
  }
`;

export const PageHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  margin-bottom: 8px;
  min-height: 40px;
`;

export const PageHeaderTitle = styled.h1`
  color: ${(props) => props.theme.colors.gray.four};
  font-size: 20px;
  line-height: 24px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
`;

export const FiltersContainer = styled.div`
  display: flex;

  & > div:not(:last-child) {
    margin-right: 10px;
  }
`;

export const AddButton = styled.button`
  height: 44px;
  padding: 0 36px;
  border: 1px solid ${(props) => props.theme.colors.gray.one};
  border-radius: 8px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.gray.five};
  margin-top: 12px;
  margin-bottom: 32px;

  span {
    margin-left: 8px;
    font-weight: 500;
  }

  svg {
    font-size: 28px;
  }
`;

export const PrimaryButton = styled.button`
  background-color: ${(props) => props.theme.colors.orange.dark};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  height: 40px;
  padding: 0 16px;
  color: ${(props) => props.theme.colors.white};
  align-items: center;
  white-space: nowrap;

  svg {
    font-size: 24px;
    margin-right: 8px;
  }
  span {
    white-space: nowrap;
    font-size: 12px;
    font-weight: ${(props) => props.theme.fontWeight.medium};
  }
`;

export const SecondaryButton = styled.button`
  white-space: nowrap;
  background-color: ${(props) => props.theme.colors.gray.six};
  border-radius: 8px;
  height: 32px;
  padding: 0 14px;
  color: ${(props) => props.theme.colors.white};
  font-size: 14px;
  font-weight: normal;
`;

export const Form = styled.form`
  width: fit-content;
`;

export const FormError = styled.span`
  color: ${(props) => props.theme.colors.error};
  margin: 5px 0 0 0;
  font-size: 14px;
`;

export const FormRow = styled.div`
  display: flex;

  div + div {
    margin-left: 44px;
  }
`;

interface IFormGroup {
  maxWidth?: boolean;
}

export const FormGroup = styled.div<IFormGroup>`
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      flex: 1;
    `}

  & > label {
    font-weight: ${(props) => props.theme.fontWeight.bold};
    color: ${(props) => props.theme.colors.gray.six};
    font-size: 12px;
  }

  & > input,
  & > textarea {
    margin-top: 8px;
    width: ${(props) => (props.maxWidth ? '100%' : '270px')};
    padding: 14px 16px;
    font-size: 12px;
    border-radius: 8px;
    border: 1px solid #eaeaea;
    height: 40px;
  }

  & > textarea {
    height: auto;
  }

  & > select {
    margin-top: 8px;
    padding: 14px 16px;
    font-size: 12px;
    width: 80px;
    background-color: transparent;
    border-radius: 8px;
    border: 1px solid #eaeaea;
  }

  & > p {
    color: ${(props) => props.theme.colors.gray.four};
    margin-top: 4px;
  }
`;

export const ColorPickerButton = styled.button`
  margin-top: 8px;
  width: 270px;
  padding: 14px 16px;
  font-size: 12px;
  border-radius: 8px;
  border: 1px solid #eaeaea;
  text-align: left;
  color: ${(props) => props.theme.colors.gray.three};
  display: flex;
  align-items: center;
`;

interface ISelectedColor {
  color: string;
}

export const SelectedColor = styled.div<ISelectedColor>`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: ${(props) => props.color};
  margin-right: 12px;
  border: 1px solid black;
`;
