import React from 'react';

import { Container, Modal } from './styles';

interface IB2Modal {
  open: boolean;
  modalStyle?: React.CSSProperties;
  children: React.ReactNode;
  className?: string;
}

const B2Modal: React.FC<IB2Modal> = ({
  open,
  modalStyle,
  children,
  className,
}) => {
  return (
    <Container open={open}>
      <Modal className={className} style={modalStyle}>
        {children}
      </Modal>
    </Container>
  );
};

export default B2Modal;
