import { useAuth } from 'contexts/Auth';
import React from 'react';
import { StaffRole } from 'utils/enums';

interface IStaffRoleGuard {
  allowedStaffs: StaffRole[];
  fallback?: (staffRole?: StaffRole) => JSX.Element;
}

const StaffRoleGuard: React.FC<IStaffRoleGuard> = ({
  allowedStaffs,
  children,
  fallback,
}) => {
  const { userInfo } = useAuth();

  const staffHasPermission =
    !!userInfo && allowedStaffs.includes(userInfo?.staff_role_info.value);

  if (!staffHasPermission) {
    return (fallback && fallback(userInfo?.staff_role_info.value)) || null;
  }

  return <>{children}</>;
};

export default StaffRoleGuard;
