import { useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { FooterButton, HeaderContainer, Title } from './styles';
import { Form, FormGroup, FormRow, FormError } from 'components/ProjectStyles';

import { B2Modal } from 'components/B2';
import CloseButton from 'components/CloseButton';
import { brCurrencyMask, onlyNumbers } from 'utils/masks';

interface IPriceSchemeModal {
  unit: string;
  closeModal: () => void;
  addPriceScheme: (newPriceScheme: IPriceScheme) => void;
  editPriceScheme: (newPriceScheme: IPriceScheme) => void;
  selectedPriceIndex: number;
  priceScheme: IPriceScheme[];
}

const PriceSchemeModal: React.FC<IPriceSchemeModal> = ({
  closeModal,
  addPriceScheme,
  unit,
  selectedPriceIndex,
  priceScheme,
  editPriceScheme,
}) => {
  const priceSchemeFormik = useFormik({
    initialValues: {
      time: 0,
      field_start: 0,
      field_end: 0,
      price: 0,
    },
    onSubmit: (values) => {
      selectedPriceIndex >= 0
        ? editPriceScheme(values)
        : addPriceScheme(values);
      closeModal();
    },
    validationSchema: yup.object({
      time: yup.number().positive('Esse campo precisa ser um valor positivo'),
      field_end: yup
        .number()
        .positive('Esse campo precisa ser um valor positivo')
        .test(
          'is-field-end-valid',
          'A unidade final precisa ser maior do que a inicial',
          (value, scheme) => {
            if (scheme.parent.field_start && value) {
              return value > scheme.parent.field_start;
            } else {
              return true;
            }
          }
        ),
      price: yup.number().positive('Esse campo precisa ser um valor positivo'),
    }),
  });

  const populateModal = useCallback(() => {
    const priceSchemeRow = priceScheme[selectedPriceIndex];
    if (priceSchemeRow) {
      priceSchemeFormik.setValues(priceSchemeRow);
    } else {
      priceSchemeFormik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceScheme, selectedPriceIndex]);

  useEffect(() => {
    populateModal();
  }, [populateModal, selectedPriceIndex]);

  return (
    <B2Modal open={true}>
      <HeaderContainer>
        <Title>Novo campo</Title>
        <CloseButton onClick={closeModal} />
      </HeaderContainer>
      <Form onSubmit={priceSchemeFormik.handleSubmit}>
        <FormRow>
          <FormGroup>
            <label htmlFor="time">Turno (horas)</label>
            <input
              type="number"
              name="time"
              id="time"
              min={0}
              step={1}
              value={priceSchemeFormik.values.time}
              onChange={(e) => {
                e.target.value = parseInt(e.target.value || '0').toString();
                priceSchemeFormik.handleChange(e);
              }}
            />
            {priceSchemeFormik.errors.time &&
              priceSchemeFormik.touched.time && (
                <FormError>{priceSchemeFormik.errors.time}</FormError>
              )}
          </FormGroup>
          <FormGroup>
            <label htmlFor="price">Preço</label>
            <input
              type="text"
              name="price"
              id="price"
              autoComplete="off"
              value={brCurrencyMask(priceSchemeFormik.values.price)}
              onChange={(e) => {
                priceSchemeFormik.setFieldValue(
                  'price',
                  Number(onlyNumbers(e.target.value))
                );
              }}
            />
            {priceSchemeFormik.errors.price &&
              priceSchemeFormik.touched.price && (
                <FormError>{priceSchemeFormik.errors.price}</FormError>
              )}
          </FormGroup>
        </FormRow>
        <FormRow>
          <FormGroup>
            <label htmlFor="field_start">Unidade inicial ({unit})</label>
            <input
              type="number"
              name="field_start"
              id="field_start"
              min={0}
              step={1}
              value={priceSchemeFormik.values.field_start}
              onChange={(e) => {
                e.target.value = parseInt(e.target.value || '0').toString();
                priceSchemeFormik.handleChange(e);
              }}
            />
            {priceSchemeFormik.errors.field_start &&
              priceSchemeFormik.touched.field_start && (
                <FormError>{priceSchemeFormik.errors.field_start}</FormError>
              )}
          </FormGroup>
          <FormGroup>
            <label htmlFor="field_end">Unidade final ({unit})</label>
            <input
              type="number"
              name="field_end"
              id="field_end"
              min={0}
              step={1}
              value={priceSchemeFormik.values.field_end}
              onChange={(e) => {
                e.target.value = parseInt(e.target.value || '0').toString();
                priceSchemeFormik.handleChange(e);
              }}
            />
            {priceSchemeFormik.errors.field_end &&
              priceSchemeFormik.touched.field_end && (
                <FormError>{priceSchemeFormik.errors.field_end}</FormError>
              )}
          </FormGroup>
        </FormRow>
        <FooterButton type="submit">Concluir</FooterButton>
      </Form>
    </B2Modal>
  );
};

export default PriceSchemeModal;
