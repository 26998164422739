import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 2px solid ${(props) => props.theme.colors.gray.one};
`;

export const ProfileContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid ${(props) => props.theme.colors.gray.one};

  img {
    width: 64px;
    height: 64px;
    border-radius: 8px;
  }
`;

export const Title = styled.h2`
  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.gray.six};
`;

export const InfoUser = styled.div`
  margin-left: 16px;
`;

export const BanButton = styled.button`
  height: 28px;
  padding: 0px 8px;
  border-radius: 6px;
  background: rgba(255, 20, 59, 0.2);
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.red.dark};
  margin-top: 8px;

  span {
    margin-left: 6px;
    font-size: 14px;
  }

  svg {
    font-size: 22px;
  }
`;

export const InfoTop = styled.div`
  display: flex;
  align-items: center;

  p {
    color: ${(props) => props.theme.colors.gray.four};
    margin-left: 12px;
    font-size: 12px;
    font-weight: 500;
    display: inline;
  }
`;

export const InfoBottom = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  div + div {
    margin-left: 48px;
  }
`;

export const InfoData = styled.div`
  p {
    font-size: 12px;
    font-weight: normal;
    color: ${(props) => props.theme.colors.gray.four};
  }
  p + p {
    font-size: 14px;
    font-weight: 500;
    color: ${(props) => props.theme.colors.gray.six};
  }
`;

export const ServicesContainer = styled.div`
  display: flex;
  padding: 16px 0px;
`;

export const Service = styled.div`
  max-height: 172px;
  overflow: auto;

  & + & {
    margin-left: 64px;
  }
`;

export const ServiceHeader = styled.p`
  color: ${(props) => props.theme.colors.gray.three};
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 12px;
`;

export const ServiceData = styled.div`
  color: ${(props) => props.theme.colors.gray.six};
  font-weight: bold;
  font-size: 14px;

  p {
  }

  & + & {
    margin-top: 8px;
  }
`;

export const ImagesContainer = styled.div``;

export const ImagesList = styled.div`
  display: flex;
  margin-top: 16px;
`;

const handleBackgroundColor = (status?: AnalyzedImageStatus) => {
  switch (status?.value) {
    case 1:
      return 'rgba(71, 180, 114, 0.4)';
    case 2:
      return 'rgba(255, 20, 59, 0.4)';
    default:
      return 'transparent';
  }
};

interface IImageContainer {
  status?: AnalyzedImageStatus;
}

export const ImageContainer = styled.div<IImageContainer>`
  width: 112px;
  height: 112px;
  border-radius: 16px;
  cursor: ${(props) => (props.status?.value === 0 ? 'pointer' : 'auto')};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.gray.one};

  & > svg {
    font-size: 32px;
    color: ${(props) => props.theme.colors.gray.three};
  }

  img {
    border-radius: 16px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  & + & {
    margin-left: 32px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 16px;
    background: ${(props) => handleBackgroundColor(props.status)};
    z-index: 1;
  }
`;

interface IAnalysisIconContainer {
  status?: AnalyzedImageStatus;
}

const handleColorIcon = (status?: AnalyzedImageStatus) => {
  switch (status?.value) {
    case 1:
      return '#47B472';
    case 2:
      return '#FF143B';
    default:
      return 'transparent';
  }
};

export const AnalysisIconContainer = styled.div<IAnalysisIconContainer>`
  display: ${(props) => (props.status?.value === 0 ? 'none' : 'block')};
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #e3f4ea;
  background-color: ${(props) => handleColorIcon(props.status)};
  position: absolute;
  top: -8px;
  right: -8px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  svg {
    color: #e3f4ea;
    font-size: 20px;
  }
`;

export const FooterButton = styled.button`
  background-color: ${(props) =>
    props.disabled
      ? props.theme.colors.gray.three
      : props.theme.colors.orange.dark};
  border-radius: 6px;
  height: 44px;
  color: white;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
  margin-top: 30px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;
