import styled from 'styled-components';

import { B2Button, B2Modal } from 'components/B2';
import { MdClose } from 'react-icons/md';

export const Container = styled(B2Modal)`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h1`
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  width: 210px;
  text-align: center;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 24px;

  button + button {
    margin-top: 16px;
  }
`;

export const CancelButton = styled(B2Button)`
  color: ${(props) => props.theme.colors.gray.six};
  padding-left: 30px;
  padding-right: 30px;
  background-color: ${({ theme }) => theme.colors.gray.one};
`;

export const DeleteButton = styled(B2Button)`
  padding-left: 30px;
  padding-right: 30px;
  background-color: ${({ theme }) => theme.colors.red.dark};
`;

export const CloseButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  width: 24px;
  height: 24px;
  right: 10px;
  top: 10px;
  color: ${(props) => props.theme.colors.gray.two};
`;
