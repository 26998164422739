import { TableDataCell, TableH, TableRow } from 'components/B2/B2Table/styles';
import CategoryIcon from 'components/CategoryIcon';
import { AddButton } from 'components/ProjectStyles';
import { MdAdd, MdEdit } from 'react-icons/md';
import { useHistory } from 'react-router';

interface IServicesRows {
  category: ICategory;
}

const ServicesRows: React.FC<IServicesRows> = ({ category }) => {
  const history = useHistory();

  return (
    <>
      {!category.services.length ? (
        <TableRow>
          <TableDataCell colSpan={2}>
            <p>Não há nenhum serviço cadastrado</p>
          </TableDataCell>
        </TableRow>
      ) : (
        <>
          <TableRow>
            <TableH>
              <div />
            </TableH>
            <TableH>
              <div>Serviços</div>
            </TableH>
          </TableRow>
          {category.services.map((service) => (
            <TableRow key={service.id}>
              <TableDataCell>
                <CategoryIcon
                  color={category.color}
                  imageUrl={service.icon?.file}
                />
              </TableDataCell>
              <TableDataCell>
                {service.name}{' '}
                <MdEdit
                  onClick={() =>
                    history.push(
                      `/categorias/servicos/${category.id}/editar/${service.id}`
                    )
                  }
                  style={{
                    marginLeft: '12px',
                    color: '#B1B1B1',
                    fontSize: '18px',
                    cursor: 'pointer',
                  }}
                />
              </TableDataCell>
            </TableRow>
          ))}
        </>
      )}

      <TableRow>
        <TableDataCell colSpan={2}>
          <AddButton
            onClick={() =>
              history.push(`/categorias/servicos/${category.id}/criar`)
            }
          >
            <MdAdd />
            <span>Adicionar serviço</span>
          </AddButton>
        </TableDataCell>
      </TableRow>
    </>
  );
};

export default ServicesRows;
