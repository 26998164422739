import styled from 'styled-components';

export const CircleProgress = styled.circle`
  transition: stroke-dashoffset 0.5s ease-out;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
`;

export const Text = styled.pre`
  font-family: inherit;
  position: absolute;
  top: 40%;
  left: 22%;
  text-align: center;
`;
