import styled from "styled-components";

export const DropZoneContainer = styled.div`
    flex: 1;
    height: 50px;
    min-width: 375px;
    background-color: #e8e8e8;
    border-width: 0px;
    border-radius: 8px;
    font-family: ${(props) => props.theme.fontFamily};
    display: flex;
    align-items: center;
    padding: 4px;
    margin-top: 8px;
    cursor: pointer;
    
    p {
        color: ${(props) => props.theme.colors.gray.six};
        font-weight: ${(props) => props.theme.fontWeight.semiBold};
        margin-left: 8px;
    }


    span {
        text-decoration: underline;
        color: ${(props) => props.theme.colors.gray.six};
        font-weight: ${(props) => props.theme.fontWeight.bold};
    }

`;