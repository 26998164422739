import { useCallback } from 'react';
import { useApi, endpoints } from 'contexts/Api';
import { requestContextError } from './settings';

const useAuthentication = () => {
  const { request } = useApi();

  const performLogin = useCallback(
    async (email: string, password: string) => {
      if (request) {
        const data = {
          email,
          password,
        };
        return await request({
          method: 'post',
          url: endpoints.auth.login,
          data,
        });
      }
      return requestContextError;
    },
    [request]
  );

  const getProfile = useCallback(async () => {
    if (request) {
      const response = await request({
        method: 'get',
        url: endpoints.auth.profile,
      });

      return response;
    }
    return requestContextError;
  }, [request]);

  const getUserInfo = useCallback(async () => {
    if (request) {
      return await request({ method: 'get', url: endpoints.auth.profile });
    }
    return requestContextError;
  }, [request]);

  const requestChangePassword = useCallback(
    async (email) => {
      if (request) {
        return await request({
          method: 'post',
          url: endpoints.auth.changePassword,
          data: { email },
        });
      }
    },
    [request]
  );

  const changePassword = useCallback(
    async (newPassword, token) => {
      if (request) {
        return await request({
          method: 'post',
          url: endpoints.auth.confirmChangePassword,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: { password: newPassword },
        });
      }
    },
    [request]
  );

  return {
    getProfile,
    performLogin,
    getUserInfo,
    requestChangePassword,
    changePassword,
  };
};

export default useAuthentication;
