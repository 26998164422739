import styled from 'styled-components';

export const Response = styled.input`
  margin-top: 32px;
  padding: 4px;
  pointer-events: none;
  border-bottom: 1px dotted;
  width: 80px;
  text-align: center;
`;
