import styled, { css } from 'styled-components';

export const Header = styled.div`
  background-color: ${(props) => props.theme.colors.gray.one};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 16px 32px;
  color: ${(props) => props.theme.colors.gray.four};
  line-height: 16px;
  display: flex;

  span {
    font-weight: ${(props) => props.theme.fontWeight.bold};
    margin: 0 8px;
  }
`;

interface ILabel {
  active: boolean;
}

export const Label = styled.button<ILabel>`
  font-weight: ${(props) =>
    props.active ? props.theme.fontWeight.bold : props.theme.fontWeight.normal};
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 10px;
    font-size: 20px;
  }
`;

export const FormButtonGroup = styled.div`
  display: flex;
  margin-top: 108px;
`;

export const Form = styled.form`
  flex: 1;
`;

export const FormContainer = styled.div`
  margin-top: 20px;
  display: flex;
`;

export const EmbedContainer = styled.div`
  margin-top: 32px;
  flex: 1;
  margin-left: 96px;

  & > p {
    font-weight: ${(props) => props.theme.fontWeight.bold};
    margin-bottom: 10px;
    font-size: 12px;
  }

  & > iframe {
    border-radius: 10px;
  }
`;

export const SelectAllServicesContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;

  & > label {
    color: ${({ theme }) => theme.colors.gray.four};
    font-weight: 500;
    font-size: 14px;
    margin-left: 8px;
  }

  & > input {
    color: green;
  }
`;

export const SelectedServicesContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 32px;
  div + div {
    margin-top: 8px;
  }
`;

export const SelectedService = styled.div`
  background-color: ${({ theme }) => theme.colors.gray.one};
  padding: 0 16px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  justify-content: space-between;
  width: 300px;
  height: 40px;

  & > span {
    color: ${({ theme }) => theme.colors.gray.four};
    font-weight: 500;
    font-size: 14px;
  }

  & > svg {
    color: ${({ theme }) => theme.colors.error};
    font-size: 20px;
    cursor: pointer;
  }
`;

interface IAddServiceContainer {
  disabled: boolean;
}

export const AddServiceContainer = styled.div<IAddServiceContainer>`
  display: flex;
  color: ${({ theme }) => theme.colors.orange.dark};
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}

  & > span {
    font-weight: bold;
    font-size: 16px;
  }

  & > svg {
    font-size: 24px;
    margin-left: 4px;
  }
`;
