import React from 'react';
import Select, { GroupBase, Props } from 'react-select';

function FiltersSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: Props<Option, IsMulti, Group>) {
  const customTheme = (theme: any) => {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary25: '#EAEAEA',
        primary: '#FE5C1E',
      },
      borderRadius: '8px',
    };
  };

  const customStyles = {
    control: (base: any) => ({
      ...base,
      fontFamily: 'Inter',
      fontSize: 14,
      height: 40,
      width: 285,
    }),
    menu: (base: any) => ({
      ...base,
      fontFamily: 'Inter',
      fontSize: 14,
      width: 285,
    }),
  };

  return (
    <Select
      styles={customStyles}
      theme={customTheme}
      noOptionsMessage={() => 'Sem opções'}
      {...props}
    />
  );
}

export default FiltersSelect;
