import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 16px;
  border: 1px solid ${({ theme }) => theme.colors.gray.one};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;

  & > svg {
    font-size: 24px;
    color: ${({ theme }) => theme.colors.gray.four};
    cursor: pointer;
  }
`;

export const AddressData = styled.div`
  span {
    color: ${({ theme }) => theme.colors.gray.six};
    font-weight: bold;
    font-size: 14px;
    display: block;
  }
  span + span {
    color: ${({ theme }) => theme.colors.gray.six};
    font-weight: 500;
    font-size: 12px;
    display: block;
    margin-top: 4px;
  }
`;
