import { endpoints, useApi } from 'contexts/Api';
import { useCallback } from 'react';
import { requestContextError } from './settings';

const useTrainings = () => {
  const { request } = useApi();

  const listTrainings = useCallback(
    (page: number, search: string, services?: number) => {
      if (request) {
        return request({
          url: endpoints.trainings,
          method: 'get',
          params: {
            page,
            search,
            services,
          },
        });
      }

      return requestContextError;
    },
    [request]
  );

  const readTraining = useCallback(
    (id: number) => {
      if (request) {
        return request({
          url: `${endpoints.trainings}${id}/`,
          method: 'get',
        });
      }

      return requestContextError;
    },
    [request]
  );

  const deleteTraining = useCallback(
    (id: number) => {
      if (request) {
        return request({
          url: `${endpoints.trainings}${id}/`,
          method: 'delete',
        });
      }

      return requestContextError;
    },
    [request]
  );

  const createTraining = useCallback(
    ({ description, embed, name, services }: ITrainingFormik) => {
      const data = {
        description,
        embed,
        name,
        services: services?.map((service) => service.id) || [],
      };
      if (request) {
        return request({
          url: endpoints.trainings,
          method: 'post',
          data,
        });
      }

      return requestContextError;
    },
    [request]
  );

  const editTraining = useCallback(
    (id: number, { description, services, name, embed }: ITrainingFormik) => {
      const data = {
        description,
        embed,
        name,
        services: services?.map((service) => service.id) || [],
      };
      if (request) {
        return request({
          url: `${endpoints.trainings}${id}/`,
          method: 'put',
          data,
        });
      }

      return requestContextError;
    },
    [request]
  );

  return {
    listTrainings,
    deleteTraining,
    createTraining,
    editTraining,
    readTraining,
  };
};

export default useTrainings;
