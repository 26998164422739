import React from 'react';
import './index.css';

import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';

import { lightTheme } from 'themes/default';
import { ApiProvider } from 'contexts/Api';
import { AuthProvider } from 'contexts/Auth';

import Router from 'router';
import { ToastProvider } from 'contexts/Toast';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={lightTheme}>
      <ApiProvider>
        <ToastProvider>
          <AuthProvider>
            <Router />
          </AuthProvider>
        </ToastProvider>
      </ApiProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
