import { FormError } from 'components/ProjectStyles';
import { useEffect, useRef } from 'react';
import {
  CheckboxIcon,
  CloseButton,
  Container,
  InputContainer,
  OptionContainer,
  OptionsIcon,
} from './styles';

interface IOptions {
  values: string[];
  setValues: (values: string[]) => void;
  type: 'checkbox' | 'options';
  error?: string;
  touched?: boolean;
}

const Options: React.FC<IOptions> = ({
  values,
  setValues,
  type,
  error,
  touched,
}) => {
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
  }, [values]);

  const addNewOption = () =>
    setValues([...values, `Opção ${values.length + 1}`]);

  const deleteOption = (index: number) =>
    setValues(values.filter((_, i) => i !== index));

  const changeOption = (index: number, value: string) =>
    setValues(values.map((el, i) => (i === index ? value : el)));

  const icon = {
    options: <OptionsIcon />,
    checkbox: <CheckboxIcon />,
  };

  return (
    <Container>
      {values.map((value, index) => (
        <OptionContainer key={index}>
          {icon[type]}
          <InputContainer>
            <input
              autoFocus={!firstRender.current && values.length === index + 1}
              onFocus={(e) => e.target.select()}
              type="text"
              placeholder="Nova opção"
              value={value}
              onChange={(e) => changeOption(index, e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && addNewOption()}
            />
            <div />
          </InputContainer>
          <CloseButton
            onClick={() => {
              deleteOption(index);
            }}
          />
        </OptionContainer>
      ))}
      <OptionContainer onClick={addNewOption}>
        {icon[type]}
        <InputContainer>
          <input type="text" placeholder="Nova opção" disabled />
        </InputContainer>
      </OptionContainer>
      {error && touched && <FormError>{error}</FormError>}
    </Container>
  );
};

export default Options;
