import { endpoints, useApi } from 'contexts/Api';
import { useCallback } from 'react';
import { requestContextError } from './settings';

const useCategory = () => {
  const { request } = useApi();

  const createCategory = useCallback(
    (values: Omit<ICategory, 'services' | 'id'>) => {
      if (request) {
        const data = {
          color: values.color,
          name: values.name,
          icon: values.icon?.id,
        };
        return request({ url: endpoints.category, method: 'post', data });
      }

      return requestContextError;
    },
    [request]
  );

  const editCategory = useCallback(
    (id: number, values: Omit<ICategory, 'services' | 'id'>) => {
      if (request) {
        const data = {
          color: values.color,
          name: values.name,
          icon: values.icon?.id,
        };
        return request({
          url: `${endpoints.category}${id}/`,
          method: 'patch',
          data,
        });
      }

      return requestContextError;
    },
    [request]
  );

  const listCategory = useCallback(() => {
    if (request) {
      return request({ url: endpoints.category, method: 'get' });
    }

    return requestContextError;
  }, [request]);

  const readCategory = useCallback(
    (id: number) => {
      if (request) {
        return request({ url: `${endpoints.category}${id}/`, method: 'get' });
      }

      return requestContextError;
    },
    [request]
  );

  return {
    createCategory,
    listCategory,
    readCategory,
    editCategory,
  };
};

export default useCategory;
