import React from 'react';

import {
  Title,
  ButtonsContainer,
  CancelButton,
  DeleteButton,
  Container,
} from './styles';

interface IDeleteModal {
  open: boolean;
  onHide: () => void;
  onDelete: () => Promise<void>;
  title: string;
}

const DeleteModal: React.FC<IDeleteModal> = ({
  open,
  onHide,
  onDelete,
  title,
}) => {
  return (
    <Container open={open}>
      <Title>{title}</Title>
      <ButtonsContainer>
        <DeleteButton
          onClick={() => {
            onDelete();
            onHide();
          }}
          variant="primary"
        >
          Delete
        </DeleteButton>
        <CancelButton variant="none" onClick={onHide}>
          Cancel
        </CancelButton>
      </ButtonsContainer>
    </Container>
  );
};

export default DeleteModal;
