import { MdDelete } from 'react-icons/md';

import {
  Container,
  DeleteQuestionButton,
  InfoContainer,
  Title,
} from './styles';

import OptionsSelect from 'components/OptionsSelect';
import { FormError, FormGroup } from 'components/ProjectStyles';
import { Decimal, Increment, Options, Text } from './questionTypes';
import { FormikTouched } from 'formik';

interface IQuestionCard {
  form: IForm;
  setForm: (form: IForm) => void;
  deleteQuestion: () => void;
  index: number;
  toucheds?: FormikTouched<IForm>;
  errors?: any;
}

const QuestionCard: React.FC<IQuestionCard> = ({
  form,
  setForm,
  deleteQuestion,
  index,
  errors,
  toucheds,
}) => {
  const questionTypes = {
    checkbox: (
      <Options
        values={form.values || []}
        setValues={(values: string[]) => {
          setForm({
            ...form,
            values,
          });
        }}
        type={form.type as 'checkbox'}
        error={errors?.values}
        touched={!!toucheds?.values}
      />
    ),
    options: (
      <Options
        values={form.values || []}
        setValues={(values: string[]) => {
          setForm({
            ...form,
            values,
          });
        }}
        type={form.type as 'options'}
        error={errors?.values}
        touched={!!toucheds?.values}
      />
    ),
    decimal: <Decimal />,
    increment: <Increment />,
    text: <Text />,
  };

  return (
    <Container>
      <Title>{index + 1}. Pergunta</Title>
      <InfoContainer>
        <FormGroup maxWidth>
          <label htmlFor="questionTitle">Enunciado da pergunta</label>
          <input
            type="text"
            id="questionTitle"
            placeholder="Insira o enunciado"
            value={form.question}
            onChange={(e) =>
              setForm({
                ...form,
                question: e.target.value,
              })
            }
          />
          {errors?.question && toucheds?.question && (
            <FormError>{errors.question}</FormError>
          )}
        </FormGroup>
        <FormGroup>
          <label>Tipo da pergunta</label>
          <OptionsSelect
            value={form.type}
            options={[
              {
                label: 'Texto',
                value: 'text',
              },
              {
                label: 'Múltipla escolha',
                value: 'options',
              },
              {
                label: 'Incremento',
                value: 'increment',
              },
              {
                label: 'Decimal',
                value: 'decimal',
              },
              {
                label: 'Caixa de seleção',
                value: 'checkbox',
              },
            ]}
            onChange={(op: FormTypes) => {
              if (op === 'checkbox' || op === 'options') {
                setForm({
                  type: op,
                  question: form.question,
                  values: [],
                });
                return;
              }
              setForm({
                type: op,
                question: form.question,
              });
            }}
          />
        </FormGroup>
      </InfoContainer>
      {questionTypes[form.type]}
      <DeleteQuestionButton onClick={deleteQuestion}>
        <MdDelete />
        <span>Excluir</span>
      </DeleteQuestionButton>
    </Container>
  );
};

export default QuestionCard;
