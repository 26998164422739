import styled from 'styled-components';
import { B2Button } from '../../components/B2';

export const Container = styled.div``;

export const Divider = styled.div`
  height: 1px;
  background-color: #e6e6e6;
  margin: 20px 0;
`;

export const TrainingListContainer = styled.div`
  & > div:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const TrainingItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > iframe {
    border-radius: 10px;
  }
`;

export const TrainingItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const TrainingItemTitle = styled.p`
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  color: ${({ theme }) => theme.colors.gray.four};
`;

export const TrainingItemDescription = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.gray.five};
  margin: 12px 0;
  max-width: 80%;
`;

export const TrainingLikesContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;

  & > span {
    font-size: 20px;
    color: ${({ theme }) => theme.colors.gray.five};
    margin-left: 4px;
  }

  & > svg {
    color: ${({ theme }) => theme.colors.orange.dark};
    font-size: 24px;
  }
`;

export const TrainingButtonsContainer = styled.div``;

export const EditTrainingButton = styled(B2Button)`
  border-color: #eaeaea;
  padding: 15px;
  font-size: 12px;
`;

export const DeleteTrainingButton = styled(EditTrainingButton)`
  color: ${(props) => props.theme.colors.error};
  margin-right: 20px;
`;
