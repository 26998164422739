const getBrandImage = (brand: Brand) => {
  switch (brand) {
    case 'Visa':
      return '/images/brands/visa.png';
    case 'Mastercard':
      return '/images/brands/master_card.png';
    case 'Amex':
      return '/images/brands/amex.png';
    case 'Discover':
      return '/images/brands/discover.png';
    case 'Diners':
      return '/images/brands/diners.png';
    case 'Hipercard':
      return '/images/brands/hipercard.png';
    case 'Elo':
      return '/images/brands/elo.png';
    default:
      return '/images/brands/creditCard.png';
  }
};

export default getBrandImage;
