import { B2Button, B2Spinner, B2TextInput } from 'components/B2';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100vh;
`;

export const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  background: ${(props) => props.theme.colors.main};
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const RightPanel = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: show-downwards 1s ease-out, fade-in 1s ease-out;
`;

interface ILogo {
  movement: {
    x: number;
    y: number;
  };
}

export const Logo = styled.img<ILogo>`
  width: min(50%, 350px);
  animation: show-rightwards 1s ease-out, fade-in 1s ease-out;
  padding: 200px;
  box-sizing: content-box;

  &:not(:hover) {
    transition: transform 0.5s ease-out;
  }
`;

export const Card = styled.div`
  width: min(450px, 80%);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
`;

export const CardTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 14px;
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: ${(props) => props.theme.colors.main};
  align-self: flex-start;
`;

export const TextInput = styled(B2TextInput)`
  box-sizing: border-box;
  height: 45px;
  width: 100%;
  padding: 0 15px;
`;

export const Button = styled(B2Button)`
  width: 100%;
  height: 40px;
  margin-top: 25px;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Label = styled.label`
  width: 100%;
  margin-bottom: 10px;
  display: inline-block;
`;

export const FormGroup = styled.div`
  width: 100%;
  margin-top: 25px;
`;

export const Loading = styled(B2Spinner)`
  margin-top: 25px;
`;
