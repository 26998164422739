import React from 'react';

import {
  InfoMessageSubtitle,
  InfoMessageTitle,
  Logo,
  NextButton,
} from '../styles';

interface IInfoMessage {
  editMode?: boolean;
  onSubmit: () => void;
}

const InfoMessage: React.FC<IInfoMessage> = ({ editMode, onSubmit }) => {
  return (
    <>
      <Logo src="/images/main-logo.svg" alt="Logo" />
      <InfoMessageTitle>
        Prestador {editMode ? 'atualizado' : 'cadastrado'}
      </InfoMessageTitle>
      <InfoMessageSubtitle>
        O prestador agora pode entrar na nossa plataforma e começar a prestar
        seu serviço.
      </InfoMessageSubtitle>
      <NextButton onClick={onSubmit}>Continuar</NextButton>
    </>
  );
};

export default InfoMessage;
