import styled, { css } from "styled-components"

interface IExpandButton {
    expand: boolean;
}

export const TableDataCellContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;


export const ExpandButton = styled.button<IExpandButton>`
    display: flex;
    align-items: center;
    border-radius: 8px;
    color: ${(props) => props.expand ? props.theme.colors.white : props.theme.colors.gray.four};
    background: ${(props) => props.expand ? 'rgba(254, 92, 30, 0.5)' : 'rgba(27, 25, 25, 0.1)'};
    height: 32px;
    padding: 0px 4px 0px 12px;
    svg {
        ${(props) => props.expand && css`
            transform: rotate(-180deg);
        `}
        margin-left: 6px;
    }
`;

